import React, { type FormEvent, useContext, useRef, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import withAccessControl from 'common/routing/withAccessControl';
import ButtonV2 from 'common/ui/ButtonV2';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import SwitchV2 from 'common/ui/SwitchV2';
import { H2, P } from 'common/ui/Text';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import 'css/components/subdomain/admin/_AdminRoadmapSettingsPublicView.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const validateRoadmapBoards = (roadmapBoards: string[]) => {
  if (roadmapBoards.length === 0) {
    return 'At least one board must be selected.';
  }
  return null;
};

const AdminRoadmapSettingsPublicView = (props: Props) => {
  const company = useContext(CompanyContext);
  const boards = useContext(BoardsContext);
  const formRef = useRef(null);
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [showRoadmap, setShowRoadmap] = useState(company.showRoadmap);
  const [roadmapBoards, setRoadmapBoards] = useState<string[]>(company.roadmapBoards);

  // Boards are loading
  if (!Array.isArray(boards)) {
    return null;
  }

  const allBoardsSelected = roadmapBoards.length === boards.length;

  const onSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (saving) {
      return;
    }

    setError(undefined);
    setSaving(false);

    const roadmapBoardsError = validateRoadmapBoards(roadmapBoards);
    if (roadmapBoardsError) {
      setError(roadmapBoardsError);
      return;
    }

    setSaving(true);
    const response = await AJAX.post('/api/company/changeSettings', {
      roadmapBoards,
      showRoadmap,
    });
    if (response !== 'success') {
      setError('Something went wrong, please try again later.');
      setSaving(false);
      return;
    }

    await props.reloadCompany();
    setSaving(false);
  };

  const onSelectAll = () => {
    if (!formRef.current) {
      return;
    }

    setRoadmapBoards(allBoardsSelected ? [] : boards.map((board) => board._id));
  };

  const onSelectBoard = (value: boolean, boardID: string) => {
    setRoadmapBoards(
      value === true
        ? [...roadmapBoards, boardID]
        : roadmapBoards.filter((existingBoard) => existingBoard !== boardID)
    );
  };

  return (
    <form className="adminRoadmapSettingsPublicView" onSubmit={onSave} ref={formRef}>
      <div className="optionRow">
        <div className="optionDetail">
          <label htmlFor="showRoadmap">
            <H2 className="label" variant="headingSm">
              Public Roadmap
            </H2>
          </label>
          <P>
            Display a public roadmap on your feedback portal. Posts on the public roadmap will be
            sorted into three columns.
          </P>
        </div>
        <div className="optionInput">
          <SwitchV2
            id="showRoadmap"
            onChange={(value: boolean) => setShowRoadmap(value)}
            size="medium"
            checked={showRoadmap}
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="optionRow smallMargin">
        <div className="optionDetail">
          <H2 className="label" variant="headingSm">
            Board visibility
          </H2>
          <P>
            Customize visibility on the public roadmap by selecting specific boards. Only users with
            access to the chosen boards will be able to view them.
          </P>
        </div>
        <div className="optionInput">
          <ButtonV2 size="medium" variant="plain" onClick={onSelectAll}>
            {allBoardsSelected ? 'Select none' : 'Select all'}
          </ButtonV2>
        </div>
      </div>
      <div className="optionRow wrap">
        {boards.map((board) => (
          <CheckboxWithLabel
            checked={roadmapBoards.includes(board._id)}
            className="boardCheckbox"
            size="medium"
            name="boards"
            onChange={(value) => onSelectBoard(value, board._id)}
            value={board._id}
            key={board._id}>
            {board.name}
          </CheckboxWithLabel>
        ))}
      </div>

      <div className="buttonContainer">
        {error ? (
          <P variant="bodySm" className="error">
            {error}
          </P>
        ) : null}
        <ButtonV2 loading={saving} size="medium" type="submit">
          Save changes
        </ButtonV2>
      </div>
    </form>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.roadmap['public-view']),
    '/admin/settings'
  )
)(AdminRoadmapSettingsPublicView) as unknown as React.FC<OwnProps>;
