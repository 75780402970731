import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModalPortal from 'common/modals/ModalPortal';
import Spinner from 'common/Spinner';
import UppercaseHeader from 'common/UppercaseHeader';
import isNil from 'common/util/isNil';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AdminCreateAzureDevopsWorkItemField from './AdminCreateAzureDevopsWorkItemField';
import { ErrorMessages, ErrorTypes, Fields } from './Constants';

import 'css/components/subdomain/admin/_AdminCreateAzureDevopsWorkItemModal.scss';

const AdminCreateAzureDevopsWorkItemModal = ({ onClose, onWorkItemCreated, post, reloadPost }) => {
  // state
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const [workItem, _setWorkItem] = useState({
    fields: null,
    typeName: null,
    projectID: null,
    title: post.title,
  });
  const [projects, setProjects] = useState(null);
  const [fields, setFields] = useState(null);
  const [loadingFields, setLoadingFields] = useState(false);
  const [fieldErrors, _setFieldErrors] = useState({});

  // state utils
  const setWorkItem = (data) => _setWorkItem((workItem) => ({ ...workItem, ...data }));
  const setFieldErrors = (data) => _setFieldErrors((fieldErrors) => ({ ...fieldErrors, ...data }));
  const getProject = (projectID) => projects.find((project) => project.id === projectID);
  const getType = (typeName, projectID) => {
    const project = getProject(projectID);
    return project.types.find((type) => type.name === typeName);
  };

  // effects
  useEffect(() => {
    const fetchAndSetProjects = async () => {
      const response = await AJAX.post('/api/azureDevops/getProjects');
      const { error, parsedResponse } = parseAPIResponse(response, {
        errors: ErrorMessages,
        isSuccessful: (parsedResponse) => !!parsedResponse.projects,
      });

      if (error) {
        setError({ type: ErrorTypes.projects, message: error.message });
        return;
      }

      const { projects: projectMap } = parsedResponse;
      const projects = Object.values(projectMap);
      const defaultProject = projects[0];
      const defaultType = defaultProject?.types?.[0];

      setWorkItem({ typeName: defaultType?.name, projectID: defaultProject?.id });
      setProjects(projects);
    };

    fetchAndSetProjects();
  }, []);

  const { projectID, typeName } = workItem;
  useEffect(() => {
    const fetchAndSetFields = async () => {
      if (!projectID || !typeName) {
        return;
      }

      setLoadingFields(true);
      setWorkItem({ fields: {} }); // reset field values

      const response = await AJAX.post('/api/azureDevops/workItems/getFields', {
        projectID,
        type: typeName,
      });

      setLoadingFields(false);

      const { error, parsedResponse } = parseAPIResponse(response, {
        errors: ErrorMessages,
        isSuccessful: (parsedResponse) => !!parsedResponse.fields,
      });

      if (error) {
        setError({ type: ErrorTypes.fields, message: error.message });
        return;
      }

      const { fields } = parsedResponse;
      setFields(fields);

      // store values for field with default values
      const fieldValues = fields
        .filter((field) => !isNil(field.defaultValue))
        .reduce((fieldValues, field) => {
          const parse = Fields[field.type].parser;
          return {
            ...fieldValues,
            [field.referenceName]: parse(field.defaultValue),
          };
        }, {});
      setWorkItem({ fields: fieldValues });
    };

    fetchAndSetFields();
  }, [typeName, projectID]);

  // utils
  const createIssue = async () => {
    const { typeName, projectID, title, fields: fieldValueMap } = workItem;

    setCreating(true);

    const serializedFieldMap = fields.reduce((serializedFieldMap, field) => {
      const serialize = Fields[field.type].serializer;
      const fieldValue = fieldValueMap[field.referenceName];
      return {
        ...serializedFieldMap,
        [field.referenceName]: serialize(fieldValue),
      };
    }, {});

    const response = await AJAX.post('/api/azureDevops/workItems/createAndLink', {
      fields: serializedFieldMap,
      postID: post._id,
      projectID,
      title,
      type: typeName,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setCreating(false);
      setError({
        type: ErrorTypes.create,
        message: error.type === 'default' ? error.message : error.type,
      });
      return;
    }

    await reloadPost(post);

    onWorkItemCreated?.();
    onClose?.();
  };

  const onProjectChange = (projectID) => {
    const team = getProject(projectID);
    const typeName = team.types?.[0]?.name;

    setWorkItem({ typeName, projectID });
  };

  const onCustomFieldChange = (value, field) => {
    const isValid = Fields[field.type].validator;
    const parse = Fields[field.type].parser;
    const parsedValue = parse(value);
    if (!isValid(parsedValue)) {
      setFieldErrors({ [field.referenceName]: true });
      return;
    }

    setFieldErrors({ [field.referenceName]: false });
    setWorkItem({
      fields: { ...workItem.fields, [field.referenceName]: parsedValue },
    });
  };

  // renders
  const renderFields = () => {
    if (loadingFields) {
      return (
        <div className="customFields loading">
          <Spinner />
        </div>
      );
    } else if (error?.type === ErrorTypes.fields) {
      return (
        <div className="error" role="alert">
          {error.message}
        </div>
      );
    }

    return (
      <div className="customFields">
        {fields.map((field) => (
          <Tooltip position="top" delay={1000} key={field.referenceName} value={field.helpText}>
            <AdminCreateAzureDevopsWorkItemField
              errored={fieldErrors[field.referenceName]}
              field={field}
              onCustomFieldChange={onCustomFieldChange}
            />
          </Tooltip>
        ))}
      </div>
    );
  };

  const renderContents = () => {
    if (error?.type === ErrorTypes.projects) {
      return (
        <div className="error" role="alert">
          {error.message}
        </div>
      );
    } else if (!error && (!projects || !fields)) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    }

    const selectedProject = getProject(workItem.projectID);
    const selectedType = getType(workItem.typeName, workItem.projectID);
    const projectOptions = projects.map((project) => ({
      name: project.id,
      render: project.name,
    }));
    const typeOptions = selectedProject.types.map((type) => ({
      name: type.name,
      render: type.name,
    }));

    if (!projectOptions.length) {
      return (
        <div className="error" role="alert">
          It looks like there are no available Azure Devops Project. Please add a Project on Azure
          Devops before creating a work&nbsp;item.
        </div>
      );
    }

    const isFilled = Object.keys(workItem.fields || {}).length === fields?.length && workItem.title;
    const hasErrors = Object.values(fieldErrors).some(Boolean);
    const isDisabled = !isFilled || hasErrors;
    return (
      <>
        <div className="createWorkItemForm">
          <div className="dropdownsContainer">
            <div className="dropdownOuterContainer">
              <UppercaseHeader>Project</UppercaseHeader>
              <ControlledDropdown
                options={projectOptions}
                onChange={(projectID) => onProjectChange(projectID)}
                selectedName={selectedProject.id}
              />
            </div>
            <div className="dropdownOuterContainer">
              <UppercaseHeader>Type</UppercaseHeader>
              <ControlledDropdown
                options={typeOptions}
                onChange={(typeName) => setWorkItem({ typeName })}
                selectedName={selectedType.name}
              />
            </div>
          </div>
          <div>
            <TextInput
              defaultValue={post.title}
              inset="Title"
              onChange={(e) => setWorkItem({ title: e.target.value })}
            />
            {renderFields()}
          </div>
        </div>
        <div>
          <hr className="divisor" />
          <div className="submitSection">
            <div className="error" role="alert">
              {error?.type === ErrorTypes.create && error.message}
            </div>
            <Button
              disabled={isDisabled}
              buttonType="cannyButton"
              loading={creating}
              onTap={createIssue}
              value="Create & Link Work Item"
            />
          </div>
        </div>
      </>
    );
  };

  const render = () => {
    return (
      <ModalPortal
        className="adminCreateAzureDevopsWorkItemModal"
        closeOnClickAway={true}
        onClose={onClose}>
        <div className="container">
          <div>
            <div className="heading">Create a new Work Item</div>
            <hr className="divisor" />
          </div>
          {renderContents()}
        </div>
      </ModalPortal>
    );
  };

  return render();
};

AdminCreateAzureDevopsWorkItemModal.propTypes = {
  onClose: PropTypes.func,
  onWorkItemCreated: PropTypes.func,
  post: PropTypes.object,
};

export default connect(null, (dispatch) => ({
  reloadPost: (post) => dispatch(reloadPost(post)),
}))(AdminCreateAzureDevopsWorkItemModal);
