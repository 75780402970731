import React, { useEffect, useState } from 'react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import { P } from 'common/ui/Text';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Slack, SlackSettings } from 'common/api/endpoints/slack';
import type { Dispatch } from 'redux';

import 'css/components/subdomain/admin/_AdminDirectMessagesSettings.scss';

type Props = Pick<Slack, 'settings'> & {
  reloadCompany: () => void;
};

const AdminDirectMessagesSettings = ({ settings, reloadCompany }: Props) => {
  const [options, setOptions] = useState(settings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setOptions({ disableSpamButtonOnDirectMessages: settings.disableSpamButtonOnDirectMessages });
  }, [settings.disableSpamButtonOnDirectMessages]);

  const updateSlack = async (settings: SlackSettings) => {
    setError('');
    setLoading(true);
    const response = await AJAX.post('/api/slack/updateSettings', {
      settings,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    setLoading(false);

    if (error) {
      setError(error.message);
    } else {
      reloadCompany();
    }
  };
  return (
    <div className="adminDirectMessagesSettings">
      <UppercaseHeader>Settings</UppercaseHeader>
      {error ? (
        <P className="errorText">{error}</P>
      ) : (
        <div className="settings">
          <div className="settingList">
            <Tooltip value="Control if mark as spam button should be visible on direct messages">
              <label className="settingLabel">
                <input
                  className="checkboxInput"
                  checked={options.disableSpamButtonOnDirectMessages}
                  onChange={(e) =>
                    setOptions({
                      ...options,
                      disableSpamButtonOnDirectMessages: e.currentTarget.checked,
                    })
                  }
                  type="checkbox"
                />
                <div className="settingName">Disable spam button on direct messages</div>
              </label>
            </Tooltip>
          </div>

          <Button
            buttonType="cannyButton"
            disabled={
              options.disableSpamButtonOnDirectMessages ===
              settings.disableSpamButtonOnDirectMessages
            }
            loading={loading}
            onTap={() => {
              updateSlack(options);
            }}
            value="Save"
          />
        </div>
      )}
    </div>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    dispatch(reloadCompany());
  },
}))(AdminDirectMessagesSettings);
