import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import VideoNUX from 'common/common/VideoNUX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import AdminBoardPrivacyForm from 'common/subdomain/admin/AdminBoardPrivacyForm';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import validateInviteEmails from 'common/util/validateInviteEmails';
import withContexts from 'common/util/withContexts';

import VideoThumbnailPrivacy from 'img/video-thumbnail-privacy.jpg';

import 'css/components/subdomain/admin/_AdminBoardSettingsPrivacy.scss';

class AdminBoardSettingsPrivacy extends Component {
  static propTypes = {
    board: PropTypes.shape({
      invites: PropTypes.array,
      settings: PropTypes.shape({
        domain: PropTypes.string,
        indexed: PropTypes.bool,
        private: PropTypes.bool,
        unlisted: PropTypes.bool,
      }),
      urlName: PropTypes.string,
    }),
    company: PropTypes.shape({
      azure: PropTypes.object,
      plan: PropTypes.object,
      subdomain: PropTypes.string,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    allowIdentified: this.props.board.settings.allowIdentified,
    domain: this.props.board.settings.domain,
    error: null,
    editingDomain: false,
    email: '',
    indexed: this.props.board.settings.indexed,
    private: this.props.board.settings.private,
    saving: false,
    segment: this.props.board.settings.segmentURLName,
    unlisted: this.props.board.settings.unlisted,
    verifiedBy: this.props.board.settings.verifiedBy || 'email',
  };

  constructor(props, context) {
    super(props, context);

    this.emailsRef = React.createRef();
  }

  onChange = (settings) => {
    this.setState(settings);
  };

  validateBillingPlans = () => {
    const {
      board,
      company: { features },
    } = this.props;
    if (!this.state.private) {
      return true;
    } else if (board.settings.private) {
      return true;
    } else if (!features || features?.privateBoards) {
      return true;
    }
    return false;
  };

  validateEmails = () => {
    const { validEmails, invalidEmails } = validateInviteEmails(this.state.email);

    if (validEmails.length > 50) {
      this.setState({
        error: 'You can only invite up to 50 new people at a time',
      });
      return false;
    }

    if (invalidEmails.length > 0) {
      this.setState({
        error: 'The following emails are invalid: ' + invalidEmails.join(', '),
      });
      return false;
    }
    return validEmails;
  };

  onSavePrivateBoard = () => {
    const validEmails = this.validateEmails();
    if (!validEmails) {
      this.setState({
        saving: false,
      });
      return;
    }

    const { board } = this.props;

    AJAX.post(
      '/api/boards/editSettings',
      {
        allowIdentified: this.state.allowIdentified,
        boardID: board._id,
        domain: this.state.domain,
        indexed: false,
        private: true,
        segmentURLName: this.state.segment,
        unlisted: true,
        verifiedBy: this.state.verifiedBy,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            saving: false,
          });
          return;
        }

        AJAX.post(
          '/api/boards/sendInvites',
          {
            boardID: board._id,
            emails: validEmails,
          },
          (response) => {
            if (response !== 'success') {
              this.setState({
                error: 'Something went wrong, please try again later',
                saving: false,
              });
              return;
            }

            this.props.reloadBoard(board.urlName).then(() => {
              this.emailsRef.current.setValue('');
              this.setState({
                email: '',
                saving: false,
              });
            });
          }
        );
      }
    );
  };

  onSavePublicBoard = () => {
    AJAX.post(
      '/api/boards/editSettings',
      {
        allowIdentified: this.state.allowIdentified,
        boardID: this.props.board._id,
        indexed: this.state.indexed,
        private: false,
        unlisted: this.state.unlisted,
      },
      (response) => {
        if (response === 'success') {
          this.props.reloadBoard(this.props.board.urlName).then(() => {
            this.setState({
              saving: false,
            });
          });
          return;
        }

        this.setState({
          error: 'Something went wrong, please try again later',
          saving: false,
        });
      }
    );
  };

  onSave = () => {
    if (this.state.saving) {
      return;
    }

    this.setState({
      error: null,
      saving: true,
    });
    if (this.state.private) {
      this.onSavePrivateBoard();
    } else {
      this.onSavePublicBoard();
    }
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  hasSettingsChange() {
    if (this.state.private !== this.props.board.settings.private) {
      return true;
    }

    if (this.state.private) {
      return (
        this.state.domain !== this.props.board.settings.domain ||
        this.state.verifiedBy !== this.props.board.settings.verifiedBy ||
        this.state.allowIdentified !== this.props.board.settings.allowIdentified ||
        this.state.segment !== this.props.board.settings.segmentURLName ||
        this.state.email !== ''
      );
    }

    return (
      this.state.indexed !== this.props.board.settings.indexed ||
      this.state.unlisted !== this.props.board.settings.unlisted
    );
  }

  renderSaveButton() {
    if (this.state.private && !this.validateBillingPlans()) {
      return null;
    }

    return (
      <Button
        buttonType="cannyButton"
        disabled={!this.hasSettingsChange()}
        loading={this.state.saving}
        onTap={this.onSave}
        value="save"
      />
    );
  }

  render() {
    const { board } = this.props;
    return (
      <div className="adminBoardSettingsPrivacy">
        <Helmet title={'Privacy Settings | ' + board.name + ' | Canny'} />
        <VideoNUX
          name="boardPrivacy"
          pillText="Learn"
          text="Let's take a look at your different board privacy options and&nbsp;settings."
          videoThumbnail={VideoThumbnailPrivacy}
          videoURL="https://www.loom.com/embed/54674bb4aab342a8b1798c8db6b0135a?autoplay=1"
        />
        <AdminBoardPrivacyForm
          allowIdentified={this.state.allowIdentified}
          board={board}
          domain={this.state.domain}
          emailsRef={this.emailsRef}
          indexed={this.state.indexed}
          onChange={this.onChange}
          private={this.state.private}
          segment={this.state.segment}
          unlisted={this.state.unlisted}
          verifiedBy={this.state.verifiedBy}
        />
        {this.renderError()}
        {this.renderSaveButton()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board.privacy),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminBoardSettingsPrivacy);
