import React from 'react';

import { RoutePermissions } from 'common/util/permissions';

import AdminSettingsRedirect from './AdminSettingsRedirect';

const AdminBoardSettingsRedirect = () => (
  <AdminSettingsRedirect
    routePermissions={RoutePermissions.adminSettings.board}
    urlPrefix={({ boardURLName }) => `boards/${boardURLName}`}
  />
);

const AdminChangelogSettingsRedirect = () => (
  <AdminSettingsRedirect
    routePermissions={RoutePermissions.adminSettings.changelog}
    urlPrefix="changelog"
  />
);

const AdminFieldsSettingsRedirect = () => (
  <AdminSettingsRedirect
    routePermissions={RoutePermissions.adminSettings.postFields}
    urlPrefix="fields"
  />
);

const AdminGeneralSettingsRedirect = () => (
  <AdminSettingsRedirect
    routePermissions={RoutePermissions.adminSettings.general}
    urlPrefix="company"
  />
);

const AdminRoadmapStatusSettingsRedirect = () => (
  <AdminSettingsRedirect
    routePermissions={RoutePermissions.adminSettings.roadmap}
    urlPrefix="roadmap"
  />
);

const AdminNotificationSettingsRedirect = () => (
  <AdminSettingsRedirect
    routePermissions={RoutePermissions.adminSettings.notifications}
    urlPrefix="user-emails"
  />
);

const AdminTeamSettingsRedirect = () => (
  <AdminSettingsRedirect routePermissions={RoutePermissions.adminSettings.team} urlPrefix="team" />
);

export {
  AdminBoardSettingsRedirect,
  AdminChangelogSettingsRedirect,
  AdminFieldsSettingsRedirect,
  AdminGeneralSettingsRedirect,
  AdminNotificationSettingsRedirect,
  AdminRoadmapStatusSettingsRedirect,
  AdminTeamSettingsRedirect,
};
