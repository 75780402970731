import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadAllBoards, reloadBoard } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidateQueriesAndReload } from 'common/actions/queueItemQueries';
import { invalidateRoadmap } from 'common/actions/roadmap';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import Strings from 'common/Strings';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminBoardSettingsDelete.scss';

class AdminBoardSettingsDelete extends Component {
  static propTypes = {
    board: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    canDelete: false,
    deleting: false,
  };

  onInputChange = (e) => {
    const { board } = this.props;
    const value = e.target.value;
    this.setState({
      canDelete: value.toLowerCase() === board.name.toLowerCase(),
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { canDelete, deleting } = this.state;

    if (!canDelete || deleting) {
      return;
    }

    const { board, invalidateBoardData, reloadBoard, router, showToast } = this.props;

    this.setState({
      deleting: true,
    });

    const response = await AJAX.post('/api/boards/delete', {
      boardID: board._id,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: Strings.miscError,
      },
    });

    if (error) {
      this.setState({
        deleting: false,
      });
      showToast(error.message, ToastTypes.error);
      return;
    }

    // Wait until board data is invalidated, otherwise it crashes AdminDashboard
    await invalidateBoardData();
    router.push('/admin/settings/boards/');
    showToast(`Board ${board.name} is successfully deleted`);
    reloadBoard(board.urlName);
  };

  render() {
    const { board } = this.props;

    if (!board) {
      return null;
    }

    return (
      <div className="adminBoardSettingsDelete">
        <Helmet title={'Delete Board | ' + board.name + ' | Canny'} />
        <Form
          addEventsToDocument={false}
          onSubmit={this.onSubmit}
          disableSubmit={this.state.deleting}>
          <div className="warning">
            Warning:
            <ol>
              <li>All posts on the board will be deleted.</li>
              <li>This action cannot be undone.</li>
            </ol>
          </div>
          <div className="message">Enter the name of this board below to confirm deletion.</div>
          <TextInput onChange={this.onInputChange} />
          <Button
            buttonType="cannyButton"
            disabled={!this.state.canDelete}
            loading={this.state.deleting}
            formButton={true}
            value="Delete Board"
          />
        </Form>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateBoardData: () => {
      return Promise.all([
        dispatch(invalidateDashboardActivity()),
        dispatch(invalidatePostQueries()),
        dispatch(invalidateRoadmap()),
        dispatch(reloadAllBoards()),
        dispatch(invalidateQueriesAndReload()),
      ]);
    },
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName)), dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board.delete),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
      showToast: ShowToastContext,
    },
    { forwardRef: true }
  )
)(AdminBoardSettingsDelete);
