import promisify from 'common/util/promisify';

import Data from '../Data';

import type { Confidence, InboxItem, ItemType, SortOptions } from 'common/api/resources/inbox';
import type { Dispatch, GetState, State } from 'redux-connect';

// Action Types
export const REQUEST_QUERY = 'canny/inbox_item_queries/request_query';
export const QUERY_LOADED = 'canny/inbox_item_queries/query_loaded';
export const QUERY_ERROR = 'canny/inbox_item_queries/query_error';
export const INVALIDATE_AND_RELOAD = 'canny/inbox_item_queries/invalidate_and_reload';
export const LOADING_MORE = 'canny/inbox_item_queries/loading_more';

export type QueryParams = {
  boardURLNames?: string[];
  confidence?: Confidence;
  itemType?: ItemType;
  lastCreated?: Date;
  limit?: number;
  sort?: SortOptions;
  spam?: boolean;
};

type Result = {
  lastCreated?: Date | null;
  items: InboxItem[];
  totalItemCount: number;
};

export type InboxAction = Action & {
  queryParams: QueryParams;
  result: Result;
};

// Actions

function requestQuery(queryParams: QueryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: REQUEST_QUERY,
  };
}

function queryLoaded(queryParams: QueryParams, result: Result, reload = false) {
  return {
    queryParams,
    reload,
    result,
    timestamp: Date.now(),
    type: QUERY_LOADED,
  };
}

function queryError(queryParams: QueryParams, error: string) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QUERY_ERROR,
  };
}

function invalidatingAndReloading(queryParams: QueryParams) {
  return {
    timestamp: Date.now(),
    queryParams,
    type: INVALIDATE_AND_RELOAD,
  };
}

function loadingMore(queryParams: QueryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LOADING_MORE,
  };
}

// Action Creators
function fetchQuery(queryParams: QueryParams, reload = false) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const data = await promisify(
        Data.fetch,
        {
          result: {
            input: queryParams,
            query: Data.queries.inboxItems,
          },
        },
        cookies
      );
      return gotResult(dispatch, queryParams, '', data.result, reload);
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return gotResult(dispatch, queryParams, error);
    }
  };
}

export function loadQuery(queryParams: QueryParams) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(itemList: { lastCreated?: Date; queryParams: QueryParams }) {
  return (dispatch: Dispatch) => {
    const { lastCreated, queryParams } = itemList;
    dispatch(loadingMore(queryParams));

    queryParams.lastCreated = lastCreated;
    return dispatch(fetchQuery(queryParams));
  };
}

export function invalidateQueriesAndReload(queryParams: QueryParams) {
  return (dispatch: Dispatch) => {
    dispatch(invalidatingAndReloading(queryParams));
    return dispatch(fetchQuery(queryParams, true));
  };
}

// Helper Functions
export function getQueryKey(queryParams: QueryParams) {
  return JSON.stringify(queryParams);
}

function shouldFetchQuery(state: State, queryParams: QueryParams) {
  const { inboxItemQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !inboxItemQueries[queryKey];
}

function isFetchingQuery(state: State, queryParams: QueryParams) {
  const { inboxItemQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !!inboxItemQueries[queryKey].loading;
}

// Callback Queue
const resultCallbacks: Record<string, (() => void)[]> = {};

async function waitForResult(queryParams: QueryParams) {
  const queryKey = getQueryKey(queryParams);
  return new Promise((resolve) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => resolve(true));
  });
}

async function gotResult(
  dispatch: Dispatch,
  queryParams: QueryParams,
  error: string,
  result?: Result,
  reload = false
) {
  // Do not save lastCreated into params
  // In order to group items coming from same filters, under same query
  const copiedParams = { ...queryParams };
  delete copiedParams.lastCreated;

  let resultAction;
  const promises: Dispatch[] = [];
  if (result) {
    resultAction = queryLoaded(copiedParams, result, reload);
  } else {
    resultAction = queryError(copiedParams, error);
  }

  await Promise.all(promises);
  await dispatch(resultAction);

  const queryKey = getQueryKey(copiedParams);
  const callbacks = resultCallbacks[queryKey];
  if (!callbacks) {
    return;
  }

  callbacks.forEach((cb) => cb());
  resultCallbacks[queryKey].length = 0;
}
