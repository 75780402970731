import AJAX from 'common/AJAX';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

const errors = {
  'team needs members': 'A team cannot have zero members. Please add at least one member.',
  'name cannot be empty': 'A team cannot have an empty name. Please enter a name for the team.',
  'not a company member': 'Only company members can be added to teams.',
};

export const createTeam = async (avatarEmoji: string, name: string, userIDs: string[]) => {
  const response = await AJAX.post('/api/teams/create', {
    avatarEmoji,
    name,
    userIDs,
  });

  return parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors,
  });
};

export const editTeam = async (
  teamID: string,
  avatarEmoji: string,
  name: string,
  userIDs: string[]
) => {
  const response = await AJAX.post('/api/teams/update', {
    avatarEmoji,
    name,
    teamID,
    userIDs,
  });

  return parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors,
  });
};

export const deleteTeam = async (teamID: string) => {
  const response = await AJAX.post('/api/teams/delete', {
    teamID,
  });

  return parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors,
  });
};
