import React from 'react';

import classnames from 'classnames';
import { AlertCircle, StickyNote } from 'lucide-react';

import { P } from 'common/ui/Text';

import 'css/components/_Alert.scss';

export enum AlertTypes {
  Danger = 'Danger',
  Info = 'Info',
}

const AlertModes: Record<AlertTypes, { icon: React.ElementType; className: string }> = {
  [AlertTypes.Danger]: {
    icon: AlertCircle,
    className: 'danger',
  },
  [AlertTypes.Info]: {
    icon: StickyNote,
    className: 'info',
  },
};

type Props = {
  headingText: string;
  subText: React.ReactNode;
  button?: React.ReactNode;
  type: AlertTypes;
  className?: string;
};

const Alert = ({ headingText, subText, button, type, className }: Props) => {
  const { icon: Icon, className: modeClassName } = AlertModes[type];
  return (
    <div className={classnames('alertContainer', modeClassName, className)}>
      <div className="textContainer">
        <Icon className="icon" />
        <div>
          <P className="heading" variant="bodyMd" fontWeight="semibold">
            {headingText}
          </P>
          <P className="subText">{subText}</P>
        </div>
      </div>
      <div>{button}</div>
    </div>
  );
};

export default Alert;
