import React, { Component } from 'react';

import { Moon, Sun, SunMoon } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { StaticColors } from 'common/colors/constants';
import { isHexColor } from 'common/colors/utils';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import * as FileUploader from 'common/FileUploader';
import Helmet from 'common/helmets/Helmet';
import Image from 'common/Image';
import Button from 'common/inputs/Button';
import FileInput from 'common/inputs/FileInput';
import SubdomainInput from 'common/inputs/SubdomainInput';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import Strings from 'common/Strings';
import SingleSelect from 'common/ui/SingleSelect';
import { H2, H3, P } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import hasPermission from 'common/util/hasPermission';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import BrandColorPicker from './BrandColorPicker';
import ColorAccessibilityChecker from './ColorAccessibilityChecker';

import 'css/components/subdomain/admin/_AdminGeneralBrandingSettings.scss';

const ThemeOptions = [
  { label: 'System preference', value: 'auto', prefixIcon: SunMoon },
  { label: 'Light', value: 'light', prefixIcon: Sun },
  { label: 'Dark', value: 'dark', prefixIcon: Moon },
];

class AdminGeneralBrandingSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      faviconURL: PropTypes.string,
      logoURL: PropTypes.string,
      name: PropTypes.string,
      subdomain: PropTypes.string,
      tintColor: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }),
  };

  state = {
    edited: false,
    editing: false,
    error: null,
    hasEdits: false,
    faviconURL: this.props.company.faviconURL,
    logoURL: this.props.company.logoURL,
    uploadingFavicon: false,
    uploadingLogo: false,
    form: {
      name: this.props.company.name,
      subdomain: this.props.company.subdomain,
      tint: this.props.company.tintColor,
      theme: this.props.company.theme,
    },
  };

  componentDidMount() {
    const { company, openModal, router, viewer } = this.props;

    if (hasPermission('manageCompanyProfile', company, viewer)) {
      return;
    }

    router.replace('/admin');
    openModal(
      AccessModal,
      {
        requiredPermissions: ['manageCompanyProfile'],
      },
      {
        allowRouteChange: true,
      }
    );
  }

  onFormChange = (edits) => {
    this.setState((state) => ({
      edited: false,
      hasEdits: true,
      form: {
        ...state.form,
        ...edits,
      },
    }));
  };

  onNewFavicon = (file, base64URL) => {
    if (!file) {
      return;
    }

    const image = new window.Image();
    image.src = base64URL;
    image.onload = async () => {
      const { width, height } = image;
      if (width !== height) {
        this.setState({
          error: 'Please upload a square image.',
        });
        return;
      }

      this.setState({
        error: null,
        uploadingFavicon: true,
      });

      const { viewer } = this.props;
      let faviconURL = null;
      let error = null;

      try {
        faviconURL = await FileUploader.upload(file, viewer);
      } catch (err) {
        error = err;
      }

      if (error || !faviconURL) {
        const message =
          error?.data?.message ?? 'Something went wrong, please try again or contact support';
        this.setState({
          uploadingFavicon: false,
          error: message,
        });
        return;
      }

      this.setState({
        edited: false,
        hasEdits: true,
        faviconURL,
        uploadingFavicon: false,
      });
    };
  };

  onNewLogo = (file, base64URL) => {
    if (!file) {
      return;
    }

    const image = new window.Image();
    image.src = base64URL;
    image.onload = async () => {
      const { width, height } = image;
      if (width !== height) {
        this.setState({
          error: 'Please upload a square image.',
        });
        return;
      }

      this.setState({
        error: null,
        uploadingLogo: true,
      });

      const { viewer } = this.props;
      let logoURL = null;
      let error = null;

      try {
        logoURL = await FileUploader.upload(file, viewer);
      } catch (err) {
        error = err;
      }

      if (error || !logoURL) {
        const message =
          error?.data?.message ?? 'Something went wrong, please try again or contact support';
        this.setState({
          error: message,
          uploadingLogo: false,
        });
        return;
      }

      this.setState({
        edited: false,
        hasEdits: true,
        logoURL,
        uploadingLogo: false,
      });
    };
  };

  onSubmit = async () => {
    const {
      faviconURL,
      logoURL,
      form: { name, subdomain, tint: tintColor, theme },
    } = this.state;

    var error = null;
    if (!validateInput.companyName(name)) {
      error = 'Please enter a valid company name (1-100)';
    } else if (!validateInput.subdomain(subdomain)) {
      error = 'Please enter a valid subdomain (1-30)';
    } else if (!validateInput.hexColor(tintColor)) {
      error = 'Please enter a valid brand color (e.g. #abc or #abcdef)';
    }

    if (error) {
      this.setState({ error });
      return;
    }

    this.setState({
      editing: true,
      error: null,
    });

    const { company } = this.props;
    AJAX.post(
      '/api/company/edit',
      {
        faviconURL,
        logoURL,
        name,
        subdomain,
        theme,
        tintColor,
      },
      (response) => {
        if (response !== 'success') {
          var responseObject;
          try {
            responseObject = JSON.parse(response);
          } catch (e) {
            responseObject = { error: 'server error' };
          }

          var error = Strings.miscError;
          if (responseObject.error && responseObject.error === 'subdomain taken') {
            error = 'This subdomain is taken';
          }

          this.setState({
            editing: false,
            error,
          });
          return;
        }

        this.setState({
          edited: true,
          editing: false,
          hasEdits: false,
        });

        if (company.subdomain === subdomain) {
          this.props.reloadCompany();
        } else {
          const {
            location: { pathname },
          } = this.props;
          const protocol = window.location.protocol + '//';
          const host = subdomain + '.canny.io';
          const newURL = devURL(protocol + host + pathname);
          window.location.assign(newURL);
        }
      }
    );
  };

  renderLogoSettings() {
    const { logoURL, uploadingLogo } = this.state;
    const logo = logoURL ? (
      <Image loader={<div className="loadingLogo" />} src={logoURL} />
    ) : (
      <div className="noLogo">
        <div className="icon icon-image" />
      </div>
    );

    return (
      <div className="logoSettings">
        <div className="logoContainer">{logo}</div>
        <FileInput
          onFile={this.onNewLogo}
          value={uploadingLogo ? <Spinner /> : <span>Upload logo</span>}
        />
        <div className="guidelines">
          <div>Max: 8 MB</div>
          <div>Ratio: 1:1</div>
        </div>
      </div>
    );
  }

  renderFaviconSettings() {
    const { faviconURL, uploadingFavicon } = this.state;
    const favicon = faviconURL ? (
      <Image loader={<div className="loadingFavicon" />} src={faviconURL} />
    ) : (
      <div className="noFavicon">
        <div className="icon icon-image" />
      </div>
    );

    return (
      <div className="faviconSettings">
        <div className="faviconContainer">{favicon}</div>
        <FileInput
          onFile={this.onNewFavicon}
          value={uploadingFavicon ? <Spinner /> : <span>Upload favicon</span>}
        />
        <div className="guidelines">
          <div>Recommended size: 32 x 32 pixels</div>
          <div>Ratio: 1:1</div>
        </div>
      </div>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    const { company } = this.props;
    const {
      edited,
      editing,
      hasEdits,
      form: { name, subdomain, tint, theme },
    } = this.state;

    const selectedTheme = ThemeOptions.find((option) => option.value === theme);
    return (
      <div className="adminGeneralBrandingSettings">
        <Helmet title="Branding | Company Setting | Canny" />
        <div className="content">
          {this.renderLogoSettings()}
          {this.renderFaviconSettings()}
          <TextInput
            defaultValue={name}
            inset="Name"
            onChange={(event) => this.onFormChange({ name: event.currentTarget.value })}
          />
          <SubdomainInput
            input={subdomain}
            inset="subdomain"
            onChange={(event) => this.onFormChange({ subdomain: event.currentTarget.value })}
          />
          <div className="divider" />
          <H2 variant="headingMd">Theme</H2>
          <div className="themePicker">
            <div className="left">
              <H3 resetStyles variant="headingSm">
                Feedback portal theme
              </H3>
              <P className="subtitle" variant="bodyMd">
                Select an interface theme for your public feedback portal.
              </P>
            </div>
            <SingleSelect
              className="themeSelect"
              onChange={(selectedOption) => this.onFormChange({ theme: selectedOption.value })}
              options={ThemeOptions}
              value={selectedTheme}
            />
          </div>
          <BrandColorPicker color={tint} onColorPicked={(tint) => this.onFormChange({ tint })} />
          <ColorAccessibilityChecker
            className="brandAccessibilityChecker"
            foreground={isHexColor(tint) ? tint : company.tintColor}
            backgrounds={[StaticColors.white, StaticColors.darkThemeBackground].filter(isHexColor)}
          />
          {this.renderError()}
          <Button
            disabled={!hasEdits}
            loading={editing}
            onTap={this.onSubmit}
            value={edited ? 'Saved' : 'Save'}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.general.branding),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminGeneralBrandingSettings);
