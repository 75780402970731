import { LoadStatus, MimeTypes, type UploadedFile } from 'common/constants/files';
import generateFileID from 'common/file/utils/generateFileID';
import getFileNameWithoutExtension from 'common/file/utils/getFileNameWithoutExtension';
import getExtension from 'common/util/getExtension';

type FileNameAndURL = {
  name: string;
  url: string;
};

const isSupportedExtension = (extension: unknown): extension is keyof typeof MimeTypes => {
  return typeof extension === 'string' && extension in MimeTypes;
};

const getContentType = (extension: string | null) => {
  if (!extension) {
    return null;
  }

  if (isSupportedExtension(extension)) {
    return MimeTypes[extension];
  }

  // add extra exception to handle jpgs here, as adding an additional MimeType definition with the same value
  // causes clashes with other code
  // jpgs should be handled/renamed to jpegs on the BE
  if (extension === 'jpg') {
    return MimeTypes.jpeg;
  }

  return null;
};

export const createFiles = (files: FileNameAndURL[]): UploadedFile[] => {
  return files.map(({ name, url }) => {
    const temporaryUniqueID = generateFileID();
    const extension = getExtension(url);
    const nameWithoutExtension = getFileNameWithoutExtension(name);

    return {
      name: nameWithoutExtension,
      // NOTE: We use unique ID as the key of file elements.
      // We need a constant value (such as url) to keep the same file element when the file is re-rendered.
      uniqueID: url || temporaryUniqueID,
      contentType: getContentType(extension),
      uploadStatus: LoadStatus.loaded,
      url,
    };
  });
};

export const createImageFiles = (urls: string[]): UploadedFile[] => {
  const filesWithNames = urls.map((url) => ({ name: url, url }));
  return createFiles(filesWithNames);
};
