import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { compose } from 'redux';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Helmet from 'common/helmets/Helmet';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import getPlanNameForTrial from 'common/util/getPlanNameForTrial';
import { GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterPlanID } from 'common/util/isStarter';
import nbspLastSpace from 'common/util/nbspLastSpace';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import ClickUpLogo from 'img/landing/clickup-logo.webp';
import GoogleLogo from 'img/landing/google-logo.webp';
import HubSpotLogo from 'img/landing/hubspot-logo.webp';
import LinearLogo from 'img/landing/linear-logo.webp';
import MsTeamsLogo from 'img/landing/microsoft-teams-logo.webp';
import OktaLogo from 'img/landing/okta-logo.webp';
import OneLoginLogo from 'img/landing/onelogin-logo.webp';
import SegmentLogo from 'img/landing/segment-logo.webp';

import AsanaLogo from 'img/asana-logo.png';
import AzureDevopsLogo from 'img/azure-devops-logo.png';
import AzureLogo from 'img/azure-logo-icon.png';
import ChromeLogo from 'img/chrome-logo.svg';
import DiscordLogo from 'img/discord-logo.png';
import GoogleAnalyticsLogo from 'img/ga-logo.png';
import GongLogo from 'img/gong-logo.png';
import HelpscoutLogo from 'img/helpscout-logo.png';
import CogIcon from 'img/icons/cog.svg';
import GitHubLogo from 'img/landing/github-logo.svg';
import IntercomLogo from 'img/landing/intercom-logo.svg';
import JiraLogo from 'img/landing/jira-logo.svg';
import SalesforceLogo from 'img/landing/salesforce-logo.svg';
import SlackLogo from 'img/landing/slack-logo.svg';
import ZapierLogo from 'img/landing/zapier-logo.svg';
import ZendeskLogo from 'img/landing/zendesk-logo.svg';
import OIDCLogo from 'img/open-id-connect-logo.png';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminIntegrationSettings.scss';

type IntegrationCountProps = {
  integrationCount: number;
  integrationLimit?: number;
  onClick: () => void;
};

type IntegrationLinkProps = {
  children: React.ReactNode;
  connected: boolean;
  destination: string;
  external: boolean;
  onClick?: () => void;
};

type IntegrationRowProps = {
  aiSource?: boolean;
  hidden?: boolean;
  url: string;
  name: string;
  logo: string;
  connected: boolean;
  details: string;
  external?: boolean;
  onClick?: () => void;
};

const IntegrationCount = ({
  integrationCount,
  integrationLimit,
  onClick,
}: IntegrationCountProps) => {
  if (integrationLimit) {
    return (
      <>
        {integrationCount}/{integrationLimit}{' '}
        <button className="openModalButton" onClick={onClick}>
          Get more integrations
        </button>
      </>
    );
  } else {
    return <>{integrationCount}/unlimited</>;
  }
};

const IntegrationLink = ({
  children,
  connected,
  destination,
  external,
  onClick,
}: IntegrationLinkProps) => {
  const classNames = classnames('integrationLink', { active: connected });
  if (onClick) {
    return (
      <button className={classNames} onClick={onClick}>
        {children}
      </button>
    );
  }

  if (external) {
    return (
      <a href={destination} target="_blank" rel="noopener" className={classNames}>
        {children}
      </a>
    );
  }
  return (
    <Link to={destination} className={classNames}>
      {children}
    </Link>
  );
};

const IntegrationRow = ({
  aiSource,
  hidden,
  url,
  name,
  logo,
  connected,
  details,
  external = false,
  onClick,
}: IntegrationRowProps) => {
  if (hidden) {
    return null;
  }

  return (
    <IntegrationLink destination={url} connected={connected} external={external} onClick={onClick}>
      <LazyLoadedImage src={logo} alt={`${name} logo`} className="integrationLogo" />
      <div className="integrationDetails">
        <h3 className="integrationTitle">
          {name}{' '}
          {aiSource ? (
            <Pill pillStyle={DefaultPillStyles.canny} className="integrationPill">
              AI Source
            </Pill>
          ) : null}{' '}
          {connected ? (
            <Pill pillStyle={DefaultPillStyles.success} className="integrationPill">
              Connected
            </Pill>
          ) : null}
        </h3>
        <p>{details}</p>
      </div>
      <LazyLoadedImage src={CogIcon} alt="Settings icon" />
    </IntegrationLink>
  );
};

const AdminIntegrationSettings = () => {
  const { activeIntegrations, integrationCount, integrationLimit } =
    useContext(ActiveIntegrationContext);
  const company = useContext<Company>(CompanyContext);

  const [upsellFeature, setUpsellFeature] = useState<string | null>(null);
  const [upsellCTA, setUpsellCTA] = useState<string | null>(null);
  const [upsellPlanID, setUpsellPlanID] = useState<string | null>(null);

  const planName = company.trialingPlan
    ? `${getPlanNameForTrial(company.trialingPlan)} (trial)`
    : company.billingData?.plan?.name;
  const hasBrowserExtensionAccess = company.integrations?.browserExtension;

  const openUpsellModal = (feature: string, cta: string, planID: string) => {
    setUpsellFeature(feature);
    setUpsellCTA(cta);
    setUpsellPlanID(planID);
  };

  const closeUpsellModal = () => {
    setUpsellFeature(null);
    setUpsellCTA(null);
    setUpsellPlanID(null);
  };

  return (
    <>
      <div className="adminIntegrationSettings">
        <Helmet title="Integrations | Canny" />
        <AdminSettingsHeader
          title="Integrations"
          subheading="Connect Canny to your tools and&nbsp;services."
          learnMoreLink="https://help.canny.io/en/collections/325118-canny-integrations"
        />
        <div className="content">
          <dl className="details text">
            <div>
              <dt>Current plan:</dt>
              <dd>{planName}</dd>
            </div>
            <div>
              <dt>Integrations installed:</dt>
              <dd>
                <IntegrationCount
                  onClick={() => {
                    const integrationLimit = company.limits.integrations;
                    const planID = integrationLimit === 1 ? StarterPlanID : GrowthPlanID;
                    const amount = isStarter(planID) ? 'More' : 'Unlimited';

                    openUpsellModal(
                      'limits.integrations',
                      `${amount} integrations to suit your workflow`,
                      planID
                    );
                  }}
                  integrationCount={integrationCount}
                  integrationLimit={integrationLimit}
                />
              </dd>
            </div>
          </dl>

          <div className="section">
            <h2 className="sectionHeading">Project management tools</h2>
            <IntegrationRow
              url="/admin/settings/asana"
              name="Asana"
              logo={AsanaLogo}
              connected={activeIntegrations.asana}
              details="Push Canny posts to Asana, link them to existing Asana tasks, and sync statuses between."
            />
            <IntegrationRow
              url="/admin/settings/azure-devops"
              name="Azure DevOps"
              logo={AzureDevopsLogo}
              connected={activeIntegrations.azureDevops}
              details="Push Canny posts to Azure DevOps, link them to existing Azure DevOps work items, and sync statuses between. Only for Azure DevOps&nbsp;Services."
            />
            <IntegrationRow
              url="/admin/settings/jira"
              name="Jira"
              logo={JiraLogo}
              connected={activeIntegrations.jira}
              details="Push Canny posts to Jira, link them to existing Jira issues, and sync statuses between. Only for Jira&nbsp;Cloud."
            />
            <IntegrationRow
              url="/admin/settings/github"
              name="Github"
              logo={GitHubLogo}
              connected={activeIntegrations.github}
              details="Push Canny posts to GitHub, link them to existing GitHub issues, and sync statuses&nbsp;between."
            />
            <IntegrationRow
              url="/admin/settings/clickup"
              name="ClickUp"
              logo={ClickUpLogo}
              connected={activeIntegrations.clickup}
              details="Push Canny posts to ClickUp, link them to existing ClickUp tasks, and sync statuses&nbsp;between."
            />
            <IntegrationRow
              url="/admin/settings/linear"
              name="Linear"
              logo={LinearLogo}
              connected={activeIntegrations.linear}
              details="Push Canny posts to Linear, link them to existing Linear issues, and sync statuses&nbsp;between."
            />
          </div>

          <div className="section">
            <h2 className="sectionHeading">Communication tools</h2>
            <IntegrationRow
              aiSource
              url="/admin/settings/intercom"
              name="Intercom"
              logo={IntercomLogo}
              connected={activeIntegrations.intercom}
              details="Track the feedback you get in Intercom messenger home and live chat."
            />
            <IntegrationRow
              aiSource
              url="/admin/settings/zendesk"
              name="Zendesk"
              logo={ZendeskLogo}
              connected={activeIntegrations.zendesk}
              details="Track feedback from tickets without leaving&nbsp;Zendesk."
            />
            <IntegrationRow
              url="/admin/settings/salesforce"
              name="Salesforce"
              logo={SalesforceLogo}
              connected={activeIntegrations.salesforce}
              details="Track feedback on behalf of contacts and opportunities in&nbsp;Salesforce."
            />
            <IntegrationRow
              url="/admin/settings/hubspot"
              name="HubSpot"
              logo={HubSpotLogo}
              connected={activeIntegrations.hubspot}
              details="Track feedback on behalf of contacts and deals in&nbsp;HubSpot."
            />
            <IntegrationRow
              url="/admin/settings/slack"
              name="Slack"
              logo={SlackLogo}
              connected={activeIntegrations.slack}
              details="Get instant notifications for posts, comments, vote milestones, and status&nbsp;changes."
            />
            <IntegrationRow
              url="/admin/settings/microsoft-teams"
              name="Microsoft Teams"
              logo={MsTeamsLogo}
              connected={activeIntegrations.microsoftTeams}
              details="Get instant notifications for posts, comments, vote milestones, and status&nbsp;changes."
            />
            <IntegrationRow
              url="/admin/settings/discord"
              name="Discord"
              logo={DiscordLogo}
              connected={activeIntegrations.discord}
              details="Get instant notifications for posts, comments, votes, milestones, and status&nbsp;changes."
            />
            <IntegrationRow
              aiSource
              url="/admin/settings/gong"
              name="Gong"
              logo={GongLogo}
              connected={activeIntegrations.gong}
              details="Automatically pull in feedback from transcripts in Gong."
            />
            <IntegrationRow
              aiSource
              url="/admin/settings/helpscout"
              name="Help Scout"
              logo={HelpscoutLogo}
              connected={activeIntegrations.helpscout}
              details="Automatically pull in feedback from conversations in Help Scout."
            />
          </div>
          <div className="section">
            <h2 className="sectionHeading">Identity & Authentication</h2>
            <IntegrationRow
              url="/admin/settings/azure"
              name="Azure"
              logo={AzureLogo}
              connected={activeIntegrations.azure}
              details="Grant access to private boards via Azure&nbsp;AD."
            />
            <IntegrationRow
              url="/admin/settings/google-workspace"
              name="Google Workspace"
              logo={GoogleLogo}
              connected={activeIntegrations.gsuite}
              details="Import users from Google Workspace to track feedback on behalf of members of your&nbsp;organization."
            />
            <IntegrationRow
              url="/admin/settings/oidc"
              name="OpenID Connect"
              logo={OIDCLogo}
              connected={activeIntegrations.oidc}
              details="Grant admin access into Canny via&nbsp;OpenID Connect."
            />
            <IntegrationRow
              url="/admin/settings/okta"
              name="Okta"
              logo={OktaLogo}
              connected={activeIntegrations.okta}
              details="Grant admin access into Canny via&nbsp;Okta."
            />
            <IntegrationRow
              url="/admin/settings/onelogin"
              name="OneLogin"
              logo={OneLoginLogo}
              connected={activeIntegrations.oneLogin}
              details="Grant admin access into Canny via&nbsp;OneLogin."
            />
          </div>
          <div className="section">
            <h2 className="sectionHeading">Other Tools</h2>
            <IntegrationRow
              url="/admin/settings/zapier"
              name="Zapier"
              logo={ZapierLogo}
              connected={activeIntegrations.zapier}
              details="Easily integrate Canny with 5,000+ other apps on&nbsp;Zapier."
            />
            <IntegrationRow
              url="/admin/settings/segment"
              name="Segment"
              logo={SegmentLogo}
              connected={activeIntegrations.segment}
              details="Sync rich user data to better inform your product&nbsp;decisions."
            />
            <IntegrationRow
              url="https://chrome.google.com/webstore/detail/canny/ppljpoflocngelbkbmebgkpdbbjaejhi"
              name="Chrome Extension"
              logo={ChromeLogo}
              connected={false}
              details="Lets you capture feedback from any website, and link the feedback back to its source."
              external
              onClick={
                !hasBrowserExtensionAccess
                  ? () => {
                      openUpsellModal(
                        'integrations.browserExtension',
                        'Track feedback from any webpage via Chrome',
                        GrowthPlanID
                      );
                    }
                  : undefined
              }
            />
          </div>
          <div className="section">
            <h2 className="sectionHeading">Included in Canny Free</h2>
            <IntegrationRow
              url="/admin/settings/google-analytics"
              name="Google Analytics"
              logo={GoogleAnalyticsLogo}
              connected={activeIntegrations.googleAnalytics}
              details="Keep track of any traffic to your Canny subdomain."
            />
          </div>
        </div>
      </div>
      {upsellCTA && (
        <UpsellModal
          cta={nbspLastSpace(upsellCTA)}
          feature={upsellFeature}
          onClose={() => closeUpsellModal()}
          planID={upsellPlanID}
          show={!!upsellFeature}
        />
      )}
    </>
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations),
    '/admin/settings'
  )
)(AdminIntegrationSettings);
