import React, { useContext } from 'react';

import classnames from 'classnames';
import { match } from 'react-router';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import queryString from 'common/util/queryString';
import { LinkPrefixContext } from 'common/widget/WidgetContext';

import isLeftClickEvent from './isLeftClickEvent';
import isModifiedEvent from './isModifiedEvent';
import Tappable from './Tappable';

export type Props = {
  activeClassName?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  fakeLink?: boolean;
  forwardRef?: React.Ref<HTMLAnchorElement>;
  onTap?: (e: React.MouseEvent) => void;
  preventDefaultEventKeys?: string[];
  state?: Record<string, unknown>;
  style?: Record<string, unknown>;
  to?: string | null;
};

const Link = ({
  activeClassName,
  children,
  className = '',
  disabled = false,
  fakeLink = false,
  forwardRef,
  preventDefaultEventKeys = [],
  onTap,
  style = {},
  state,
  to,
}: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const linkPrefix = useContext(LinkPrefixContext);

  const onLinkTap = (e: React.MouseEvent) => {
    if (isModifiedEvent(e, preventDefaultEventKeys) || !isLeftClickEvent(e)) {
      return;
    }

    e.persist();
    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      onTap?.(e);
      return;
    }

    if (fakeLink) {
      onTap?.(e);
      return;
    }

    if (typeof to !== 'string') {
      onTap?.(e);
      return;
    }

    const split = to.split('?');
    const pathname = split[0];
    const query = split.length > 1 ? queryString.parse('?' + split[1]) : {};
    router.push({
      pathname,
      query,
      state,
    });

    onTap?.(e);
  };

  const onClick = (e: React.MouseEvent) => {
    if (isModifiedEvent(e, preventDefaultEventKeys) || !isLeftClickEvent(e)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  let href;
  if (typeof to === 'string') {
    const split = to.split('?');
    const pathname = split[0];
    const query = split.length > 1 ? queryString.parse('?' + split[1]) : {};
    href =
      (linkPrefix || '') +
      router.createHref({
        pathname,
        query,
      });
  }

  const onMouseEnter = () => {
    if (!to || fakeLink) {
      return;
    }

    match(
      { routes: router.routes, location: { pathname: to } },
      (_e, _redirectLocation, renderProps) => {
        renderProps?.components?.forEach((component: any) => {
          if (typeof component === 'function' && component.isDynamicLoader) {
            // The route is lazily loaded, trigger the dynamicImport to load the route
            component();
          }
        });
      }
    );
  };

  return (
    <Tappable triggerOnEnter={true} onTap={onLinkTap}>
      <a
        className={classnames(className, {
          ...(activeClassName && {
            [activeClassName]: location.pathname === to,
          }),
        })}
        href={href}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        style={style}
        ref={forwardRef}>
        {children}
      </a>
    </Tappable>
  );
};

export default Link;
