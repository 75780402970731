import {
  type Action,
  ActionType,
  type Automation,
  type Filter,
  FiltersOperator,
  Resource,
  Trigger,
} from 'common/api/endpoints/automation';

import type { PostCustomFieldFilterConditions } from './postFieldFilterUtils';

// Re-export, to make all the constants available from one place
export type { Action };
export { ActionType };
export type { Automation };
export type { Filter };
export { FiltersOperator };
export { Resource };
export { Trigger };

export type EditableAutomation = Pick<
  Automation,
  'actions' | 'filters' | 'filtersOperator' | 'title' | 'trigger'
>;

// Filter types
export interface InBoardFilter extends Filter {
  resource: Resource.postBoardURLName;
  condition: 'equals';
  value: string;
}

export interface PostContainsFilter extends Filter {
  resource: Resource.postContent;
  condition: 'containsAll' | 'containsAny';
  value: string[];
}

export interface PostCustomFieldFilter extends Filter {
  resource: Resource.postCustomField;
  condition: PostCustomFieldFilterConditions;
  value: {
    fieldID: string;
    value: string;
  };
}

export type AllFilters = InBoardFilter | PostContainsFilter | PostCustomFieldFilter;

// Action types
export interface AddPostCategory extends Action {
  type: ActionType.addPostCategory;
  properties: {
    categoryID: string;
  };
}

export interface AddPostTags extends Action {
  type: ActionType.addPostTags;
  properties: {
    tagIDs: string[];
  };
}

export interface ApplyPostStatus extends Action {
  type: ActionType.applyPostStatus;
  properties: {
    statusID: string;
  };
}

export interface AddToRoadmap extends Action {
  type: ActionType.addToRoadmap;
  properties: {
    roadmapID: string;
  };
}

export type AllActions = AddPostCategory | AddPostTags | ApplyPostStatus | AddToRoadmap;

// Utils
export type ActionOptions = {
  max: number;
  requiredFields: Resource[];
};
export type FilterOptions = {
  max: number;
};

export type Schema = Record<
  Trigger,
  {
    actions: Partial<Record<ActionType, ActionOptions>>;
    filters: Partial<Record<Resource, FilterOptions>>;
  }
>;

export const automationSchema: Schema = {
  [Trigger.postCreation]: {
    actions: {
      [ActionType.addPostCategory]: { max: 1, requiredFields: [Resource.postBoardURLName] },
      [ActionType.addPostTags]: { max: 1, requiredFields: [Resource.postBoardURLName] },
      [ActionType.applyPostStatus]: { max: 1, requiredFields: [] },
      [ActionType.addToRoadmap]: { max: 5, requiredFields: [] },
    },
    filters: {
      [Resource.postBoardURLName]: { max: 1 },
      [Resource.postContent]: { max: 2 },
      [Resource.postCustomField]: { max: 5 },
    },
  },
  [Trigger.postStatusChange]: {
    actions: {
      [ActionType.addPostCategory]: { max: 1, requiredFields: [Resource.postBoardURLName] },
      [ActionType.addPostTags]: { max: 1, requiredFields: [Resource.postBoardURLName] },
      [ActionType.applyPostStatus]: { max: 1, requiredFields: [] },
      [ActionType.addToRoadmap]: { max: 5, requiredFields: [] },
    },
    filters: {
      [Resource.postBoardURLName]: { max: 1 },
      [Resource.postContent]: { max: 2 },
      [Resource.postCustomField]: { max: 5 },
    },
  },
};
