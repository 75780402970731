import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import Button from 'common/inputs/Button';
import { P } from 'common/ui/Text';
import isNil from 'common/util/isNil';
import nbspLastSpace from 'common/util/nbspLastSpace';
import numberWithCommas from 'common/util/numberWithCommas';

import 'css/components/subdomain/admin/billing/_AdminBillingPlan.scss';

export default class AdminBillingPlan extends Component {
  static propTypes = {
    breakdown: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string,
        tooltip: PropTypes.string,
        value: PropTypes.string.isRequired,
      })
    ),
    cta: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    namePill: PropTypes.string,
    onSelect: PropTypes.func,
    price: PropTypes.number,
    priceDiscounted: PropTypes.number,
    priceOld: PropTypes.number,
    priceCustom: PropTypes.bool,
    selected: PropTypes.bool,
    selecting: PropTypes.bool,
    timeframe: PropTypes.oneOf(['mo', 'yr', 'mo (billed yearly)']),
  };

  static defaultProps = {
    timeframe: 'mo',
  };

  renderPrice() {
    const { price, priceCustom, priceDiscounted, priceOld, timeframe } = this.props;

    const clean = (number) => numberWithCommas(Math.ceil(number));
    if (!isNil(price)) {
      return (
        <div className="price">
          ${clean(price)}/{timeframe}
        </div>
      );
    }

    if (priceCustom) {
      return <div className="price">Custom</div>;
    }

    if (!isNil(priceOld) && !isNil(priceDiscounted)) {
      return [
        <div className="priceOld" key="priceOld">
          ${clean(priceOld)}
        </div>,
        <div className="priceDiscounted" key="priceDiscounted">
          ${clean(priceDiscounted)}/{timeframe}
        </div>,
      ];
    }
  }

  renderBreakdown() {
    const { breakdown } = this.props;

    if (!breakdown) {
      return;
    }

    return (
      <div className="breakdown">
        <div className="title">Pricing breakdown</div>
        {breakdown.map(({ label, link, tooltip, value }) => {
          return (
            <div className="item" key={label}>
              {link ? (
                <a href={link} rel="noopener" target="_blank">
                  {label}
                </a>
              ) : (
                label
              )}
              {tooltip && (
                <Tooltip value={tooltip}>
                  <div className="icon-help" />
                </Tooltip>
              )}
              : {value}
            </div>
          );
        })}
      </div>
    );
  }

  renderFooter() {
    const { cta, disabled, onSelect, selected, selecting } = this.props;

    if (selected) {
      return;
    }

    return (
      <div className="footer">
        <Button
          buttonType="whiteButton withBorder"
          disabled={disabled}
          loading={selecting}
          value={cta}
          onTap={onSelect}
        />
        <div className="link">
          <a href="https://canny.io/pricing" rel="noopener" target="_blank">
            Learn more
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { description, name, namePill, selected } = this.props;

    return (
      <div
        className={classnames({
          adminBillingPlan: true,
          selected,
        })}>
        <div className="header">
          <div className="name">
            <P fontWeight="bold">{name}</P>
            {namePill && <Pill pillStyle={DefaultPillStyles.canny}>{namePill}</Pill>}
          </div>
          <div className="spacer" />
          {this.renderPrice()}
        </div>
        <div className="description">{nbspLastSpace(description)}</div>
        <div className="spacer" />
        {this.renderBreakdown()}
        {this.renderFooter()}
        {selected && (
          <div className="badge">
            <div className="icon-check" />
          </div>
        )}
      </div>
    );
  }
}
