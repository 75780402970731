import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { dayjs } from 'common/util/dayjsUtils';

const NBSP = ' ';
const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
const EIGHT_DAYS = 8 * ONE_DAY;

var INTERVALS = [
  {
    lessThan: ONE_MINUTE,
    render(ms, live) {
      if (!live) {
        return 'Just now';
      }

      var seconds = Number.parseInt(ms / ONE_SECOND, 10);
      if (seconds === 0) {
        return 'Just now';
      }

      var amount = seconds === 1 ? 'second' : 'seconds';
      return seconds + NBSP + amount + NBSP + 'ago';
    },
  },
  {
    lessThan: ONE_HOUR,
    render(ms) {
      var minutes = Number.parseInt(ms / ONE_MINUTE, 10);
      var quantity = minutes === 1 ? 'A' : minutes;
      var amount = minutes === 1 ? 'minute' : 'minutes';
      return quantity + NBSP + amount + NBSP + 'ago';
    },
  },
  {
    lessThan: ONE_DAY,
    render(ms) {
      var hours = Number.parseInt(ms / ONE_HOUR, 10);
      var quantity = hours === 1 ? 'An' : hours;
      var amount = hours === 1 ? 'hour' : 'hours';
      return quantity + NBSP + amount + NBSP + 'ago';
    },
  },
  {
    lessThan: ONE_WEEK,
    render(ms) {
      var days = Number.parseInt(ms / ONE_DAY, 10);
      var quantity = days === 1 ? 'A' : days;
      var amount = days === 1 ? 'day' : 'days';
      return quantity + NBSP + amount + NBSP + 'ago';
    },
  },
  {
    lessThan: EIGHT_DAYS,
    render(ms) {
      return 'A' + NBSP + 'week' + NBSP + 'ago';
    },
  },
];

export default class Timestamp extends Component {
  static propTypes = {
    className: PropTypes.string,
    live: PropTypes.bool,
    format: PropTypes.string,
    showRelative: PropTypes.bool,
    textOptions: PropTypes.shape({
      lowerCaseRelative: PropTypes.bool,
    }),
    timestamp: PropTypes.any,
  };

  static defaultProps = {
    live: false,
    showRelative: true,
    format: 'MMMM D, YYYY',
    textOptions: {
      lowerCaseRelative: false,
    },
  };

  componentDidMount() {
    if (this.props.live) {
      this._liveInterval = setInterval(() => {
        this.forceUpdate();
      }, ONE_SECOND);
    }
  }

  componentWillUnmount() {
    if (this.props.live) {
      clearInterval(this._liveInterval);
    }
  }

  renderAbsolute() {
    var timestamp = new Date(this.props.timestamp);
    return dayjs(timestamp).format(this.props.format).replace(/' '/g, NBSP);
  }

  renderRelative() {
    const { live } = this.props;

    var now = new Date();
    var timestamp = new Date(this.props.timestamp);
    var ms = now - timestamp;
    const renderText = (text) =>
      this.props.textOptions?.lowerCaseRelative ? text.toLowerCase() : text;

    if (ms < 0) {
      return renderText('Just now');
    } else if (ms > EIGHT_DAYS) {
      return this.renderAbsolute();
    }

    for (var i = 0; i < INTERVALS.length; i++) {
      var interval = INTERVALS[i];
      if (ms < interval.lessThan) {
        return renderText(interval.render(ms, live));
      }
    }

    // should never make it here but just in case...
    return this.renderAbsolute();
  }

  render() {
    return (
      <div className={classnames('timestamp', this.props.className)}>
        {this.props.showRelative ? this.renderRelative() : this.renderAbsolute()}
      </div>
    );
  }
}
