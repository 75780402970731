import React from 'react';

import classnames from 'classnames';
import { CheckCircle, ClipboardList, DollarSign, MessageSquare, Triangle } from 'lucide-react';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import Truncate from 'common/common/Truncate';
import plaintext from 'common/markdown/plaintext';
import Timestamp from 'common/Timestamp';
import truncateString from 'common/truncateString';
import Text, { H3, P } from 'common/ui/Text';
import UserLockup from 'common/user/UserLockup';
import abbreviateNumber from 'common/util/abbreviateNumber';

import type { Board } from 'common/api/endpoints/boards';
import type { StrippedUser } from 'common/api/endpoints/users';

import 'css/components/subdomain/admin/_AdminQueuePost.scss';

type Props = {
  className?: string;
  title: string;
  details: string | null;
  author?: StrippedUser;
  created?: string;
  board?: Board;
  meta?: {
    score: number;
    commentCount: number;
    monthlySpend?: number;
  };
  workaround?: string | null;
  truncate?: boolean;
};

const AdminQueuePost = ({
  title,
  className,
  details,
  author,
  created,
  board,
  meta,
  workaround,
  truncate = true,
}: Props) => {
  return (
    <section className={classnames('adminQueuePost', className)}>
      <header className="postHeader">
        <H3 variant="bodyMd" fontWeight="medium" className="postTitle">
          {title}
        </H3>
        {workaround ? (
          <Tooltip value="Autopilot detected this feature already&nbsp;exists" position="top">
            <Pill pillStyle={DefaultPillStyles.success}>
              <div className="workaroundPill">
                <CheckCircle size={14} strokeWidth={2} />
                <P>Existing feature</P>
              </div>
            </Pill>
          </Tooltip>
        ) : null}
      </header>
      <Text element="div" variant="bodyMd" className="postDetails">
        {truncate ? (
          <Truncate numberOfLines={2}>{plaintext(details)}</Truncate>
        ) : (
          <>{plaintext(details)}</>
        )}
      </Text>
      <footer className="postFooter">
        <div className="left">
          {author && (
            <>
              <UserLockup containerClassName="user" truncate={true} user={author} />
              <div className="divider" />
            </>
          )}
          <Timestamp format="MMM D, YYYY" className="timestamp" timestamp={created} />
          {board && (
            <>
              <div className="divider" />
              <div className="postBoard">
                <ClipboardList className="icon" />
                <P variant="bodySm" className="boardName">
                  {truncateString(board.name, 25)}
                </P>
              </div>
            </>
          )}
        </div>
        {meta && (
          <div className="right">
            <Text element="div" variant="bodySm" className="meta">
              <Triangle className="icon" />
              {meta.score}
              <div className="divider" />
              <MessageSquare className="icon" />
              {meta.commentCount}
              {meta.monthlySpend ? (
                <>
                  <div className="divider" />
                  <DollarSign className="icon" />
                  {abbreviateNumber(meta.monthlySpend)}
                </>
              ) : null}
            </Text>
          </div>
        )}
      </footer>
    </section>
  );
};

export default AdminQueuePost;
