import React, { useContext } from 'react';

import { Plus } from 'lucide-react';

import { RouterContext } from 'common/containers/RouterContainer';
import { type Option } from 'common/ui/common/select/SelectCommon';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

interface Props {
  onNewRoadmap: () => void;
  roadmap: Roadmap | null;
  roadmaps: Roadmap[];
}

const AdminRoadmapSelector = ({ onNewRoadmap, roadmap, roadmaps }: Props) => {
  const router = useContext(RouterContext);
  const selectedRoadmap = roadmap
    ? {
        value: roadmap._id,
        label: roadmap.name,
      }
    : undefined;

  const options = roadmaps.map((roadmap) => ({
    value: roadmap._id,
    label: roadmap.name,
  }));

  const onChange = (option?: Option) => {
    if (!option) {
      return;
    }
    const roadmap = roadmaps.find((roadmap) => roadmap._id === option.value);
    if (roadmap) {
      router.push(`/admin/roadmap/${roadmap.urlName}`);
    }
  };

  return (
    <div>
      <SingleSelectWithSearch
        options={options}
        placeholder="Select roadmap"
        labelPlacement="left"
        optionsPlacement="bottom"
        onChange={onChange}
        value={selectedRoadmap}
        optionsMaxWidth="400px"
        cta={{
          option: { label: 'Create new roadmap', value: 'newRoadmap', prefixIcon: Plus },
          onAction: onNewRoadmap,
        }}
      />
    </div>
  );
};

export default AdminRoadmapSelector;
