import React, { Component } from 'react';

import { Helmet as ReactHelmet } from 'react-helmet-async';

import publicConfig from 'common/core/publicConfig';

const FBAppID = __DEV__ ? '1606035092942076' : '1606018679610384';
const GoogleSiteVerificationID = 'hutYg6OBDqx9fLFmeEqY4o0gjWPw2rkFt3-Sp2wbMQc';

export default class Helmet extends Component {
  render() {
    const { base, link, meta, script, title } = this.props;

    const supportsIndexing = publicConfig('supportsSearchEngineIndexing');

    const props = {
      ...(base && { base }),
      link: link || [],
      meta: meta || [],
      script: script || [],
      ...(title && { title }),
    };

    if (!supportsIndexing) {
      props.meta.push({ name: 'robots', content: 'noindex,nofollow' });
    }

    props.meta.push({
      property: 'fb:app_id',
      content: FBAppID,
    });
    props.meta.push({
      name: 'google-site-verification',
      content: GoogleSiteVerificationID,
    });

    return <ReactHelmet {...props}>{this.props.children}</ReactHelmet>;
  }
}
