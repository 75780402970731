import React, { Component } from 'react';

import AJAX from 'common/AJAX';

import { loadViewer } from '../actions/viewer';
import asyncConnect from '../core/asyncConnect';

export const ViewerContext = React.createContext();

function createAsyncConnect(options) {
  return {
    promise: ({ store: { dispatch } }) => {
      return dispatch(loadViewer(options));
    },
  };
}

class ViewerContainer extends Component {
  static asyncConnect = createAsyncConnect();

  static createAsyncConnect = createAsyncConnect;

  componentDidMount() {
    const { viewer } = this.props;
    if (!viewer?.csrfToken) {
      return;
    }

    AJAX.injectCookies({
      csrfToken: viewer.csrfToken,
    });
  }

  componentDidUpdate(prevProps) {
    const { viewer } = this.props;
    if (viewer !== prevProps.viewer && viewer?.csrfToken) {
      AJAX.injectCookies({
        csrfToken: viewer.csrfToken,
      });
    }
  }

  render() {
    return (
      <ViewerContext.Provider value={this.props.viewer || { loading: true }}>
        {this.props.children}
      </ViewerContext.Provider>
    );
  }
}

export default asyncConnect([createAsyncConnect()], (state) => ({ viewer: state.viewer }))(
  ViewerContainer
);
