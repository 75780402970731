import React from 'react';

import { PenBox, Trash } from 'lucide-react';

import 'css/components/subdomain/admin/AdminMemberSettings/_AdminMemberTeamSettings.scss';

import IconButtonV2 from 'common/ui/IconButtonV2';
import Facepile from 'common/user/Facepile';

import type { CompanyTeam } from 'common/api/endpoints/companies';

const maxFaces = 5;

interface Props extends CompanyTeam {
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const Team = ({ name, members, onEditClick, onDeleteClick }: Props) => {
  const overflowCount = members.length - maxFaces;

  return (
    <div className="team">
      <span className="teamName">{name}</span>
      <div className="avatars">
        <Facepile className="" users={members} maxFaces={maxFaces} />
        {overflowCount > 0 && <span className="overflow">+{overflowCount} more</span>}
      </div>
      <div className="buttons">
        <IconButtonV2
          aria-label="edit team"
          icon={PenBox}
          onClick={onEditClick}
          size="medium"
          variant="plain"
        />
        <span className="verticalDivider" />
        <IconButtonV2
          aria-label="delete team"
          color="error"
          icon={Trash}
          onClick={onDeleteClick}
          size="medium"
          variant="plain"
        />
      </div>
    </div>
  );
};

export default Team;
