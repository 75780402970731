import createCustomError from 'common/util/createCustomError';
import isNil from 'common/util/isNil';
import validateInput from 'common/validateInput';

import { isFilterBoardURLName, isFilterCustomField, isFilterPostContent } from '../filterUtils';
import {
  AlphanumericConditions,
  DropdownConditions,
  InputTypes,
  MultiselectConditions,
  NumericConditions,
  getConditionInputType,
} from '../postFieldFilterUtils';

import type { Filter } from '../constants';

export const ValidateAutomationFilterError = createCustomError('validateAutomation');
const validateFilter = (filter: Filter) => {
  if (isFilterBoardURLName(filter)) {
    return validateInput.urlName(filter.value);
  } else if (isFilterPostContent(filter)) {
    return (
      validateInput.oneOf(['containsAll', 'containsAny'])(filter.condition) &&
      validateInput.arrayOf(
        (value: string) => typeof value === 'string' && value.length >= 3,
        1,
        5
      )(filter.value)
    );
  } else if (isFilterCustomField(filter)) {
    // Validate the values against the conditions
    const inputType = getConditionInputType(filter.condition);

    // Don't validate dropdowns, since they are validated on the backend
    if (inputType === InputTypes.number) {
      return validateInput.integer(filter.value?.value) && filter.value?.value.length >= 1;
    } else if (inputType === InputTypes.text) {
      return validateInput.string(filter.value?.value) && filter.value?.value.length >= 3;
    } else if (inputType === null) {
      return filter.value?.value === '' || isNil(filter.value?.value);
    }

    return (
      validateInput.oneOf(
        [
          Object.values(AlphanumericConditions),
          Object.values(DropdownConditions),
          Object.values(MultiselectConditions),
          Object.values(NumericConditions),
        ].flat()
      )(filter.condition) && validateInput.string(filter.value?.fieldID)
    );
  } else {
    throw new ValidateAutomationFilterError({
      message: `Invalid filter: ${(filter as Filter).resource}`,
      filter,
    });
  }
};

export default validateFilter;
