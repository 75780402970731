import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import Dropdown from 'common/Dropdown';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Spinner from 'common/Spinner';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminCreateGithubIssueModal.scss';

class AdminCreateGithubIssueModal extends Component {
  static propTypes = {
    board: PropTypes.object,
    closeModal: PropTypes.func,
    company: PropTypes.object,
    onIssueCreated: PropTypes.func,
    post: PropTypes.object,
  };

  state = {
    repos: null,
    creating: false,
    error: null,
    selectedRepo: null,
    reposError: null,
  };

  constructor(props, context) {
    super(props, context);

    this.descriptionRef = React.createRef();
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    this.fetchRepos();
  }

  fetchRepos = async () => {
    const response = await AJAX.post('/api/github/getRepos');
    this.setState({
      reposError: null,
    });
    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse.repos,
      errors: {
        'not authorized': 'You are not authorized to perform this action',
        'github not installed':
          'You have not installed Github yet. Please install Github from integrations page to create issues',
      },
    });

    if (error) {
      this.setState({
        reposError: error.message,
        repos: [],
      });
      return;
    }

    const { repos } = parsedResponse;
    this.setState({ repos, selectedRepo: repos[0] });
  };

  onCreateIssue = async () => {
    const { closeModal, onIssueCreated, post } = this.props;
    const { selectedRepo } = this.state;

    this.setState({ creating: true, error: null });

    const response = await AJAX.post('/api/github/createAndLinkIssue', {
      issueTitle: this.titleRef.current.getValue(),
      issueDescription: this.descriptionRef.current.getValue(),
      postID: post._id,
      repoName: selectedRepo.name,
      ownerName: selectedRepo.owner.name,
    });

    const {
      error,
      parsedResponse: { issue },
    } = parseAPIResponse(response, {
      isSuccessful: ({ issue }) => issue?.id,
      errors: {
        'failed to create issue':
          "The issue could not be created. This might happen if issues are disabled in the repository.\nIf this isn't accurate and the error persists, please contact our team for\xa0support",
      },
    });

    if (error) {
      this.setState({
        creating: false,
        error: error.message,
      });
      return;
    }

    this.setState({ creating: false, error: null }, () => {
      // open created issue in a new tab.
      const issueWindow = window.open(issue.url, '_blank');
      if (issueWindow) {
        // If popups are blocked by the browser, `issueWindow` will be `null`
        issueWindow.focus();
      }

      // close modal and trigger callback.
      closeModal();
      onIssueCreated();
    });
  };

  onRepoChange = (repoID) => {
    const { repos } = this.state;
    const selectedRepo = repos.find((repo) => repo.id === repoID);
    this.setState({ selectedRepo });
  };

  renderContents() {
    const { repos, reposError } = this.state;
    if (!repos) {
      return <Spinner />;
    } else if (repos.length === 0) {
      return (
        <div className="error">
          {reposError || (
            <>
              It appears you don't have any repositories enabled. If this isn't accurate, please
              contact our team for&nbsp;support.
            </>
          )}
        </div>
      );
    }

    const {
      board,
      company: { subdomain },
      post,
    } = this.props;
    const { selectedRepo } = this.state;

    const postURL = `https://${subdomain}.canny.io/admin/board/${board.urlName}/p/${post.urlName}`;
    const description = post.details ? `${post.details}\n\n${postURL}` : postURL;

    return (
      <div className="createIssueForm">
        <div className="dropdownsContainer">
          <div className="dropdownOuterContainer">
            <UppercaseHeader>Repository</UppercaseHeader>
            <Dropdown
              defaultSelectedName={selectedRepo.id}
              options={repos.map((repo) => ({
                name: repo.id,
                render: <div>{repo.name}</div>,
              }))}
              onChange={this.onRepoChange}
            />
          </div>
        </div>
        <TextInput defaultValue={post.title} inset="Title" ref={this.titleRef} />
        <AutoResizeTextarea
          defaultValue={description}
          inset="Description"
          maxRows={8}
          minRows={3}
          ref={this.descriptionRef}
        />
        {this.renderError()}
        <Button
          buttonType="cannyButton"
          disabled={!selectedRepo}
          loading={this.state.creating}
          onTap={this.onCreateIssue}
          value="Create & Link Issue"
        />
      </div>
    );
  }

  renderError = () => {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  };

  render() {
    return (
      <div className="adminCreateGithubIssueModal">
        <div className="heading">Create a new Github issue</div>
        {this.renderContents()}
      </div>
    );
  }
}

export default withContexts(
  { closeModal: CloseModalContext, company: CompanyContext },
  { forwardRef: true }
)(AdminCreateGithubIssueModal);
