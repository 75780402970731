import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import HubspotErrorMessages from 'common/integrations/hubspot/HubspotErrorMessages';
import Link from 'common/Link';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import AdminHubspotSync from './AdminHubspotSync';

import 'css/components/subdomain/admin/AdminHubspotSettings/_AdminHubspotSettings.scss';

const AuthURL = '/api/hubspot/authorize';
const LoadingStates = {
  authorizing: 'authorizing',
  hubspot: 'hubspot',
  installing: 'installing',
  uninstalling: 'uninstalling',
};
const OneSecond = 1000;

class AdminHubspotSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      hubspot: PropTypes.shape({
        _id: PropTypes.string,
      }),
      subdomain: PropTypes.string,
    }),
  };

  state = {
    error: null,
    loading: null,
  };

  #authWindowInterval = null;

  componentWillUnmount() {
    clearInterval(this.#authWindowInterval);
  }

  onAuthorize = async () => {
    this.setState({ loading: LoadingStates.authorizing });
    const authWindow = window.open(AuthURL, '_blank');

    // Track whether the authorization window was closed or not.
    this.#authWindowInterval = setInterval(async () => {
      const isAuthWindowClosed = !authWindow || authWindow.closed;
      if (isAuthWindowClosed) {
        clearInterval(this.#authWindowInterval);
        this.setState({ loading: null });
        return;
      }
    }, OneSecond);
  };

  onInstall = async () => {
    const { reloadCompany } = this.props;

    this.setState({
      error: null,
      loading: LoadingStates.installing,
    });
    const response = await AJAX.post('/api/hubspot/install');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: HubspotErrorMessages,
    });

    if (error) {
      this.setState({
        error: error.message,
        loading: null,
      });
      return;
    }

    this.setState({ loading: null }, reloadCompany);
    this.onAuthorize();
  };

  onUninstall = async () => {
    const { reloadCompany } = this.props;

    this.setState({
      error: null,
      loading: LoadingStates.uninstalling,
    });
    const response = await AJAX.post('/api/hubspot/uninstall');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: HubspotErrorMessages,
    });

    if (error) {
      this.setState({
        error: error.message,
        loading: null,
      });
      return;
    }

    await reloadCompany();
    this.setState({ loading: null });
  };

  renderAuthorizeButton() {
    return <Button onTap={this.onAuthorize} value="Authorize HubSpot" />;
  }

  renderUninstallSection() {
    return (
      <div className="status">
        <div className="text">Your HubSpot integration is installed.</div>
        <Tappable onTap={this.onUninstall}>
          <div className="disconnect">Uninstall</div>
        </Tappable>
      </div>
    );
  }

  renderContents() {
    const { company, showIntercom } = this.props;
    const { loading } = this.state;
    const { authorized, installed, lostAccess } = company.hubspot ?? {};

    if (!company.integrations?.hubspot) {
      return (
        <div className="installation">
          {installed && this.renderUninstallSection()}
          <AdminFeatureBlock
            benefit="Available on select Canny Business plans—contact us for details."
            feature="HubSpot integration"
            showBillingLink={false}
          />
        </div>
      );
    } else if (loading) {
      return this.renderLoading(loading);
    } else if (!installed) {
      return this.renderInstallButton();
    } else if (!authorized) {
      return this.renderAuthorizeButton();
    } else if (lostAccess) {
      return (
        <div className="installation">
          <div className="status">
            <p className="text">
              There was a problem with your credentials when trying to synchronize data from
              HubSpot. Please, re-authorize the integration. If the problem persists, please{' '}
              {
                <Link className="link" fakeLink={true} onTap={() => showIntercom()} to="/">
                  reach out for support
                </Link>
              }
              .
            </p>
          </div>
          {this.renderAuthorizeButton()}
        </div>
      );
    }

    return (
      <div className="installation">
        {this.renderUninstallSection()}
        {this.renderDataSync()}
      </div>
    );
  }

  renderDataSync() {
    const {
      company: { hubspot },
    } = this.props;
    if (!hubspot) {
      return;
    }

    return (
      <div className="integration">
        <div className="heading">HubSpot Data Sync</div>
        <AdminHubspotSync />
      </div>
    );
  }

  renderInstallButton() {
    return <Button onTap={this.onInstall} value="Install HubSpot" />;
  }

  renderLoading(loadingState) {
    const message =
      {
        [LoadingStates.authorizing]: 'Authorizing HubSpot integration',
        [LoadingStates.hubspot]: 'Loading data from HubSpot',
        [LoadingStates.installing]: 'Installing HubSpot integration',
        [LoadingStates.uninstalling]: 'Uninstalling HubSpot integration',
      }[loadingState] ?? 'Loading HubSpot integration';

    return (
      <div className="spinnerContainer">
        <div>
          <Spinner />
        </div>
        <div className="installing">{message}</div>
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    return (
      <div className="adminHubspotSettings">
        <Helmet title="HubSpot Integration | Canny" />
        <div className="settingsHeading">HubSpot Integration</div>
        <div className="content">
          <div className="text">
            Canny's HubSpot integration lets you keep track of feedback from sales contacts without
            leaving&nbsp;HubSpot.
          </div>
          {this.renderContents()}
          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.hubspot), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      company: CompanyContext,
      showIntercom: ShowIntercomContext,
    },
    { forwardRef: true }
  )
)(AdminHubspotSettings);
