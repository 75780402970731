import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Dropdown from 'common/Dropdown';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import CheckboxInput from 'common/inputs/CheckboxInput';
import TextInput from 'common/inputs/TextInput';
import UppercaseHeader from 'common/UppercaseHeader';

import { Fields } from './Constants';

const AdminCreateAzureDevopsWorkItemField = ({ errored = false, field, onCustomFieldChange }) => {
  if (field.isIdentity) {
    return (
      <TextInput
        className={classnames({ erroredField: errored })}
        defaultValue={field.defaultValue}
        inset={field.name}
        onChange={(e) => onCustomFieldChange(e.target.value, field)}
        placeholder="user@yourcompany.com"
      />
    );
  } else if (field.type === Fields.string.type && field.allowedValues?.length) {
    return (
      <div className="dropdownOuterContainer">
        <UppercaseHeader>{field.name}</UppercaseHeader>
        <Dropdown
          className={classnames({ erroredField: errored })}
          onChange={(value) => onCustomFieldChange(value, field)}
          options={field.allowedValues.map((value) => ({ name: value, render: value }))}
          defaultSelectedName={field.defaultValue}
          placeholder={field.defaultValue ? null : 'Select an option'}
        />
      </div>
    );
  } else if (field.type === Fields.integer.type && field.allowedValues?.length) {
    return (
      <div className="dropdownOuterContainer">
        <UppercaseHeader>{field.name}</UppercaseHeader>
        <Dropdown
          className={classnames({ erroredField: errored })}
          onChange={(value) => onCustomFieldChange(value, field)}
          options={field.allowedValues.map((value) => ({ name: value, render: value }))}
          defaultSelectedName={field.defaultValue}
          placeholder={field.defaultValue ? null : 'Select an option'}
        />
      </div>
    );
  } else if (field.type === Fields.boolean.type) {
    return (
      <CheckboxInput
        defaultChecked={field.defaultValue === '1'}
        label={field.name}
        onChange={(value) => onCustomFieldChange(value, field)}
      />
    );
  } else if (field.type === Fields.string.type) {
    return (
      <TextInput
        className={classnames({ erroredField: errored })}
        defaultValue={field.defaultValue}
        inset={field.name}
        onChange={(e) => onCustomFieldChange(e.target.value, field)}
      />
    );
  } else if (field.type === Fields.plainText.type) {
    return (
      <AutoResizeTextarea
        className={classnames({ erroredField: errored })}
        defaultValue={field.defaultValue}
        maxRows={10}
        minRows={3}
        onChange={(e) => onCustomFieldChange(e.target.value, field)}
      />
    );
  } else if (field.type === Fields.integer.type) {
    return (
      <TextInput
        className={classnames({ erroredField: errored })}
        defaultValue={field.defaultValue}
        inset={field.name}
        onChange={(e) => onCustomFieldChange(e.target.value, field)}
        placeholder="1"
      />
    );
  } else if (field.type === Fields.double.type) {
    return (
      <TextInput
        className={classnames({ erroredField: errored })}
        defaultValue={field.defaultValue}
        inset={field.name}
        onChange={(e) => onCustomFieldChange(e.target.value, field)}
        placeholder="1.0"
      />
    );
  } else if (field.type === Fields.dateTime.type) {
    return (
      <TextInput
        className={classnames({ erroredField: errored })}
        defaultValue={field.defaultValue}
        inset={field.name}
        onChange={(e) => onCustomFieldChange(e.target.value, field)}
        placeholder="mm/dd/yyyy"
      />
    );
  } else {
    return (
      <div className="error" role="alert">
        There was an error loading the field {field.name}. Fields of type {field.type} are not yet
        supported. Please, reach out to support.
      </div>
    );
  }
};

AdminCreateAzureDevopsWorkItemField.propTypes = {
  errored: PropTypes.bool,
  field: PropTypes.shape({
    allowedValues: PropTypes.arrayOf(PropTypes.string),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    isIdentity: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(Fields)),
  }),
  onCustomFieldChange: PropTypes.func.isRequired,
};

export default AdminCreateAzureDevopsWorkItemField;
