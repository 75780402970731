import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { Plus } from 'lucide-react';

import AccountModal, { FormStates } from 'common/AccountModal';
import {
  type FailedFile,
  ImageMimeTypes,
  LoadStatus,
  type LocalFile,
  type UploadedFile,
  type UploadingFile,
} from 'common/constants/files';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import FileRenderer from 'common/file/FileRenderer';
import { createFiles, createImageFiles } from 'common/file/utils/createFiles';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import uploadFile from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import translateString from 'common/i18n/translateString';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import TextInput from 'common/inputs/TextInput';
import UploadFileButton from 'common/inputs/UploadFileButton';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';

import FieldError from './FieldError';
import PostFieldDropdowns from './PostFieldDropdowns';
import PostFieldInputs from './PostFieldInputs';
import { type PostForm, getFormErrors } from './utils';

import type { Board } from 'common/api/endpoints/boards';
import type { HexColor } from 'common/colors/utils';

import 'css/components/post/_CreatePostFormV2.scss';

const getTintedStyle = (tint: HexColor | null) => {
  if (!tint) {
    return null;
  }

  return { style: { background: tint, borderColor: tint } };
};

type Props = {
  board: Board;
  className?: string;
  defaultVisibility?: 'open' | 'closed';
  loading: boolean;
  tint?: HexColor;
  defaultForm?: Partial<PostForm>;
  onCreate: (form: PostForm) => Promise<void>;
  onUpdate?: (form: Partial<PostForm>) => Promise<void>;
  onCancel?: () => Promise<void>;
};

const CreatePostForm = ({
  board,
  className,
  defaultForm = {},
  defaultVisibility = 'closed',
  loading,
  tint: defaultTint,
  onCreate,
  onUpdate,
  onCancel,
}: Props) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [form, setForm] = useState<PostForm>({
    boardID: board._id,
    customFieldValuesMap: {},
    title: '',
    categoryID: null,
    details: '',
    files: '[]',
    fileURLs: '[]',
    imageURLs: '[]',
    ...defaultForm,
  });
  const isOpenByDefault =
    defaultVisibility === 'open' || !!(defaultForm.title || defaultForm.details);
  const [formFiles, imageURLs] = [JSON.parse(form.files), JSON.parse(form.imageURLs)];
  const [open, setOpen] = useState<boolean>(isOpenByDefault);
  const [wasClosed, setWasClosed] = useState<boolean>(false);
  const [files, setFiles] = useState<LocalFile[]>([
    ...createFiles(formFiles),
    ...createImageFiles(imageURLs),
  ]);
  const filesUploading = files.some((file) => file.uploadStatus !== LoadStatus.loaded);

  const showToast = useContext(ShowToastContext);
  const viewer = useContext(ViewerContext);
  const tint = useContext(TintColorContext);
  const company = useContext(CompanyContext);
  const location = useContext(LocationContext);
  const openModal = useContext(OpenModalContext);

  const acceptedMimeTypes = getAcceptedMimeTypes(company);

  if (!open) {
    return (
      <div onClick={() => setOpen(true)} className={classnames('createPostFormV2', className)}>
        <P variant="bodyLg" fontWeight="medium" className="placeholder">
          {translateString(board.strings, 'formCTA')}
        </P>
        <IconButtonV2
          className="tintButton"
          size="medium"
          variant="contained"
          icon={Plus}
          aria-label="Create post"
          {...getTintedStyle(defaultTint || tint)}
        />
      </div>
    );
  }

  const errors = dirty ? getFormErrors(form, board) : {};

  const changeFormFiles = (files: LocalFile[]) => {
    const validImageURLs = files
      .filter((file) => (file.contentType ? ImageMimeTypes.includes(file.contentType) : false))
      .map((file) => file.url)
      .filter(Boolean);
    const validFiles = files
      .filter((file) => (file.contentType ? !ImageMimeTypes.includes(file.contentType) : false))
      .filter((file) => !!file.url);
    const validFileURLs = validFiles.map((file) => file.url);
    const existingFileURLs = formFiles.map((file: { name: string; url: string }) => file.url);

    const imageURLsChanged =
      [...validImageURLs].sort().join(',') !== [...imageURLs].sort().join(',');
    const fileURLsChanged =
      [...validFileURLs].sort().join(',') !== [...existingFileURLs].sort().join(',');

    if (!imageURLsChanged && !fileURLsChanged) {
      return;
    }

    onFormChange({
      files: JSON.stringify(validFiles.map((file) => ({ name: file.name, url: file.url }))),
      fileURLs: JSON.stringify(validFileURLs),
      imageURLs: JSON.stringify(validImageURLs),
    });
  };

  const requireAuth = (callback?: () => void) => {
    const { authRedirectEnabled, authRedirectURL } = company;

    const isLoggedOut = !viewer || viewer.loggedOut;
    if (isLoggedOut && authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location);
      window.open(redirectURL, '_top');
      return true;
    } else if (isLoggedOut) {
      openModal(AccountModal, {
        formState: FormStates.signup,
        onSuccess: callback,
      });
      return true;
    }

    return false;
  };

  const onFileStart = () => {
    if (requireAuth()) {
      return false;
    }

    return true;
  };

  const onFileError = (file: FailedFile, error: string) => {
    setFiles((files) => {
      const newFiles = files.filter((f) => f.uniqueID !== file.uniqueID);
      changeFormFiles(newFiles);

      return newFiles;
    });

    showToast(error, ToastTypes.error);
  };

  const onFileUploading = (file: UploadingFile) => {
    setFiles((files) => {
      const newFiles = [...files, file];
      changeFormFiles(newFiles);

      return newFiles;
    });
  };

  const onFileUploaded = (file: UploadedFile) => {
    setFiles((files) => {
      const newFiles = files.map((f) => (f.uniqueID === file.uniqueID ? file : f));
      changeFormFiles(newFiles);

      return newFiles;
    });
  };

  const onFileRemoved = (file: LocalFile) => {
    setFiles((files) => {
      const newFiles = files.filter((f) => f.uniqueID !== file.uniqueID);
      changeFormFiles(newFiles);

      return newFiles;
    });
  };

  const onFormChange = (edits: Partial<PostForm>) => {
    const updatedForm = { ...form, ...edits };
    setForm(updatedForm);
    onUpdate?.({
      ...updatedForm,
      categoryID: updatedForm.categoryID || null,
    });
  };

  const onFileSubmitted = async (file: File) => {
    if (requireAuth()) {
      return;
    }

    await uploadFile({
      file,
      viewer,
      onFileError,
      onFileUploading,
      onFileUploaded,
    });
  };

  const onClose = async () => {
    setOpen(false);
    setDirty(false);
    setWasClosed(true);
    onCancel?.();
  };

  const onSubmit = async () => {
    if (loading) {
      return;
    }

    setDirty(true);

    // submit if there are no errors
    const errors = getFormErrors(form, board);
    if (Object.keys(errors).length) {
      return;
    }

    const createPost = async (form: PostForm) => {
      try {
        await onCreate({
          ...form,
          categoryID: form.categoryID || null,
        });
      } catch (error) {
        if (
          error &&
          typeof error === 'object' &&
          'message' in error &&
          typeof error.message === 'string'
        ) {
          showToast(error.message, ToastTypes.error);
        }
      }
    };

    if (requireAuth(() => createPost(form))) {
      return;
    }

    await createPost(form);
  };

  return (
    <Form
      acceptedFileTypes={acceptedMimeTypes}
      addEventsToDocument={false}
      allowFileUpload={true}
      className={classnames('createPostFormV2', 'open', className, {
        hasAnimation: !isOpenByDefault || wasClosed,
      })}
      onFile={onFileSubmitted}
      onSubmit={onSubmit}
      disableSubmit={loading || filesUploading}
      submitOnEnter={false}>
      <div className="createPostFormSection postForm first">
        <TextInput
          aria-label={translateString(board.strings, 'titleField')}
          className="title"
          defaultValue={form.title}
          placeholder={translateString(board.strings, 'titlePlaceholder')}
          autoFocus={true}
          onChange={(e) => onFormChange({ title: e.currentTarget.value })}
        />
        <FieldError error={errors.title} />
        <label htmlFor="details" className="descriptionLabel">
          <P variant="bodyMd" fontWeight="medium">
            Description
          </P>
        </label>
        <AutoResizeTextarea
          aria-label={translateString(board.strings, 'detailsField')}
          id="details"
          className="details"
          defaultValue={form.details}
          placeholder={translateString(board.strings, 'detailsPlaceholder')}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onFormChange({ details: e.currentTarget.value })
          }
        />
        <FieldError error={errors.details} />
        <PostFieldDropdowns board={board} form={form} errors={errors} onFormChange={onFormChange} />
      </div>
      <PostFieldInputs board={board} form={form} errors={errors} onFormChange={onFormChange} />
      <footer className="footer">
        <FileRenderer
          className="postFormFileRenderer"
          allowRemove={true}
          files={files}
          onFileRemoved={onFileRemoved}
        />
        <div className="ctaSection">
          <UploadFileButton
            acceptedMimeTypes={acceptedMimeTypes}
            defaultStyle={false}
            onFileError={onFileError}
            onFileStart={onFileStart}
            onFileUploading={onFileUploading}
            onFileUploaded={onFileUploaded}
          />
          <ButtonV2 onClick={onClose} size="medium" variant="outlined">
            Cancel
          </ButtonV2>
          <ButtonV2
            className="tintButton"
            disabled={filesUploading}
            loading={loading}
            size="medium"
            type="submit"
            {...getTintedStyle(defaultTint || tint)}>
            {translateString(board.strings, 'createCTA')}
          </ButtonV2>
        </div>
      </footer>
    </Form>
  );
};

export default CreatePostForm;
