import React, { useContext, useState } from 'react';

import { CornerDownRight, type LucideIcon } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import TextToggle from 'common/common/TextToggle';
import {
  ReviewPlatformLabels,
  ReviewPlatformNames,
  SyncIntegrationNames,
} from 'common/constants/autopilotIntegrations';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import { CannyAnnouncement } from 'common/modals/CannyAnnouncementModal';
import withAccessControl from 'common/routing/withAccessControl';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import SwitchV2 from 'common/ui/SwitchV2';
import { H1, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import AdminAutopilotIntegrationItem from './AdminAutopilotIntegrationItem';
import AdminAutopilotReviewIntegrationItem from './AdminAutopilotReviewIntegrationItem';

import AppStoreLogo from 'img/app-store-logo-small.svg';
import AutopilotAnnouncement from 'img/autopilot.svg';
import CapterraLogo from 'img/capterra-logo-small.svg';
import G2Logo from 'img/g2-logo-small.svg';
import GongLogo from 'img/gong-logo.png';
import GooglePlayLogo from 'img/google-play-logo-small.svg';
import HelpscoutLogo from 'img/helpscout-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import ProductHuntLogo from 'img/product-hunt-logo-small.svg';
import SalesforceLogo from 'img/salesforce-text-logo-small.svg';
import ShopifyLogo from 'img/shopify-logo-small.svg';
import TrustpilotLogo from 'img/trustpilot-logo-small.svg';
import TrustRadiusLogo from 'img/trustradius-logo-small.svg';
import WordpressLogo from 'img/wordpress-logo-small.svg';
import ZendeskLogo from 'img/zendesk-logo-small.png';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminFeedbackDiscoverySettings.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

export type IntegrationItem = {
  credits: number;
  label: string;
  logo: string | LucideIcon;
  name: SyncIntegrationNames;
  needsCountry?: boolean;
  platform?: ReviewPlatformNames;
  type: 'call' | 'conversation' | 'review' | 'item';
};

export type IntegrationItemWithComponent = IntegrationItem & {
  Component: React.FC<IntegrationItem>;
};

const AdminFeedbackDiscoverySettings = ({ reloadCompany }: Props) => {
  const boards = useContext(BoardsContext);
  const company = useContext<Company>(CompanyContext);
  const router = useContext(RouterContext);
  const showToast = useContext(ShowToastContext);

  const [enabled, setEnabled] = useState(!!company.queueAutomation?.enabled);
  const [boardID, setBoardID] = useState<string | null>(company.queueAutomation?.boardID ?? null);
  const [loading, setLoading] = useState<boolean>(false);

  const Integrations: Partial<Record<SyncIntegrationNames, IntegrationItemWithComponent>> = {
    [SyncIntegrationNames.intercom]: {
      Component: AdminAutopilotIntegrationItem,
      credits: 1,
      label: 'Intercom',
      logo: IntercomLogo,
      name: SyncIntegrationNames.intercom,
      type: 'conversation',
    },
    [SyncIntegrationNames.helpscout]: {
      Component: AdminAutopilotIntegrationItem,
      credits: 1,
      label: 'Help Scout',
      logo: HelpscoutLogo,
      name: SyncIntegrationNames.helpscout,
      type: 'conversation',
    },
    [SyncIntegrationNames.zendesk]: {
      Component: AdminAutopilotIntegrationItem,
      credits: 1,
      label: 'Zendesk',
      logo: ZendeskLogo,
      name: SyncIntegrationNames.zendesk,
      type: 'conversation',
    },
    [SyncIntegrationNames.appStoreReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.appStore,
      logo: AppStoreLogo,
      name: SyncIntegrationNames.appStoreReviews,
      needsCountry: true,
      platform: ReviewPlatformNames.appStore,
      type: 'review',
    },
    [SyncIntegrationNames.playStoreReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: 'Google Play',
      logo: GooglePlayLogo,
      name: SyncIntegrationNames.playStoreReviews,
      needsCountry: true,
      platform: ReviewPlatformNames.playStore,
      type: 'review',
    },
    [SyncIntegrationNames.g2Reviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.g2,
      logo: G2Logo,
      name: SyncIntegrationNames.g2Reviews,
      platform: ReviewPlatformNames.g2,
      type: 'review',
    },
    [SyncIntegrationNames.capterraReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.capterra,
      logo: CapterraLogo,
      name: SyncIntegrationNames.capterraReviews,
      platform: ReviewPlatformNames.capterra,
      type: 'review',
    },
    [SyncIntegrationNames.trustpilotReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.trustpilot,
      logo: TrustpilotLogo,
      name: SyncIntegrationNames.trustpilotReviews,
      platform: ReviewPlatformNames.trustpilot,
      type: 'review',
    },
    [SyncIntegrationNames.trustradiusReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.trustradius,
      logo: TrustRadiusLogo,
      name: SyncIntegrationNames.trustradiusReviews,
      platform: ReviewPlatformNames.trustradius,
      type: 'review',
    },
    [SyncIntegrationNames.productHuntReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.productHunt,
      logo: ProductHuntLogo,
      name: SyncIntegrationNames.productHuntReviews,
      platform: ReviewPlatformNames.productHunt,
      type: 'review',
    },
    [SyncIntegrationNames.salesforceReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.salesforce,
      logo: SalesforceLogo,
      name: SyncIntegrationNames.salesforceReviews,
      platform: ReviewPlatformNames.salesforce,
      type: 'review',
    },
    [SyncIntegrationNames.wordpressReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.wordpress,
      logo: WordpressLogo,
      name: SyncIntegrationNames.wordpressReviews,
      platform: ReviewPlatformNames.wordpress,
      type: 'review',
    },
    [SyncIntegrationNames.shopifyReviews]: {
      Component: AdminAutopilotReviewIntegrationItem,
      credits: 1,
      label: ReviewPlatformLabels.shopify,
      logo: ShopifyLogo,
      name: SyncIntegrationNames.shopifyReviews,
      platform: ReviewPlatformNames.shopify,
      type: 'review',
    },
    [SyncIntegrationNames.gong]: {
      Component: AdminAutopilotIntegrationItem,
      credits: 5,
      label: 'Gong',
      logo: GongLogo,
      name: SyncIntegrationNames.gong,
      type: 'call',
    },
  };

  // handle boards loading state
  if ('loading' in boards) {
    return null;
  }

  const options = boards.map((board) => ({
    label: board.name,
    value: board._id,
  }));

  const updatePostDeduplication = async (enabled: boolean) => {
    const response = await AJAX.post('/api/queue/togglePostDeduplication', {
      enabled,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadCompany();
  };

  const updateQueueAutomation = async () => {
    if (enabled && !boardID) {
      return;
    }

    setLoading(true);

    const response = await AJAX.post('/api/queue/toggleAutomation', {
      boardID,
      enabled,
    });

    setLoading(false);

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadCompany();
  };

  const { features } = company;
  const hasAutopilot = features.deduplication || features.supportExtraction;

  return (
    <>
      <Helmet title={`Feedback Discovery | Autopilot Settings | Canny`} />
      <div className="adminQueueSettings">
        {hasAutopilot ? (
          <>
            <div className="feedbackDiscoveryModeSection">
              <div className="queueToggleSection">
                <div className="queueDetails">
                  <H1 variant="headingMd">Feedback Suggestions</H1>
                  <P className="queueBody">
                    Choose how you would like feedback suggestions to be handled. You can either
                    manually review the actions or automate them, with the ability to reverse them
                    as needed.
                  </P>
                </div>
                <TextToggle
                  className="enableToggle"
                  disabled={loading}
                  onChange={(value) => setEnabled(value === 'automatic')}
                  options={[
                    { value: 'manual', label: 'Manual' },
                    { value: 'automatic', label: 'Automated' },
                  ]}
                  selected={enabled ? 'automatic' : 'manual'}
                />
                <ButtonV2
                  color="primary"
                  onClick={updateQueueAutomation}
                  disabled={
                    (!enabled && !company.queueAutomation?.enabled) ||
                    (enabled && !boardID) ||
                    loading
                  }
                  loading={loading}
                  size="medium">
                  Save
                </ButtonV2>
              </div>
              {enabled ? (
                <div className="boardSelectSection">
                  <P className="boardSelectLabel" variant="bodyMd">
                    <CornerDownRight height="16" width="16" />
                    Select a destination for generated feedback
                  </P>
                  <div>
                    <SingleSelectWithSearch
                      disabled={loading}
                      onChange={(option) => option && setBoardID(option.value)}
                      options={options}
                      placeholder="Board"
                      value={options.find((option) => option.value === boardID)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="queueToggleSection">
              <div className="queueDetails">
                <div className="queueSectionTitle">
                  <H1 variant="headingMd">Canny Portal</H1>
                  <Pill pillStyle={DefaultPillStyles.canny} className="pill">
                    1 credit / post
                  </Pill>
                </div>
                <P className="queueBody">
                  When new posts are created in the Canny Portal, allow Autopilot to review existing
                  posts to find&nbsp;duplicates.
                </P>
              </div>
              <SwitchV2
                size="medium"
                checked={!!company.deduplication?.enabled}
                onChange={updatePostDeduplication}
              />
            </div>
            <div className="queueToggleSection">
              <div className="queueDetails">
                <H1 variant="headingMd">Sources</H1>
                <P className="queueBody">
                  Select the sources for autopilot to extract feedback from. Detected feedback will
                  be compared to existing posts to identify&nbsp;duplicates.
                </P>
                <div className="integrationsList">
                  {Object.values(Integrations).map(({ Component, ...integrationData }) => (
                    <Component {...integrationData} key={integrationData.name} />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Card borderStyle="solid" className="legacyAutopilotUpsell">
            <CannyAnnouncement
              body={`Never let valuable feedback slip through the cracks again with Canny Autopilot. Automatically detect and consolidate feedback from all your sources in one place.\nMove to our new Free plan to get started.`}
              cta="Get Autopilot"
              image={AutopilotAnnouncement}
              learnMore="https://help.canny.io/en/articles/8202451-beta-inbox"
              onSuccess={() => router.replace('/admin/settings/billing/subscription')}
              title="Feedback Discovery on Autopilot"
            />
          </Card>
        )}
      </div>
    </>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.inbox),
    '/admin/settings'
  )
)(AdminFeedbackDiscoverySettings) as unknown as React.FC<OwnProps>;
