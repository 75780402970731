import React, { useRef, useState } from 'react';

import { Plus, Trash } from 'lucide-react';

import SearchInput from 'common/inputs/SearchInput';
import AdminDropdown from 'common/subdomain/admin/AdminDropdown';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import UserAvatar from 'common/user/UserAvatar';
import findStringMatches from 'common/util/findStringMatches';

import type { CompanyTeamMember, Member } from 'common/api/endpoints/companies';

type Props = {
  members: CompanyTeamMember[];
  onCreate: (member: Member) => void;
  onDelete: (userID: string) => void;
};

const TeamMembers = ({ members, onCreate, onDelete }: Props) => {
  const [search, setSearch] = useState('');
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const filteredMembers = findStringMatches(members, 'name', search);

  const transformedMembers = filteredMembers.map((member) => ({ _id: member.userID, ...member }));

  let headingText = 'Members';
  if (members.length === 1) {
    headingText = '1 Member';
  } else if (members.length > 1) {
    headingText = `${members.length} Members`;
  }

  const onUserSelected = (member: Member) => {
    onCreate(member);
    setUserDropdownOpen(false);
  };

  return (
    <div className="memberSection">
      <div className="memberHeader">
        <h1 className="sectionHeader">{headingText}</h1>
        <ButtonV2
          ref={buttonRef}
          variant="plain"
          startIcon={Plus}
          size="medium"
          onClick={() => setUserDropdownOpen(true)}>
          Add new member
        </ButtonV2>
        {userDropdownOpen && (
          <AdminDropdown
            align="start"
            width={220}
            onUserSelected={onUserSelected}
            relativeElementRef={buttonRef}
            placeholder="Search for a user..."
            onClickOutside={() => setUserDropdownOpen(false)}
            buttonRef={buttonRef}
          />
        )}
      </div>
      {members.length > 5 ? (
        <SearchInput
          autoFocus={false}
          placeholder="Search members..."
          defaultValue={search}
          onChange={(value: string) => setSearch(value)}
          styling="v2"
        />
      ) : null}
      <ul className="teamMemberList">
        {transformedMembers.map((member) => (
          <li key={member.userID} className="teamMember">
            <div className="teamMemberDetails">
              <UserAvatar user={member} />
              <div className="details">
                <span className="userName">{member.name}</span>
                {member.email ? <span className="userEmail">{member.email}</span> : null}
              </div>
            </div>
            <IconButtonV2
              color="error"
              aria-label="Remove user from team"
              variant="plain"
              size="small"
              onClick={() => onDelete(member.userID)}
              icon={Trash}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeamMembers;
