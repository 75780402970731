import React, { useContext, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { ArrowRight, FileQuestion, type LucideProps, Play, Plus, Users, X } from 'lucide-react';

import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import AdminBoardPreview from 'common/subdomain/admin/AdminBoardPreview';
import InstructionTemplate from 'common/subdomain/admin/AdminDashboard/onboarding/InstructionTemplate';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import {
  CreateBoard,
  CustomizePage,
  EnabledAutopilot,
  FiveContributors,
  InstalledIntegration,
  InstalledSource,
  TwoTeammates,
} from 'common/util/getOnboardingTasks';

import AddingTeammatesHero from 'img/adding-teammates-hero.png';
import AutopilotFeedbackHero from 'img/autopilot-feedback-hero.png';
import EnableAutopilotHero from 'img/enable-autopilot-hero.png';
import FiveContributorsHero from 'img/five-contributors-hero.png';
import InstalledIntegrationHero from 'img/installed-integration-hero.png';
import InstalledSourceHero from 'img/installed-source-hero.png';

import type { Company } from 'common/api/endpoints/companies';

const AddingTeammatesVideo = 'https://canny.io/videos/adding-teammates.mp4';
const ConnectSourceVideo = 'https://canny.io/videos/connect-source.mp4';
const EnableAutopilotVideo = 'https://canny.io/videos/enable-autopilot.mp4';
const AutopilotFeedbackVideo = 'https://canny.io/videos/get-autopilot-feedback.mp4';

type LoomVideoProps = {
  src: string;
};

const LoomVideo = ({ src }: LoomVideoProps) => {
  return <iframe width="600" height="358" src={src} allowFullScreen />;
};

type VideoProps = {
  height?: number;
  key: string;
  src?: string;
  thumbnail: string;
};

const Video = ({ height = 330, key, src, thumbnail }: VideoProps) => {
  // state
  const [showVideo, setShowVideo] = useState(false);

  // effects
  useEffect(() => {
    onClose();
  }, [src]);

  // ref
  const videoRef = useRef<HTMLVideoElement>(null);

  const onWatch = () => {
    setShowVideo(true);

    if (!videoRef.current) {
      return;
    }
    videoRef.current.play();

    AJAX.post('/api/analytics/log', {
      key: 'onboarding_video_watch',
      data: {
        video: key,
      },
    });
  };

  const onClose = () => {
    setShowVideo(false);

    if (!videoRef.current) {
      return;
    }
    // videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  return (
    <div className="onboardingVideo" style={{ height }}>
      <div className={classnames('thumbnail', { shown: !showVideo })}>
        <LazyLoadedImage src={thumbnail} />
        {src && (
          <ButtonV2
            className="watchButton"
            onClick={onWatch}
            size="small"
            startIcon={Play}
            variant="outlined">
            Watch Video
          </ButtonV2>
        )}
      </div>
      <div className={classnames('video', { shown: showVideo })}>
        <video controls ref={videoRef}>
          <source type="video/mp4" src={src} />
        </video>
        <IconButtonV2
          aria-label="Close video"
          className="closeButton"
          onClick={onClose}
          size="small"
          icon={X}
          variant="plain"
        />
      </div>
    </div>
  );
};

type CTAProps = {
  endIcon?: React.ComponentType<LucideProps>;
  label: string;
  secondary?: boolean;
  onClick?: () => void;
  startIcon?: React.ComponentType<LucideProps>;
  url?: string;
};

const CTA = ({ endIcon, label, onClick, secondary, startIcon, url }: CTAProps) => {
  const variant = secondary ? 'outlined' : 'contained';

  const button = (
    <ButtonV2
      endIcon={endIcon}
      onClick={onClick}
      size="medium"
      startIcon={startIcon}
      variant={variant}>
      {label}
    </ButtonV2>
  );

  if (onClick) {
    return button;
  }

  const isRelative = url && url[0] === '/';
  if (isRelative) {
    return <Link to={url}>{button}</Link>;
  }

  return (
    <a href={url} rel="noopener" target="_blank">
      {button}
    </a>
  );
};

const AppNameMap = {
  helpscout: 'HelpScout',
  hubspot: 'HubSpot',
  intercom: 'Intercom',
  salesforce: 'Salesforce',
  zendesk: 'Zendesk',
};

type IntegrationInstuctionsProps = {
  completed: boolean;
};

const IntegrationInstructions = ({ completed }: IntegrationInstuctionsProps) => {
  const company = useContext<Company>(CompanyContext);
  const showIntercom = useContext(ShowIntercomContext);

  const {
    integrations,
    useCase: { feedbackSources: usedApps = [] },
  } = company;

  const salesApps = ['salesforce', 'hubspot'];
  const usesSalesApp = salesApps.find((app) => usedApps.includes(app)) as keyof typeof AppNameMap;
  const supportApps = ['intercom', 'zendesk'];
  const usesSupportApp = supportApps.find((app) =>
    usedApps.includes(app)
  ) as keyof typeof AppNameMap;

  if (usesSalesApp) {
    const appName = AppNameMap[usesSalesApp];
    const label = `Connect ${appName}`;
    const canInstall = integrations && integrations[usesSalesApp];
    const desciptionCTA = canInstall
      ? 'Install today and start winning more deals.'
      : 'To set up this paid integration and start winning more deals, contact support.';
    const description = `Our integration lets your team gather feedback directly in ${appName}. ${desciptionCTA}`;
    const url = `/admin/settings/${usesSalesApp}`;
    const cta = canInstall ? (
      <CTA key={`connect${appName}`} endIcon={ArrowRight} label={label} url={url} />
    ) : (
      <CTA key="bookDemo" endIcon={ArrowRight} label="Contact support" onClick={showIntercom} />
    );

    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          cta,
          <CTA
            key="viewIntegrations"
            endIcon={ArrowRight}
            label="View integrations"
            secondary
            url="/admin/settings/integrations"
          />,
        ]}
        description={description}
        hero={
          usesSalesApp === 'salesforce' && (
            <LoomVideo src="https://www.loom.com/embed/5883b29d992c495eb944f66a0b8ec926?hideEmbedTopBar=true" />
          )
        }
        heroImg={usesSalesApp === 'hubspot' && InstalledIntegrationHero}
        heroImgAlt={
          usesSalesApp === 'hubspot'
            ? 'Canny integrates with a variety of tools including Salesforce, Intercom, Hubspot, Asana, and Github'
            : ''
        }
        label={label}
        time={10}
      />
    );
  } else if (usesSupportApp) {
    const appName = AppNameMap[usesSupportApp];
    const hero =
      usesSupportApp === 'intercom' ? (
        <LoomVideo src="https://www.loom.com/embed/06e5a389b4754ad6be5644e5fa08622e?hideEmbedTopBar=true" />
      ) : (
        <LoomVideo src="https://www.loom.com/embed/926b0abe5c034d8b97de1997b0ef9672?hideEmbedTopBar=true" />
      );
    const label = `Connect ${appName}`;
    const description = `You mentioned your team uses ${appName}. Our integration allows your team to capture feedback without leaving ${appName}.  Install today and start listening to your customers.`;
    const url = `/admin/settings/${usesSupportApp}`;

    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA key={`connect${appName}`} endIcon={ArrowRight} label={label} url={url} />,
          <CTA
            key="viewIntegrations"
            endIcon={ArrowRight}
            label="View integrations"
            secondary
            url="/admin/settings/integrations"
          />,
        ]}
        description={description}
        hero={hero}
        label={label}
        time={10}
      />
    );
  }

  return (
    <InstructionTemplate
      completed={completed}
      ctas={[
        <CTA
          key="viewIntegrations"
          endIcon={ArrowRight}
          label="View integrations"
          url="/admin/settings/integrations"
        />,
      ]}
      description="Get more out of Canny with integrations! Connect other tools in your suite to Canny to boost your productivity and get the most out of your feedback."
      heroImg={InstalledIntegrationHero}
      heroImgAlt="Canny integrates with a variety of tools including Salesforce, Intercom, Hubspot, Asana, and Github"
      label="Connect an integration"
      time={10}
    />
  );
};

const FeedbackInstructions = ({ completed }: IntegrationInstuctionsProps) => {
  const company = useContext<Company>(CompanyContext);
  const {
    useCase: { customerFeedback, motivations = [], publicFeedback },
  } = company;

  const autopilotFeedback = motivations.includes('autopilotFeedback');
  const directFeedback = publicFeedback || motivations.includes('directFeedback');
  const behalfFeedback = customerFeedback || !!motivations.find((m) => m.includes('Feedback'));

  if (autopilotFeedback) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="reviewFeeback"
            endIcon={ArrowRight}
            label="Review Feedback"
            url="/admin/autopilot"
          />,
        ]}
        description="Track feedback from five different different users."
        hero={
          <Video
            key="autopilot-feedback"
            src={AutopilotFeedbackVideo}
            thumbnail={AutopilotFeedbackHero}
          />
        }
        label="Get feedback"
        time={3}
      />
    );
  } else if (directFeedback) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="installCanny"
            endIcon={ArrowRight}
            label="Install Canny"
            url="https://developers.canny.io/install"
          />,
          <CTA
            key="bestPractices"
            endIcon={ArrowRight}
            label="Best Practices"
            secondary
            url="https://canny.io/blog/make-your-user-feedback-tool-more-accessible/"
          />,
        ]}
        description="Encourage your users to give feedback by embedding Canny into your app. Also, learn best practice to make the most out of Canny."
        hero={
          <LoomVideo src="https://www.loom.com/embed/48f7eff11b484abbb8cd8fbf6694b2ec?hideEmbedTopBar=true" />
        }
        label="Collect feedback"
        time={10}
      />
    );
  } else if (behalfFeedback) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="voteOnBehalf"
            endIcon={ArrowRight}
            label="Learn more"
            url="https://help.canny.io/en/articles/636112-vote-on-behalf-of-your-users"
          />,
        ]}
        description="Tracking feedback for your users is easy in Canny. Click below to learn how to vote on their behalf."
        hero={
          <LoomVideo src="https://www.loom.com/embed/b9bc427d19454455954e876985d76689?hideEmbedTopBar=true" />
        }
        label="Collect feedback"
        time={10}
      />
    );
  }

  return (
    <InstructionTemplate
      completed={completed}
      ctas={[
        <CTA
          key="shareBoard"
          endIcon={ArrowRight}
          label="Share your board"
          url="https://canny.io/blog/make-your-user-feedback-tool-more-accessible/"
        />,
      ]}
      description="Feedback is important in creating value for your users and keeping them happy. Share your board with them to begin collecting your first user insights!"
      heroImg={FiveContributorsHero}
      heroImgAlt="Illustration of feedback from multiple people"
      label="Collect feedback"
      time={4}
    />
  );
};

type InstructionsProps = {
  completed: boolean;
  taskName: string;
};

const Instructions = ({ completed, taskName }: InstructionsProps) => {
  const boards = useContext(BoardsContext);

  if (taskName === CreateBoard) {
    const firstBoard = 'loading' in boards ? null : boards[0];
    const ctas = firstBoard
      ? [
          <CTA
            key="manageAccess"
            endIcon={ArrowRight}
            label="Manage access"
            url={`/admin/settings/boards/${firstBoard.urlName}/privacy`}
          />,
          <CTA
            key="viewBoard"
            endIcon={ArrowRight}
            label="View board"
            url={`/admin/settings/boards/${firstBoard.urlName}/general`}
            secondary
          />,
        ]
      : [<CTA key="create" label="Create board" startIcon={Plus} url="/admin/create-board" />];
    return (
      <InstructionTemplate
        completed={completed}
        ctas={ctas}
        description="Boards keep your customers' ideas organized. You can receive feedback directly through public boards, or use private boards for internal teams or specific users."
        hero={
          <div className="boardPreviewWrapper">
            <AdminBoardPreview />
          </div>
        }
        label="Create a board"
        time={2}
      />
    );
  } else if (taskName === CustomizePage) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="customize"
            endIcon={ArrowRight}
            label="Customize workspace"
            url="/admin/settings/company"
          />,
          <CTA
            key="customizeHelp"
            startIcon={FileQuestion}
            label="Learn More"
            secondary
            url="https://help.canny.io/en/articles/1627404-setting-your-branding"
          />,
        ]}
        hero={
          <LoomVideo src="https://www.loom.com/embed/32659edd1f3741e28baa9b166b457ed4?hideEmbedTopBar=true" />
        }
        description="Make Canny feel like your home for feedback. Customize your logo and color scheme to keep Canny consistent with your brand."
        label="Customize your page"
        time={4}
      />
    );
  } else if (taskName === EnabledAutopilot) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="toggleSources"
            endIcon={ArrowRight}
            label="Toggle sources"
            url="/admin/settings/autopilot/feedback-discovery"
          />,
        ]}
        description="Toggle on a connected source so Canny Autopilot starts looking for feedback."
        hero={
          <Video
            key="enable-autopilot"
            src={EnableAutopilotVideo}
            thumbnail={EnableAutopilotHero}
          />
        }
        label="Turn a source on"
        time={1}
      />
    );
  } else if (taskName === FiveContributors) {
    return <FeedbackInstructions completed={completed} />;
  } else if (taskName === InstalledIntegration) {
    return <IntegrationInstructions completed={completed} />;
  } else if (taskName === InstalledSource) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="installSource"
            endIcon={ArrowRight}
            label="Install source"
            url="/admin/settings/autopilot/feedback-discovery"
          />,
        ]}
        description="Connect to places where you get feedback so Canny can start automatically capturing feedback."
        hero={
          <Video key="connect-source" src={ConnectSourceVideo} thumbnail={InstalledSourceHero} />
        }
        label="Connect a source"
        time={1}
      />
    );
  } else if (taskName === TwoTeammates) {
    return (
      <InstructionTemplate
        completed={completed}
        ctas={[
          <CTA
            key="inviteMembers"
            endIcon={ArrowRight}
            label="Invite members"
            url="/admin/settings/team"
          />,
          <CTA
            key="manageRoles"
            label="Manage roles"
            startIcon={Users}
            url="/admin/settings/team/roles"
            secondary
          />,
        ]}
        description="Unleash the full potential of Canny with your team! Invite them today to experience the power of collaboration and supercharge your productivity."
        hero={
          <Video
            height={360}
            key="adding-teammates"
            src={AddingTeammatesVideo}
            thumbnail={AddingTeammatesHero}
          />
        }
        label="Invite your team"
        time={2}
      />
    );
  }

  return (
    <InstructionTemplate
      completed={completed}
      description="You should not see these instructions."
      label="Complete Onboarding"
      time={4}
    />
  );
};

export default Instructions;
