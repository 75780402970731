import React from 'react';

import classnames from 'classnames';

import UppercaseHeader from 'common/UppercaseHeader';

import 'css/components/common/_NoteBox.scss';

type Props = {
  className: string;
  title: string;
  colorScheme: 'note' | 'hazard';
  children: React.ReactNode;
};

const NoteBox = ({ className, title, colorScheme = 'hazard', children }: Props) => {
  return (
    <div className={classnames('noteBox', className, colorScheme)}>
      {title && <UppercaseHeader className={colorScheme}>{title}</UppercaseHeader>}
      {children}
    </div>
  );
};
export default NoteBox;
