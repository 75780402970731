import React, { useContext } from 'react';

import { RouterContext } from 'common/containers/RouterContainer';
import AdminQueueAlert, {
  AlertTypes,
} from 'common/subdomain/admin/AdminQueue/AdminQueueAlerts/AdminQueueAlert';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';

import type { Company } from 'common/api/endpoints/companies';

type Props = {
  company: Company;
};

const AdminQueueDraftLimit = ({ company }: Props) => {
  const router = useContext(RouterContext);

  if (company.queueAutomation?.enabled) {
    return null;
  }

  return (
    <AdminQueueAlert
      type={AlertTypes.warning}
      text={
        <P>
          Maximum number of suggestions reached. Resolve existing suggestions to continue processing
          or enable automated actions.
        </P>
      }
      actions={
        <ButtonV2
          onClick={() => router.push('/admin/settings/autopilot/feedback-discovery')}
          size="medium">
          Automate actions
        </ButtonV2>
      }
    />
  );
};

export default AdminQueueDraftLimit;
