import React, { useContext } from 'react';

import { SyncReviewIntegrationNames } from 'common/constants/autopilotIntegrations';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import translateString from 'common/i18n/translateString';

import { DuplicatePostSuggestion, UniquePostSuggestion } from './Suggestion';

import type { Company } from 'common/api/endpoints/companies';
import type { ActionItem, PostActionItem, PostDraftActionItem } from 'common/api/endpoints/queue';

type Props = {
  item: ActionItem;
  company: Company;
};

const PostDraftSuggestedAction = ({
  company,
  item,
}: {
  company: Company;
  item: PostDraftActionItem;
}) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  const { sourcePostDraft, duplicatePost } = item;
  const isReviewSource = Object.values(SyncReviewIntegrationNames).includes(
    item.source as unknown as SyncReviewIntegrationNames
  );
  const defaultLink = isReviewSource
    ? '/admin/settings/autopilot/feedback-discovery'
    : '/admin/settings/integrations';

  if (!duplicatePost) {
    return (
      <UniquePostSuggestion
        title={translateString(sourcePostDraft, 'title')}
        details={translateString(sourcePostDraft as { details: string }, 'details')}
        board={item.board}
        author={sourcePostDraft.author}
        created={sourcePostDraft.createdAt}
        workaround={sourcePostDraft.workaround}
        onClick={() => window.open(item.link ?? defaultLink, '_blank', 'noopener,noreferrer')}
      />
    );
  }

  return (
    <DuplicatePostSuggestion
      sourceType="draft"
      title={translateString(sourcePostDraft, 'title')}
      details={translateString(sourcePostDraft as { details: string }, 'details')}
      author={sourcePostDraft.author}
      created={sourcePostDraft.createdAt}
      board={duplicatePost.board}
      duplicatePost={duplicatePost}
      company={company}
      workaround={sourcePostDraft.workaround}
      onClickDuplicatePost={() =>
        window.open(item.link ?? defaultLink, '_blank', 'noopener,noreferrer')
      }
      onClickOriginalPost={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: item.duplicatePost?.urlName },
        })
      }
    />
  );
};

const CannyPostSuggestedAction = ({
  company,
  item,
}: {
  company: Company;
  item: PostActionItem;
}) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  const { sourcePost, duplicatePost } = item;

  return (
    <DuplicatePostSuggestion
      sourceType="post"
      title={translateString(sourcePost, 'title')}
      details={translateString(sourcePost, 'details')}
      author={sourcePost.author}
      created={sourcePost.created}
      board={duplicatePost.board}
      duplicatePost={duplicatePost}
      company={company}
      onClickDuplicatePost={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: item.urlName },
        })
      }
      onClickOriginalPost={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: item.duplicatePost?.urlName },
        })
      }
    />
  );
};

const AuditLogSuggestionCell = ({ company, item }: Props) => {
  if (item.sourceType === 'draft') {
    return <PostDraftSuggestedAction company={company} item={item} />;
  }

  if (item.sourceType === 'post') {
    return <CannyPostSuggestedAction company={company} item={item} />;
  }

  return null;
};

export default AuditLogSuggestionCell;
