import React from 'react';

import classnames from 'classnames';

import type { LucideIcon } from 'lucide-react';

import 'css/components/subdomain/admin/_AdminQueueNUX.scss';

type Props = {
  title: string;
  icon: LucideIcon;
  loading?: boolean;
  info: string[];
  cta?: React.ReactNode;
};

const AdminQueueNUX = ({ title, icon: Icon, info, cta, loading }: Props) => {
  return (
    <div className="adminQueueNUX">
      <div className="iconContainer">
        <Icon className={classnames('icon', { spin: loading })} strokeWidth={1} size={64} />
      </div>
      <div className="textContent">
        <h1 className="title">{title}</h1>
        {info.map((content) => (
          <p className="info" key={content}>
            {content}
          </p>
        ))}
      </div>
      {cta}
    </div>
  );
};

export default AdminQueueNUX;
