import { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import mapify from 'common/util/mapify';

import { type QueryParams, getQueryKey, loadQuery } from '../actions/inboxItemQueries';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { Confidence, InboxItem, ItemType, SortOptions } from 'common/api/resources/inbox';

export type EncodedQuery = {
  boardURLNames?: string;
  confidence?: Confidence;
  itemType?: ItemType;
  sort?: SortOptions;
};

type LocationWithQuery = {
  pathname: string;
  query: EncodedQuery;
};

type Props = {
  boards: Board[];
  children: React.ReactNode;
  inboxItemQueries: Record<
    string,
    {
      items: InboxItem[];
      lastCreated: Date | null;
      lastUpdated: number;
      loading: boolean;
      error: string;
      totalItemCount: number;
    }
  >;
  location: LocationWithQuery;
};

export const getQueryParams = (location: LocationWithQuery): QueryParams => {
  const { query } = location;

  const boardURLNames = query.boardURLNames ? query.boardURLNames.split('_') : null;
  const confidence = query.confidence ?? null;
  const itemType = query.itemType ?? null;
  const sort = query.sort ?? null;

  return {
    ...(boardURLNames && { boardURLNames }),
    ...(confidence && { confidence }),
    ...(itemType && { itemType }),
    ...(sort && { sort }),
    limit: 20,
  };
};

export const getEncodedQueryParams = (params: QueryParams): EncodedQuery => {
  const { boardURLNames, confidence, itemType, sort } = params;

  // don't include limit in the encoded query since it's always the same
  return {
    ...(boardURLNames && { boardURLNames: boardURLNames.join('_') }),
    ...(confidence && { confidence }),
    ...(itemType && { itemType }),
    ...(sort && { sort }),
  };
};

// Exposes the list of spam items, as well as the count
// This may have some dead code paths now that the '/admin/moderation' route has been removed
const InboxItemListContainer = (props: Props) => {
  const { boards, inboxItemQueries, location } = props;

  const company = useContext<Company>(CompanyContext);

  const getInboxItemList = () => {
    const queryParams = getQueryParams(location);
    const inboxQuery = inboxItemQueries[getQueryKey(queryParams)];

    if (!inboxQuery?.items?.length) {
      return Object.assign({ loading: true }, inboxQuery);
    }

    const boardMap = mapify(boards, '_id');
    inboxQuery.items.forEach((item) => {
      item.board = boardMap[item.boardID];

      if (item.itemType === 'comment') {
        item.post.board = boardMap[item.post.boardID];
      }
    });

    return inboxQuery;
  };

  const getTotalItemCount = () => {
    const lastQuery = Object.values(inboxItemQueries)
      .filter((query) => !query.loading && !query.error)
      .sort((a, b) => a.lastUpdated - b.lastUpdated)
      .pop();

    if (!lastQuery) {
      return 0;
    }

    return lastQuery.totalItemCount;
  };

  return cloneElementWithProps(props.children, {
    ...props,
    ...(company.features.moderationTools && {
      inboxItemList: getInboxItemList(),
      inboxTotalItemCount: getTotalItemCount(),
    }),
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState }, location }) => {
        const { pathname, query } = location;

        const isModeration = pathname.includes('/admin/autopilot') && query.source === 'spam';

        const initiallyLoaded = !!Object.keys(getState().inboxItemQueries).length;

        // NOTE: We'd like to load more items only in moderation page
        if (initiallyLoaded && !isModeration) {
          return;
        }

        return dispatch(loadQuery(getQueryParams(location)));
      },
    },
  ],
  (state) => ({
    inboxItemQueries: state.inboxItemQueries,
  })
)(InboxItemListContainer);
