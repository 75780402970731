import React, { Component } from 'react';

import classnames from 'classnames';
import { Eye, Home, Lightbulb, Map, RefreshCw } from 'lucide-react';
import PropTypes from 'prop-types';

import AccountModal, { FormStates } from 'common/AccountModal';
import CompanyLogo from 'common/company/CompanyLogo';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Helmet from 'common/helmets/Helmet';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import NotificationsMenu from 'common/notifications/NotificationsMenu';
import ButtonV2 from 'common/ui/ButtonV2';
import UppercaseHeader from 'common/UppercaseHeader';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import delayer from 'common/util/delayer';
import devURL from 'common/util/devURL';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import withContexts from 'common/util/withContexts';

import GlobalSearch from './GlobalSearch';
import PublicNavAccountMenu from '../PublicNavAccountMenu';
import PublicNavBoardDropdown from '../PublicNavBoardDropdown';

import PurpleLogo from 'img/canny-wordmark.svg';

import 'css/components/subdomain/public/PublicNav/_PublicNav.scss';

class PublicNav extends Component {
  static propTypes = {
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.shape({
      error: PropTypes.string,
      changelog: PropTypes.shape({
        hasPublishedEntries: PropTypes.bool,
      }),
      logoURL: PropTypes.string,
      notFound: PropTypes.bool,
      showRoadmap: PropTypes.bool,
      viewerIsMember: PropTypes.bool,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    notifications: PropTypes.object,
    openModal: PropTypes.func.isRequired,
    post: PropTypes.object,
    tintColor: PropTypes.string,
    viewer: PropTypes.object,
  };

  state = {
    searchFocused: false,
    searchValue: '',
    open: false,
  };

  constructor(props, context) {
    super(props, context);
    this.debouncedSearch = new delayer(this.onSearchChangeAfterDelay, 250);
  }

  componentDidMount() {
    const searchValue = decodeQueryTextParam(this.props.location?.query?.search) ?? '';
    this.setState({ searchValue });
  }

  componentDidUpdate(prevProps) {
    // Clear search state when you navigate away from a global search-enabled route
    const oldPathname = prevProps?.location?.pathname;
    const newPathname = this.props?.location?.pathname;
    const searchPath = '/search';

    if (oldPathname !== newPathname && !newPathname.startsWith(searchPath) && newPathname !== '/') {
      this.setState({
        searchFocused: false,
        searchValue: '',
      });
    }
  }

  onChooseOption = () => {
    this.setState({
      open: false,
    });
  };

  onSearchChangeAfterDelay = (searchQuery) => {
    const { location, router } = this.props;
    const searchPath = '/search';
    const isSearch = location.pathname.startsWith(searchPath);
    const encodedSearch = encodeURIComponent(searchQuery);
    const options = {
      pathname: searchPath,
      query: {
        search: encodedSearch,
      },
    };

    if (isSearch) {
      router.replace(options);
    } else {
      router.push(options);
    }
  };

  onSearchChange = (searchValue) => {
    this.setState({ searchValue });

    const { router } = this.props;
    if (searchValue === '') {
      router.push('/');
      this.debouncedSearch.cancel();
      return;
    }

    this.debouncedSearch.callAfterDelay(searchValue);
  };

  renderAccountMenu() {
    const { board, post, viewer } = this.props;
    if (!viewer || viewer.loggedOut) {
      return null;
    }

    return <PublicNavAccountMenu board={board} post={post} />;
  }

  renderBackLink() {
    const {
      company,
      location: { pathname },
    } = this.props;
    const isMember = company.viewerIsMember;
    if (!isMember) {
      return null;
    }

    const { board, post } = this.props;

    var link = '/admin';
    if (board) {
      if (post) {
        link = '/admin/board/' + board.urlName + '/p/' + post.urlName;
      } else {
        link = '/admin/board/' + board.urlName;
      }
    } else if (pathname.startsWith('/changelog')) {
      link = '/admin/changelog';
    }

    return (
      <Link to={link}>
        <ButtonV2 startIcon={Eye} className="adminViewLink" variant="outlined">
          Admin view
        </ButtonV2>
      </Link>
    );
  }

  renderCreateLink() {
    const { board, company, location, tintColor, viewer } = this.props;
    if (!board || !board.urlName || !board?.settings?.showCreateForm) {
      return null;
    }

    const linkStyle = {
      background: tintColor,
    };

    const { authRedirectEnabled, authRedirectURL } = company;
    if (viewer.loggedOut && authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location);
      return (
        <a href={redirectURL} className="createLink" style={linkStyle}>
          <span className="icon icon-pencil" />
          <UppercaseHeader>Create</UppercaseHeader>
        </a>
      );
    }

    const create = '/' + board.urlName + '/create';
    return (
      <Link to={create} className="createLink" style={linkStyle}>
        <span className="icon icon-pencil" />
        <UppercaseHeader>Create</UppercaseHeader>
      </Link>
    );
  }

  renderContents() {
    const { company } = this.props;

    if (company.error || company.notFound || company.loading) {
      const homepageURL = devURL('https://canny.io');
      return (
        <a href={homepageURL} className="homeLink">
          <LazyLoadedImage alt="Canny logo" height={20} src={PurpleLogo} />
        </a>
      );
    }

    const {
      boardCount,
      changelog: { hasPublishedEntries },
      logoURL,
      name,
    } = company;
    const homeURL = boardCount === 0 && hasPublishedEntries ? '/changelog' : '/';

    return (
      <Link to={homeURL} className="company">
        {logoURL ? (
          <div className="logoContainer">
            <CompanyLogo />
            <div className="companyName">{name}</div>
          </div>
        ) : (
          <div className="companyName">{name}</div>
        )}
      </Link>
    );
  }

  renderHelmet() {
    const { board, company } = this.props;

    const noIndex = (
      <Helmet
        meta={[
          {
            name: 'robots',
            content: 'noindex,nofollow',
          },
        ]}
      />
    );

    if (company.billingData?.status !== 'active') {
      return noIndex;
    }

    if (board && board.settings && !board.settings.indexed) {
      return noIndex;
    }

    if (company.billingData?.isFree) {
      return noIndex;
    }

    return null;
  }

  renderLoginLink() {
    const { company, location, viewer } = this.props;
    if (!viewer.loggedOut) {
      return null;
    }

    const { authRedirectEnabled, authRedirectURL } = company;
    if (authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location);
      return (
        <a href={redirectURL}>
          <ButtonV2 size="medium">Log in</ButtonV2>
        </a>
      );
    }

    if (viewer._id) {
      return null;
    }

    return (
      <div className="authButtons">
        <ButtonV2
          onClick={() => this.props.openModal(AccountModal, { formState: FormStates.login })}
          size="medium"
          variant="outlined">
          Log in
        </ButtonV2>
        <ButtonV2
          onClick={() => this.props.openModal(AccountModal, { formState: FormStates.signup })}
          size="medium">
          Sign up
        </ButtonV2>
      </div>
    );
  }

  renderSecondaryNav() {
    const {
      board,
      boards,
      company,
      location: { pathname },
      tintColor,
    } = this.props;

    if (!company || !company.changelog) {
      return null;
    }

    if (boards.length === 0) {
      return null;
    }

    const { searchFocused, searchValue } = this.state;

    const hideLinks = searchFocused || searchValue !== '';

    const {
      changelog: { hasPublishedEntries },
      showChangelog,
      showRoadmap,
    } = company;

    const isChangelog = !board && pathname.startsWith('/changelog');
    const isRoadmap = pathname === '/';
    const isSearch = pathname.startsWith('/search');
    const isBoard =
      !isChangelog &&
      !isRoadmap &&
      !isSearch &&
      board &&
      board._id &&
      pathname.startsWith('/' + board.urlName);

    const FirstLinkIconComponent = showRoadmap ? Map : Home;
    const firstLinkText = showRoadmap ? 'Roadmap' : 'Home';

    return (
      <div className="secondaryNav">
        <Link
          className="link roadmap"
          to="/"
          activeClassName="activeLink"
          style={{
            ...(isRoadmap && {
              borderBottomColor: tintColor,
            }),
          }}>
          <span className={classnames('subdomainHomeIcons', { rotate: showRoadmap })}>
            <FirstLinkIconComponent
              className="subdomainHomeIcon"
              style={isRoadmap && tintColor ? { stroke: tintColor } : {}}
            />
          </span>
          <div className="text" style={isRoadmap && tintColor ? { color: tintColor } : {}}>
            {firstLinkText}
          </div>
        </Link>
        {Array.isArray(boards) && boards.length ? (
          <Link
            className={classnames('link', 'feedback', { hideOnMobile: true })}
            to={`/${board ? board.urlName : boards[0].urlName}`}
            activeClassName="activeLink"
            style={{ ...(isBoard && { borderBottomColor: tintColor }) }}>
            <span className="subdomainHomeIcons">
              <Lightbulb
                className="subdomainHomeIcon"
                style={isBoard && tintColor ? { stroke: tintColor } : {}}
              />
            </span>
            <div className="text" style={isBoard && tintColor ? { color: tintColor } : {}}>
              Feedback
            </div>
          </Link>
        ) : null}
        <div
          className={classnames('link', 'boards', {
            hideOnMobile: hideLinks,
            hide: true,
          })}
          style={{
            ...(isBoard && {
              borderBottomColor: tintColor,
            }),
          }}>
          <span className="subdomainHomeIcons">
            <Lightbulb
              className="subdomainHomeIcon"
              style={isBoard && tintColor ? { stroke: tintColor } : {}}
            />
          </span>
          <PublicNavBoardDropdown
            board={board && board._id ? board : null}
            boards={boards}
            selectionStyle={isBoard && tintColor ? { color: tintColor } : {}}
          />
        </div>
        {showChangelog && hasPublishedEntries ? (
          <Link
            className={classnames('link', 'changelog', { hideOnMobile: hideLinks })}
            to="/changelog"
            activeClassName="activeLink"
            style={{ ...(isChangelog && { borderBottomColor: tintColor }) }}>
            <span className="subdomainHomeIcons">
              <RefreshCw
                className="subdomainHomeIcon"
                style={isChangelog && tintColor ? { stroke: tintColor } : {}}
              />
            </span>
            <div className="text" style={isChangelog && tintColor ? { color: tintColor } : {}}>
              Changelog
            </div>
          </Link>
        ) : null}
        {isRoadmap || isSearch ? (
          <GlobalSearch
            className={classnames('search')}
            value={searchValue}
            focused={searchFocused}
            onFocus={() => this.setState({ searchFocused: true })}
            onBlur={() => this.setState({ searchFocused: false })}
            onChange={this.onSearchChange}
          />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <ContentContainer innerClassName="publicNavContent" outerClassName="publicNav">
        {this.renderHelmet()}
        <div className="mainNav">
          {this.renderContents()}
          <div className="rightContainer">
            <NotificationsMenu v2 notifications={this.props.notifications} tint={true} />
            {this.renderBackLink()}
            {this.renderAccountMenu()}
            {this.renderCreateLink()}
            {this.renderLoginLink()}
          </div>
        </div>
        {this.renderSecondaryNav()}
      </ContentContainer>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  openModal: OpenModalContext,
  tintColor: TintColorContext,
  viewer: ViewerContext,
})(PublicNav);
