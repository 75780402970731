export enum ContextTypes {
  changelogEntryImage = 'changelogEntryImage',
  commentFile = 'commentFile',
  commentImage = 'commentImage',
  companyFavicon = 'companyFavicon',
  companyLogo = 'companyLogo',
  postFile = 'postFile',
  postImage = 'postImage',
  thirdPartyUserAvatar = 'thirdPartyUserAvatar',
  userAvatar = 'userAvatar',
}

export enum FileFolders {
  files = 'files',
  images = 'images',
}

export enum MimeTypes {
  png = 'image/png',
  jpeg = 'image/jpeg',
  gif = 'image/gif',
  webp = 'image/webp',
  svg = 'image/svg+xml',
  txt = 'text/plain',
  rtf = 'application/rtf',
  csv = 'text/csv',
  md = 'text/markdown',
  yaml = 'application/x-yaml',
  // Note: We temporarily disabled PDF uploads due to a security vulnerability, will enable it when we have a solid file scanning solution
  // pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  odt = 'application/vnd.oasis.opendocument.text',
  ods = 'application/vnd.oasis.opendocument.spreadsheet',
  odp = 'application/vnd.oasis.opendocument.presentation',
  key = 'application/x-iwork-keynote-sffkey',
  json = 'application/json',
  xml = 'application/xml',
  mp4 = 'video/mp4',
  avi = 'video/x-msvideo',
  mov = 'video/quicktime',
  wmv = 'video/x-ms-wmv',
  flv = 'video/x-flv',
}

type MimeTypeKeys = keyof typeof MimeTypes;

// Create the Extensions enum using the keys from MimeTypes
export const Extensions = Object.freeze(
  Object.fromEntries(Object.keys(MimeTypes).map((key) => [key, key])) as { [K in MimeTypeKeys]: K }
);

export const ImageMimeTypes = [
  MimeTypes.png,
  MimeTypes.jpeg,
  MimeTypes.gif,
  MimeTypes.webp,
  MimeTypes.svg,
];

export type ExtensionTypes = keyof typeof Extensions;

export type FileWithMetadata = {
  buffer: Buffer;
  contentLength: number;
  contentType: MimeTypes;
};

export enum LoadStatus {
  failed = 'failed',
  loading = 'loading',
  loaded = 'loaded',
  notStarted = 'notStarted',
}

export type LocalFile = {
  name: string;
  contentType: MimeTypes | null;
  uniqueID: string;
  uploadStatus: LoadStatus;
  url: string | null;
};

export type FailedFile = LocalFile & { uploadStatus: LoadStatus.failed; url: null };
export type UploadingFile = LocalFile & { uploadStatus: LoadStatus.loading; url: null };
export type UploadedFile = LocalFile & { uploadStatus: LoadStatus.loaded; url: string };

export enum ObjectTypes {
  changelogEntry = 'changelogEntry',
  comment = 'comment',
  company = 'company',
  post = 'post',
  thirdPartyUser = 'thirdPartyUser',
  user = 'user',
}

export type ObjectFile = { name: string; url: string };
