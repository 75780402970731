import React from 'react';

import classnames from 'classnames';

import ModalPortal from 'common/modals/ModalPortal';

import 'css/components/modals/_ModernModal.scss';

type Props = {
  overlayClassName?: string;
  modalClassName?: string;
  footer: React.ReactNode;
  header: React.ReactNode;
  onClose: () => void;
  sections: React.ReactNode[];
};

const ModernModal = ({
  overlayClassName,
  modalClassName,
  onClose,
  header,
  footer,
  sections,
}: Props) => {
  return (
    <ModalPortal
      overlayClassName={classnames('modernModalPortal', overlayClassName)}
      modalClassName={classnames('modernModalPortalContents', modalClassName)}
      closeOnClickAway={true}
      onClose={onClose}>
      <header className="content header">
        <h1>{header}</h1>
        <button className="close" onClick={onClose}>
          <div className="closeIcon icon icon-x" />
        </button>
      </header>

      <div className="sections">
        {sections.map((section, index: number) => (
          <section className="content section" key={index}>
            {section}
          </section>
        ))}
      </div>

      <section className="content footer">{footer}</section>
    </ModalPortal>
  );
};

export default ModernModal;
