import React, { useContext } from 'react';

import { Sparkles } from 'lucide-react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Markdown from 'common/markdown/Markdown';
import ModalPortal from 'common/modals/ModalPortal';
import ButtonV2 from 'common/ui/ButtonV2';
import { H1, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/modals/_CannyAnnouncementModal.module.scss';

import type { Dispatch } from 'redux';

type AnnouncementProps = {
  body: string;
  cta: string;
  image: string;
  learnMore?: string;
  onDismiss?: () => void;
  onSuccess: () => void;
  title: string;
};

export const CannyAnnouncement = ({
  body,
  cta,
  image,
  learnMore,
  onDismiss,
  onSuccess,
  title,
}: AnnouncementProps) => {
  return (
    <div>
      <div className={styles.header}>
        <LazyLoadedImage src={image} />
      </div>
      <section className={styles.modalContent}>
        <Pill pillStyle={DefaultPillStyles.canny}>
          <P fontWeight="medium" className={styles.pill}>
            <Sparkles strokeWidth={2} size={14} />
            New
          </P>
        </Pill>
        <H1 variant="headingXl">{title}</H1>
        <div className={styles.body}>
          <Markdown contents={body} />
        </div>
        <footer className={styles.footer}>
          <div className={styles.right}>
            {onDismiss && (
              <button className={styles.skipButton} onClick={onDismiss}>
                <P>Dismiss</P>
              </button>
            )}
            <ButtonV2 size="medium" onClick={onSuccess}>
              {cta}
            </ButtonV2>
          </div>
          {learnMore ? (
            <a
              href={learnMore}
              target="_blank"
              rel="nofollow noopener"
              className={styles.learnMore}>
              Learn more
            </a>
          ) : null}
        </footer>
      </section>
    </div>
  );
};

type ModalProps = {
  name: string;
};

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type OwnProps = AnnouncementProps & ModalProps;
type Props = OwnProps & ConnectProps;

const CannyAnnouncementModal = ({
  body,
  cta,
  image,
  learnMore,
  name,
  onSuccess,
  reloadCompany,
  title,
}: Props) => {
  const showToast = useContext(ShowToastContext);

  const close = async () => {
    const response = await AJAX.post('/api/nux/dismissNUX', { nuxType: name });

    const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    reloadCompany?.();
  };

  const succeed = async () => {
    await close();
    onSuccess();
  };

  return (
    <ModalPortal modalClassName={styles.modal} onClose={close} closeOnClickAway allowEscape>
      <CannyAnnouncement
        body={body}
        cta={cta}
        image={image}
        learnMore={learnMore}
        onDismiss={close}
        onSuccess={succeed}
        title={title}
      />
    </ModalPortal>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    dispatch(reloadCompany());
  },
}))(CannyAnnouncementModal) as unknown as React.FC<OwnProps>;
