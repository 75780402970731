import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost, reloadPostByURLName } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import translateString, { hasTranslation } from 'common/i18n/translateString';
import TranslationToggle from 'common/i18n/TranslationToggle';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import UserLockup from 'common/user/UserLockup';
import devURL from 'common/util/devURL';
import getCannyOrigin from 'common/util/getCannyOrigin';
import hasPermission from 'common/util/hasPermission';
import parseJSON from 'common/util/parseJSON';
import withContexts from 'common/util/withContexts';
import { IsIntercomContext } from 'common/widget/WidgetContext';

import PostBody from './PostBody';
import PostLink from './PostLink';

import 'css/components/post/_PostMerge.scss';

class PostMerge extends Component {
  static propTypes = {
    allowUnmerge: PropTypes.bool,
    board: PropTypes.object,
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    isIntercom: PropTypes.bool,
    merge: PropTypes.object,
    openModal: PropTypes.func,
    post: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    hideTranslation: false,
    unmerging: false,
  };

  onToggleTranslation = () => {
    this.setState({
      hideTranslation: !this.state.hideTranslation,
    });
  };

  onUnmerge = () => {
    const { unmerging } = this.state;
    if (unmerging) {
      return;
    }

    const { company, openModal, viewer } = this.props;
    if (!hasPermission('mergePosts', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['mergePosts'],
      });
      return;
    }

    openModal(ConfirmModal, {
      message:
        "Are you sure you'd like to unmerge this post? All comments, votes, etc will be restored.",
      onConfirm: () => {
        this.setState({
          unmerging: true,
        });

        const { merge } = this.props;
        AJAX.post(
          '/api/posts/unmerge',
          {
            postMergeID: merge._id,
          },
          (responseJSON) => {
            parseJSON(responseJSON, async (error, response) => {
              if (error || !response.postURLName) {
                this.setState({
                  error: 'Something went wrong, please contact our team for support.',
                  unmerging: false,
                });
                return;
              }

              const { board, post, reloadData, router } = this.props;
              const { postURLName, fromBoard } = response;
              await reloadData(postURLName, post, board, fromBoard);
              router.push(`/admin/board/${fromBoard.urlName}/p/${postURLName}`);
            });
          }
        );
      },
    });
  };

  renderAuthor() {
    const {
      company: { viewerIsMember },
      board: { settings: boardSettings },
      viewer,
    } = this.props;
    const { postAuthor } = this.props.merge;

    if (!postAuthor) {
      return null;
    }

    const isAuthorViewer = postAuthor._id === viewer._id;
    const showAlias = boardSettings.privateAuthors && isAuthorViewer && !viewerIsMember;

    return <UserLockup user={postAuthor} showAlias={showAlias} />;
  }

  renderDescription() {
    return <div className="mergeDescription">Merged in a post:</div>;
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return (
      <div className="errorMenu">
        <div className="middot">&middot;</div>
        <div className="error">{error}</div>
      </div>
    );
  }

  renderMerge() {
    const { merge } = this.props;
    const { hideTranslation } = this.state;

    const fakePost = {
      details: translateString(merge, 'postDetails', hideTranslation),
      files: merge.files,
      imageURLs: merge.postImageURLs,
    };
    const translationObject = merge;
    const translationStringKeys = ['postDetails', 'postTitle'];
    return (
      <div className="mergeContainer">
        <div className="title">{translateString(merge, 'postTitle', hideTranslation)}</div>
        {this.renderAuthor()}
        <div className="bottomContainer">
          <PostBody post={fakePost} />
          <div className="menu">
            {merge.postCreated ? <Timestamp timestamp={merge.postCreated} /> : null}
            {hasTranslation(translationObject, translationStringKeys) ? (
              <span className="middot">&middot;</span>
            ) : null}
            <TranslationToggle
              className="translationToggle"
              hideTranslation={this.state.hideTranslation}
              object={translationObject}
              onToggle={this.onToggleTranslation}
              stringKeys={translationStringKeys}
              variant="text"
            />
          </div>
        </div>
      </div>
    );
  }

  renderTimestamp() {
    const { board, company, isIntercom, merge, post } = this.props;
    if (!merge.created) {
      return null;
    }

    if (isIntercom) {
      const postURL = devURL(getCannyOrigin(company) + '/' + board.urlName + '/p/' + post.urlName);
      return (
        <a className="timestamp" href={postURL} rel="noopener" target="_blank">
          <Timestamp timestamp={merge.created} />
        </a>
      );
    }

    return (
      <PostLink post={post}>
        <Timestamp timestamp={merge.created} />
      </PostLink>
    );
  }

  renderUnmerge() {
    const { allowUnmerge } = this.props;
    if (!allowUnmerge) {
      return null;
    }

    const { merge } = this.props;
    if (merge.mergeID) {
      // A into B, B into C
      // Cannot unmerge A out of C
      return null;
    } else if (!merge.allowUnmerge) {
      // A into B, B into C
      // Can unmerge B from C but then not A from B
      return null;
    }

    const { unmerging } = this.state;
    return (
      <div className="unmergeMenu">
        <div className="middot">&middot;</div>
        {unmerging ? (
          <Spinner />
        ) : (
          <Tappable onTap={this.onUnmerge}>
            <div className="unmerge">Unmerge</div>
          </Tappable>
        )}
      </div>
    );
  }

  renderUserAvatar() {
    const { merge } = this.props;
    if (!merge.member) {
      return null;
    }
    return <UserLockup user={merge.member} />;
  }

  render() {
    return (
      <div className="postMerge">
        {this.renderUserAvatar()}
        <div className="bottomContainer">
          {this.renderDescription()}
          {this.renderMerge()}
          <div className="mergeMenu">
            {this.renderTimestamp()}
            {this.renderUnmerge()}
            {this.renderError()}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadData: async (mergePostURLName, intoPost, toBoard, fromBoard) => {
      await Promise.all([
        dispatch(invalidateDashboardActivity()),
        dispatch(invalidatePostQueries()),
        dispatch(reloadBoard(toBoard.urlName)),
        dispatch(reloadBoard(fromBoard.urlName)),
        dispatch(reloadPost(intoPost)),
        dispatch(reloadPostActivity(intoPost)),
      ]);
      // load after reloading the rest to avoid having the same post duplicated into different boards.
      await dispatch(reloadPostByURLName(fromBoard, mergePostURLName));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      isIntercom: IsIntercomContext,
      openModal: OpenModalContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostMerge);
