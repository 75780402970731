import React from 'react';

import classnames from 'classnames';
import { AlertCircle, Zap } from 'lucide-react';

import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueAlert.module.scss';

type Props = {
  type: AlertTypes;
  actions: React.ReactNode;
  text: React.ReactNode;
};

export enum AlertTypes {
  warning = 'warning',
  info = 'info',
}

const AlertModes: Record<AlertTypes, { icon: React.ElementType }> = {
  [AlertTypes.warning]: {
    icon: () => (
      <div className={classnames(styles.badgeContainer, styles.warning)}>
        <AlertCircle height={24} width={24} className={styles.badgeIcon} />
      </div>
    ),
  },
  [AlertTypes.info]: {
    icon: () => (
      <div className={classnames(styles.badgeContainer, styles.info)}>
        <Zap height={16} width={16} className={styles.badgeIcon} />
      </div>
    ),
  },
};

const AdminQueueAlert = ({ type, actions, text }: Props) => {
  const { icon: Icon } = AlertModes[type];
  return (
    <div className={styles.adminQueueAlert}>
      <div className={styles.left}>
        <Icon />
        <div className={styles.text}>{text}</div>
      </div>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
};

export default AdminQueueAlert;
