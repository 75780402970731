import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import Strings from 'common/Strings';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import UppercaseHeader from 'common/UppercaseHeader';
import devURL from 'common/util/devURL';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminAuthSettings.scss';

const TwoSeconds = 2000;

class AdminAuthSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      authRedirectEnabled: PropTypes.bool,
      authRedirectURL: PropTypes.string,
      name: PropTypes.string,
    }),
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    router: PropTypes.object,
  };

  state = {
    enabledError: null,
    enabledSaving: false,
    savedURL: false,
    savingURL: false,
    savingURLError: null,
    testWorked: !!this.props.location.query.works,
    validURL: false,
  };

  constructor(props, context) {
    super(props, context);

    this.urlRef = React.createRef();
  }

  onSaveURL = () => {
    this.setState({
      savingURL: true,
    });
    const authRedirectURL = this.urlRef.current.getValue();
    const { reloadCompany } = this.props;
    AJAX.post(
      '/api/company/changeAuthSettings',
      {
        authRedirectEnabled: false,
        authRedirectURL,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            savingURL: false,
            savingURLError: Strings.miscError,
            testWorked: false,
          });
          return;
        }

        reloadCompany().then(() => {
          this.setState({
            savingURL: false,
            savedURL: true,
            testWorked: false,
            validURL: false,
          });
          setTimeout(() => {
            this.setState({
              savedURL: false,
            });
          }, TwoSeconds);
        });
      }
    );
  };

  onToggle = (authRedirectEnabled) => {
    const { enabledSaving } = this.state;
    if (enabledSaving) {
      return;
    }

    this.setState({
      enabledError: null,
      enabledSaving: true,
    });

    const { company } = this.props;
    AJAX.post(
      '/api/company/changeAuthSettings',
      {
        authRedirectEnabled,
        authRedirectURL: company.authRedirectURL,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            enabledError: Strings.miscError,
            enabledSaving: false,
          });
          return;
        }

        const { reloadCompany } = this.props;
        reloadCompany().then(() => {
          this.setState({
            enabledSaving: false,
          });
        });
      }
    );
  };

  onURLChange = (e) => {
    const value = e.target.value;
    this.setState({
      validURL: validateInput.primitives.url(value) || value === '',
    });
  };

  renderRedirectURLForm() {
    const {
      company: { authRedirectURL },
    } = this.props;
    const { savingURLError, savedURL, savingURL, validURL } = this.state;
    const isButtonDisabled = savedURL || !validURL;
    const error = savingURLError ? <div className="error">{savingURLError}</div> : null;
    return (
      <Form
        className="redirectURLForm"
        addEventsToDocument={false}
        onSubmit={this.onSaveURL}
        disableSubmit={isButtonDisabled || savingURL}>
        <div className="text">2. Set your redirect URL&nbsp;here:</div>
        <div className="inputContainer">
          <TextInput
            defaultValue={authRedirectURL}
            onChange={this.onURLChange}
            placeholder="https://example.com/login"
            ref={this.urlRef}
          />
          <Button
            disabled={isButtonDisabled}
            formButton={true}
            loading={savingURL}
            value={savedURL ? 'Saved!' : 'Save'}
          />
        </div>
        {error}
      </Form>
    );
  }

  renderTestFlow() {
    const {
      company: { authRedirectURL, name },
    } = this.props;
    const testLink = this.renderTestURL();

    return (
      <div className="testFlow">
        <div className="text">3. Test your redirect flow to confirm that it&nbsp;works.</div>
        <div className="warning">
          <UppercaseHeader>Note</UppercaseHeader>
          <div className="body">
            Before testing, make sure your {name} account uses the same email address as your Canny
            account. Otherwise you will be logged out of your admin&nbsp;account.
          </div>
        </div>
        <a href={testLink} rel="noopener" target="_blank">
          <Button disabled={!authRedirectURL} value="Test Redirect Flow" />
        </a>
      </div>
    );
  }

  renderTestURL() {
    const {
      company: { _id: companyID, authRedirectURL, subdomain },
    } = this.props;
    const redirectURL = devURL(
      'https://' + subdomain + '.canny.io/admin/settings/sso-redirect?works=true'
    );

    const hasQuery = authRedirectURL.indexOf('?') !== -1;
    if (!hasQuery) {
      return (
        authRedirectURL +
        queryString.stringify({
          companyID,
          redirect: redirectURL,
        })
      );
    }

    const query = authRedirectURL.slice(authRedirectURL.indexOf('?'));
    const params = queryString.parse(query);
    params.redirect = redirectURL;
    params.companyID = companyID;
    const baseURL = authRedirectURL.slice(0, authRedirectURL.indexOf('?'));
    return baseURL + queryString.stringify(params);
  }

  renderPushLive() {
    const {
      company: { authRedirectEnabled, authRedirectURL },
    } = this.props;
    const { enabledError, testWorked } = this.state;

    const message = authRedirectEnabled ? null : (
      <div className="text">
        4. After the test passes, you'll be able to enable the&nbsp;redirect.
      </div>
    );

    const error = enabledError ? <div className="error">{enabledError}</div> : null;

    return (
      <div className="pushLive">
        {message}
        <div className="toggleContainer">
          <Toggle
            disabled={!authRedirectURL || (!authRedirectEnabled && !testWorked)}
            onToggle={this.onToggle}
            value={authRedirectEnabled}
          />
          <div className="label">Enable Single Sign-On Redirect</div>
        </div>
        {error}
      </div>
    );
  }

  render() {
    return (
      <div className="adminAuthSettings">
        <Helmet title="SSO Redirect Settings | Canny" />
        <AdminSettingsHeader
          title="SSO Redirect"
          subheading="Manage how end users authenticate into your&nbsp;portal."
          learnMoreLink="https://help.canny.io/en/articles/1961021-setting-up-a-single-sign-on-sso-redirect"
        />
        <div className="content">
          <div className="text">
            By default, Canny uses its own authentication. This means if a user is not
            authenticated, we'll ask them to create a Canny&nbsp;account.
          </div>
          <div className="text">
            If you want to disable this, and only use accounts from your app, you're in the
            right&nbsp;place.
          </div>
          <div className="text">
            First, read our guide on{' '}
            <a href="https://help.canny.io/en/articles/1961021-setting-up-single-sign-on-sso-redirect">
              how to set up your redirect flow
            </a>
            . After you've done that, set your redirect URL&nbsp;here:
          </div>
          {this.renderRedirectURLForm()}
          {this.renderTestFlow()}
          {this.renderPushLive()}
        </div>
      </div>
    );
  }
}

// Use Canny Auth (disabled) (successfully test your redirect flow to disable)

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.ssoRedirect),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminAuthSettings);
