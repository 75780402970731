import React, { useContext } from 'react';

import classnames from 'classnames';
import { BarChart, Sparkles } from 'lucide-react';

import {
  type QueryParams,
  invalidateQueriesAndReload,
  loadMore,
} from 'common/actions/inboxItemQueries';
import AJAX from 'common/AJAX';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import { KeyIdentifiers } from 'common/KeyCodes';
import LazyLoadedImage from 'common/LazyLoadedImage';
import SelectableDataTable from 'common/SelectableDataTable';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AdminQueueNUX from './AdminQueueNUX';
import { SpamActionsCell, SpamDetailsCell } from './DataTableCells';
import { InboxType, type SpamRow as Row } from './types';

import SpamBoxChartIcon from 'img/icons/spambox-chart.svg';

import 'css/components/subdomain/admin/_AdminQueuePage.scss';

import type { InboxItem } from 'common/api/resources/inbox';
import type { Column } from 'common/DataTable';
import type { Dispatch } from 'redux-connect';

type ItemList = {
  items: InboxItem[];
  loading: boolean;
  loadingMore: boolean;
  error?: string;
  lastCreated?: Date;
  queryParams: QueryParams;
};

type ConnectProps = {
  inboxItemList: ItemList;
  invalidateQueriesAndReload: (queryParams: QueryParams) => Promise<void>;
  loadMore: (itemList: ItemList) => void;
};

type OwnProps = Record<string, never>;

type Props = ConnectProps & OwnProps;

const AdminQueueSpam = ({ inboxItemList, invalidateQueriesAndReload, loadMore }: Props) => {
  const showToast = useContext(ShowToastContext);

  const columns = [
    {
      id: 'score',
      align: 'left',
      header: 'Confidence',
      sortable: false,
      cell: (score) => {
        if (score >= 5) {
          return (
            <div className={classnames('spamFlag', 'high')}>
              <BarChart size={14} />
              <P className="label">High</P>
            </div>
          );
        }

        return (
          <div className={classnames('spamFlag', 'low')}>
            <LazyLoadedImage src={SpamBoxChartIcon} width={14} alt="Spambox chart icon" />
            <P className="label">Low</P>
          </div>
        );
      },
    } as Column<Row, 'score'>,
    {
      id: 'type',
      align: 'left',
      sortable: false,
      header: 'Type',
      cell: (type) => <P className="spamType">{type}</P>,
    } as Column<Row, 'type'>,
    {
      id: 'item',
      align: 'left',
      header: 'Details',
      sortable: false,
      fullWidth: true,
      cell: (item) => <SpamDetailsCell item={item} />,
    } as Column<Row, 'item'>,
    {
      id: 'actions',
      align: 'left',
      header: 'Quick actions',
      cell: (_, row) => <SpamActionsCell row={row} />,
    } as Column<Row, 'actions'>,
  ];

  const itemToRow = (item: InboxItem) => {
    // actions
    const approve = async () => {
      const response = await AJAX.post('/api/spam/unmark', {
        objectID: item._id,
        objectType: item.itemType,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          'not authorized': `You don't have the permissions take action on this item`,
          'invalid commentID': 'Comment is invalid',
          'invalid postID': 'Post is invalid',
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      showToast('Item marked as not spam', ToastTypes.success);
      await invalidateQueriesAndReload(inboxItemList.queryParams);
    };

    const deletePost = async () => {
      const response = await AJAX.post('/api/posts/delete', {
        postID: item._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          'not authorized': `You don't have the permissions take action on this item`,
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      showToast('Deleted post', ToastTypes.success);
      await invalidateQueriesAndReload(inboxItemList.queryParams);
    };

    const deleteComment = async () => {
      const response = await AJAX.post('/api/comments/delete', {
        commentID: item._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          'not authorized': `You don't have the permissions take action on this item`,
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      showToast('Deleted comment', ToastTypes.success);
      await invalidateQueriesAndReload(inboxItemList.queryParams);
    };

    const reject = async () => {
      const keyMap: Record<Row['type'], string> = {
        comment: 'commentID',
        post: 'postID',
      };

      const response = await AJAX.post('/api/spam/mark', {
        [keyMap[item.itemType]]: item._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          'not authorized': `You don't have the permissions take action on this item`,
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      showToast('Item marked as spam', ToastTypes.success);
      await invalidateQueriesAndReload(inboxItemList.queryParams);
    };

    return {
      id: item._id,
      score: item.spamScore,
      type: item.itemType as InboxType,
      item,
      actions: {
        approve,
        reject,
        delete: item.itemType === InboxType.comment ? deleteComment : deletePost,
      },
    };
  };

  const isReady = !inboxItemList.error && !inboxItemList.loading;
  const rows = isReady ? inboxItemList.items.map<Row>(itemToRow) : [];

  if (isReady && !rows.length) {
    return (
      <div className="adminQueuePage empty">
        <AdminQueueNUX
          icon={Sparkles}
          info={[
            'Canny automatically identifies spam so you can easily moderate your feedback.',
            'Check back regularly to take action on any detected spam.',
          ]}
          title="No spam detected!"
        />
      </div>
    );
  }

  return (
    <div className="adminQueuePage">
      <SelectableDataTable<Row>
        fullHeight
        className="adminQueueTable"
        cellAlignment="middle"
        loading={inboxItemList.loading}
        padding="medium"
        error={inboxItemList.error}
        rounded={false}
        hotkeys={{
          [KeyIdentifiers.N]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null =
              element?.querySelector('.queueActions > .approve') ?? null;
            button?.click();
          },
          [KeyIdentifiers.X]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null =
              element?.querySelector('.queueActions > .delete') ?? null;
            button?.click();
          },
          [KeyIdentifiers.B]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null =
              element?.querySelector('.queueActions > .reject') ?? null;
            button?.click();
          },
        }}
        pagination={{
          loading: inboxItemList.loadingMore,
          hasMore: !!(inboxItemList.items && inboxItemList.lastCreated),
          onLoadMore: () => loadMore(inboxItemList),
        }}
        columns={columns}
        rows={rows}
      />
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  invalidateQueriesAndReload: (queryParams: QueryParams) =>
    dispatch(invalidateQueriesAndReload(queryParams)),
  loadMore: (itemList: ItemList) => dispatch(loadMore(itemList)),
}))(AdminQueueSpam) as unknown as React.FC<OwnProps>;
