import React from 'react';

import classnames from 'classnames';

import Form from 'common/Form';
import ModalPortal from 'common/modals/ModalPortal';
import ButtonV2 from 'common/ui/ButtonV2';
import { H1 } from 'common/ui/Text';

import 'css/components/modals/_ModernConfirmModal.scss';

type Props = {
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  confirmText: string;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type?: 'primary' | 'destructive';
};

const ModernConfirmModal = ({
  children,
  className,
  confirmText,
  header,
  loading,
  onClose,
  onConfirm,
  type = 'primary',
}: Props) => {
  return (
    <ModalPortal
      className={classnames('modernConfirmModal', className)}
      onClose={onClose}
      closeOnClickAway
      allowEscape>
      <Form
        addEventsToDocument={true}
        disableSubmit={false}
        submitOnEnter={true}
        onSubmit={onConfirm}>
        <header className="header">
          <H1 variant="headingSm">{header}</H1>
        </header>
        <div className="body">{children}</div>
        <footer className="footer">
          <ButtonV2 size="medium" onClick={onClose} color="primary" variant="outlined">
            Cancel
          </ButtonV2>
          <ButtonV2
            size="medium"
            color={type === 'destructive' ? 'error' : 'primary'}
            loading={loading}
            type="submit"
            variant="contained">
            {confirmText}
          </ButtonV2>
        </footer>
      </Form>
    </ModalPortal>
  );
};

export default ModernConfirmModal;
