import publicConfig from 'common/core/publicConfig';

export default function devURL(url) {
  const domainOverride = publicConfig('hostnameOverride');

  // If running SSR tests
  if (__SSR_TEST_RUNNER__) {
    return url.replace(/canny\.io/i, 'canny.localhost:8108').replace('https://', 'http://');
  }

  // If running SSR server locally
  if (__SSR_LOCAL_SERVER__) {
    return url.replace(/canny\.io/i, 'canny.localhost:8008').replace('https://', 'http://');
  }

  // If running in a local dev environment
  if (__DEV__) {
    return url.replace(/canny\.io/i, 'canny.localhost:8007');
  }

  // If an override has been specified (eg, staging env)
  if (domainOverride) {
    return url.replace(/canny\.io/i, domainOverride);
  }

  return url;
}
