import React, { type ChangeEvent, useContext } from 'react';

import { Sparkles } from 'lucide-react';

import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import TextInput from 'common/inputs/TextInput';
import URLNameInput from 'common/inputs/URLNameInput';
import SwitchV2 from 'common/ui/SwitchV2';
import { H5, P, Span } from 'common/ui/Text';
import urlNamify from 'common/util/urlNamify';

import AdminOnboardingHeader, {
  AdminOnboardingHeaderTwo,
  AdminOnboardingSubHeader,
} from './AdminOnboardingHeader';
import { type UseCase } from './Types';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';
import type { Company } from 'common/api/endpoints/companies';

export type BoardInput = {
  name: string;
  url: string;
};

type UpsellBadgeProps = {
  text: string;
};

const UpsellBadge = ({ text }: UpsellBadgeProps) => {
  return (
    <div className="upsellBadge">
      <Span>{text}</Span>
    </div>
  );
};

type Props = {
  board: BoardInput;
  onChange: (useCase: UseCase) => void;
  onChangeBoard: (board: BoardInput) => void;
  useCase: UseCase;
};

const AdminOnboardingCreateBoard = ({ board, onChange, onChangeBoard, useCase }: Props) => {
  // context
  let boards = useContext(BoardsContext);
  const company = useContext<Company>(CompanyContext);

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!Array.isArray(boards)) {
    boards = [];
  }

  // helpers
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    const url = urlNamify(name);
    onChangeBoard({
      name,
      url,
    });
  };

  const firstBoard = boards[0];
  const { private: isPrivate, indexed } = useCase;
  const shouldUpsell = isPrivate || indexed;
  return (
    <div className="onboardingCreateBoard">
      <AdminOnboardingHeader>Let’s set up your first board!</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>
        Boards represent a specific project/topic where people can post, comment, and vote on ideas.
      </AdminOnboardingSubHeader>
      <div className="inputs">
        <TextInput
          defaultValue={board.name}
          disabled={!!firstBoard}
          inset="name"
          onChange={onChangeName}
          placeholder={firstBoard ? firstBoard.name : 'e.g. Feature Requests'}
        />
        <URLNameInput
          company={company}
          defaultValue={board.url}
          disabled={!!firstBoard}
          input={board.name}
          inset="url"
          onChange={(url: string) => {
            onChangeBoard({
              ...board,
              url,
            });
          }}
          placeholder={firstBoard ? firstBoard.urlName : null}
        />
      </div>
      <div className="line" />
      <AdminOnboardingHeaderTwo>Privacy Settings</AdminOnboardingHeaderTwo>
      <AdminOnboardingSubHeader>
        Public boards are visible to anyone with the link, while private boards have restricted
        access.
      </AdminOnboardingSubHeader>
      <div className="privateToggle">
        <P variant="bodyLg">I want this board to be private</P>
        <SwitchV2
          checked={isPrivate}
          onChange={(isPrivate: boolean) => onChange({ ...useCase, private: isPrivate })}
          size="medium"
        />
        <UpsellBadge text="Growth Plan" />
      </div>
      <div className="indexedToggle">
        <P variant="bodyLg">
          I want this board to be indexed by search engines (e.g. Google, Bing, etc.)
        </P>
        <SwitchV2
          checked={indexed}
          onChange={(indexed: boolean) => onChange({ ...useCase, indexed })}
          size="medium"
        />
        <UpsellBadge text="Starter Plan" />
      </div>
      {shouldUpsell && (
        <div className="upsellAlert">
          <div>
            <Sparkles className="alertSparkles" size={18} />
          </div>
          <div className="words">
            <H5>Canny Growth Plan</H5>
            <P variant="bodyLg">
              Your team will be put on a 14-day trial so you can try Growth features. No credit card
              required.
            </P>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminOnboardingCreateBoard;
