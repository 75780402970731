import React from 'react';

import Helmet from 'common/helmets/Helmet';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';
import { RoutePermissions } from 'common/util/permissions';

import 'css/components/subdomain/admin/AdminMemberSettings/_AdminMemberSettingsContainer.scss';

type MemberSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.team;
};

const SidebarLinks: MemberSidebarLink[] = [
  {
    label: 'People',
    urlSuffix: 'people',
  },
  {
    label: 'Teams',
    urlSuffix: 'teams',
  },
  {
    label: 'Roles',
    urlSuffix: 'roles',
  },
];

const AdminMemberSettingsContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="adminMemberSettingsContainer">
      <Helmet title="Team Settings | Canny" />
      <AdminSettingsHeader
        title="Admins"
        subheading="Manage who can access Canny's admin view and set&nbsp;permissions."
        learnMoreLink="https://help.canny.io/en/articles/3847317-admin-roles"
      />
      <div className="settingsContainer">
        <div className="settingsSidebar">
          {SidebarLinks.map(({ label, urlSuffix }) => (
            <AdminSettingsSidebarLink
              key={urlSuffix}
              requiredPermissions={RoutePermissions.adminSettings.team[urlSuffix]}
              to={`/admin/settings/team/${urlSuffix}`}>
              {label}
            </AdminSettingsSidebarLink>
          ))}
        </div>
        <div className="settingsContent">{children}</div>
      </div>
    </div>
  );
};

export default AdminMemberSettingsContainer;
