import React, { useContext, useState } from 'react';

import { Plus, Users } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import 'css/components/subdomain/admin/AdminMemberSettings/_AdminMemberTeamSettings.scss';

import { reloadCompany } from 'common/actions/company';
import EmptyStatePlaceholder from 'common/common/EmptyStatePlaceholder';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import AdminTeamModal from 'common/modals/AdminTeamModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import ButtonV2 from 'common/ui/ButtonV2';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import { createTeam, deleteTeam, editTeam } from './api';
import Team from './Team';

import type { Company, CompanyTeam, CompanyTeamMember } from 'common/api/endpoints/companies';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

const TeamSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editingTeam, setEditingTeam] = useState<CompanyTeam | null>(null);
  const [deletingTeam, setDeletingTeam] = useState<CompanyTeam | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onSave = async ({
    avatarEmoji,
    name,
    members,
    team,
  }: {
    avatarEmoji: string;
    name: string;
    members: CompanyTeamMember[];
    team: CompanyTeam | null;
  }) => {
    setError(null);
    setSaveLoading(true);
    const userIDs = members.map((member) => member.userID);
    const { error } = team
      ? await editTeam(team._id, avatarEmoji, name, userIDs)
      : await createTeam(avatarEmoji, name, userIDs);

    if (error) {
      setError(error.message);
      setSaveLoading(false);
      return;
    } else {
      await reloadCompany();
      setSaveLoading(false);
      setShowModal(false);
      setEditingTeam(null);
    }
  };

  const onDeleteClick = async (team: CompanyTeam, confirmDeletion = false) => {
    setDeleteLoading(true);
    if (confirmDeletion) {
      setDeletingTeam(team);
      setShowDeleteConfirmation(true);
    } else {
      await handleDelete(team);
    }
    setDeleteLoading(false);
  };

  const handleDelete = async (team: CompanyTeam) => {
    const { error } = await deleteTeam(team._id);

    if (error) {
      setError(error.message);
      return;
    } else {
      await reloadCompany();
      onModalClose();
    }
  };

  const onDeleteConfirmed = async () => {
    if (!deletingTeam) {
      return;
    }

    await handleDelete(deletingTeam);
    setDeletingTeam(null);
    setShowDeleteConfirmation(false);
  };

  const onCreateClick = () => {
    setEditingTeam(null);
    setError(null);
    setShowModal(true);
  };

  const onEditClick = (team: CompanyTeam) => () => {
    setEditingTeam(team);
    setShowModal(true);
  };

  const onModalClose = () => {
    setError(null);
    setEditingTeam(null);
    setShowModal(false);
  };

  const { teams } = company;

  return (
    <div className="adminMemberTeamSettings">
      <section className="content">
        <header>
          <h1>Teams</h1>
          <ButtonV2 startIcon={Plus} size="medium" color="primary" onClick={onCreateClick}>
            Create new team
          </ButtonV2>
        </header>
        {teams.length ? (
          <div className="teams">
            {teams.map((team) => (
              <Team
                key={team._id}
                onEditClick={onEditClick(team)}
                onDeleteClick={() => onDeleteClick(team, true)}
                {...team}
              />
            ))}
          </div>
        ) : (
          <EmptyStatePlaceholder
            title="No teams yet"
            description="Create groups of people that you can assign to boards and posts."
            icon={Users}>
            <ButtonV2
              variant="plain"
              size="medium"
              color="primary"
              startIcon={Plus}
              onClick={onCreateClick}>
              Create team
            </ButtonV2>
          </EmptyStatePlaceholder>
        )}
        {error && !showModal ? <span className="error">{error}</span> : null}
      </section>
      {showModal && (
        <AdminTeamModal
          deleteLoading={deleteLoading}
          error={error}
          onClose={onModalClose}
          onDelete={onDeleteClick}
          onSave={onSave}
          saveLoading={saveLoading}
          setError={setError}
          team={editingTeam}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmModal
          closeModal={() => {
            setDeletingTeam(null);
            setShowDeleteConfirmation(false);
          }}
          message={
            <p>
              Are you sure you want to delete the "{deletingTeam?.name}" team? This can't be undone.
            </p>
          }
          onConfirm={onDeleteConfirmed}
          submitButtonType="redButton"
          submitButtonValue="Delete team"
          tint={false}
          useModalPortal={true}
        />
      )}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.team.teams),
    '/admin/settings'
  )
)(TeamSettings) as unknown as React.FC<OwnProps>;
