import React, { useEffect, useRef, useState } from 'react';

import Picker from '@emoji-mart/react';

import Portal from 'common/common/Portal';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import Spinner from 'common/Spinner';
import ButtonV2 from 'common/ui/ButtonV2';

import 'css/components/inputs/_EmojiPicker.scss';

type EmojiPickedEvent = {
  aliases: string[];
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  skin: number;
  unified: string;
};

type Props = {
  onEmojiSelect: (unicode: string) => void;
  value: string;
};

const EmojiPicker = ({ onEmojiSelect, value }: Props) => {
  const portalRef = useRef<Portal>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emojiData, setEmojiData] = useState<unknown>();

  useBackgroundClick(() => {
    setShowPicker(false);
  }, [portalRef, buttonRef]);

  const handleEmojiSelect = (e: EmojiPickedEvent) => {
    onEmojiSelect(e.native);
    setShowPicker(false);
  };

  const fetchEmojiData = async () => {
    setLoading(true);
    const response = await fetch(
      'https://cdn.jsdelivr.net/npm/@emoji-mart/data@1.1.2/sets/14/native.json'
    );
    const data = await response.json();
    setLoading(false);
    setEmojiData(data);
  };

  useEffect(() => {
    fetchEmojiData();
  }, []);

  return (
    <>
      <ButtonV2
        ref={buttonRef}
        onClick={() => setShowPicker(true)}
        size="medium"
        variant="outlined">
        {showPicker && loading ? <Spinner /> : value}
      </ButtonV2>
      {showPicker && emojiData && (
        <Portal
          className="emojiPickerPortal"
          align="start"
          position="bottom"
          ref={portalRef}
          relativeToRef={buttonRef}>
          <Picker data={emojiData} onEmojiSelect={handleEmojiSelect} />
        </Portal>
      )}
    </>
  );
};

export default EmojiPicker;
