import React, { Component } from 'react';

import PropTypes from 'prop-types';

import DatePicker from 'common/common/DatePicker';
import Tooltip from 'common/common/Tooltip';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import Months from 'common/Months';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import nbspLastSpace from 'common/util/nbspLastSpace';

import AdminChangelogDetailsInput from './AdminChangelogDetailsInput';

import 'css/components/subdomain/admin/_AdminChangelogCreateComposer.scss';

export default class AdminChangelogCreateComposer extends Component {
  static propTypes = {
    detailsValue: PropTypes.string,
    entry: PropTypes.object,
    entrySaved: PropTypes.bool,
    needsPublish: PropTypes.bool,
    notifyEnabled: PropTypes.bool,
    notify: PropTypes.bool,
    onDatePicked: PropTypes.func,
    onDetailsChange: PropTypes.func,
    onPublishTapped: PropTypes.func,
    onNotifyChange: PropTypes.func,
    onSaveEntry: PropTypes.func,
    onTitleChange: PropTypes.func,
    onUnpublishEntry: PropTypes.func,
    onUnscheduleEntry: PropTypes.func,
    publishing: PropTypes.bool,
    savingEntry: PropTypes.bool,
    scheduling: PropTypes.bool,
    selectedTypes: PropTypes.array,
    unpublishing: PropTypes.bool,
    unsavedWork: PropTypes.bool,
    unscheduling: PropTypes.bool,
  };

  state = {
    showScheduling: false,
  };

  onDatePicked = (timestamp) => {
    this.setState({ showScheduling: false });
    this.props.onDatePicked(timestamp);
  };

  onToggleScheduling = () => {
    this.setState({
      showScheduling: !this.state.showScheduling,
    });
  };

  renderNotifyButton() {
    const { entry, notify, notifyEnabled, onNotifyChange } = this.props;
    if (!notifyEnabled) {
      return null;
    }

    const isDisabled = !!entry?.firstPublishedAt;
    return (
      <Tooltip
        delay={300}
        containerClassName="notifyTooltip"
        position="top"
        value={nbspLastSpace(
          isDisabled
            ? 'Email notifications can only be sent once'
            : 'Subscribed users will be notified by\u00a0email after publishing'
        )}>
        <CheckboxWithLabel
          checked={!isDisabled && notify}
          size="medium"
          disabled={isDisabled}
          aria-label="Notify subscribers by email"
          onChange={onNotifyChange}>
          Notify subscribers
        </CheckboxWithLabel>
      </Tooltip>
    );
  }

  renderDraftStatus() {
    const { entry, entrySaved, savingEntry, selectedTypes } = this.props;
    if (!selectedTypes || selectedTypes.length === 0) {
      return <div className="draftStatus noTypes">Please select at least one type.</div>;
    }

    if (!entrySaved && !savingEntry) {
      return <div className="draftStatus empty" />;
    }

    if (savingEntry) {
      if (entry && entry.status === 'published') {
        return <div className="draftStatus saving">Saving entry&hellip;</div>;
      }

      return <div className="draftStatus saving">Saving draft&hellip;</div>;
    }

    return <div className="draftStatus saved">Saved just now</div>;
  }

  renderComposerButtons() {
    const {
      entry,
      needsPublish,
      onPublishTapped,
      onSaveEntry,
      onUnpublishEntry,
      onUnscheduleEntry,
      publishing,
      savingEntry,
      scheduling,
      selectedTypes,
      unpublishing,
      unsavedWork,
      unscheduling,
    } = this.props;
    const { showScheduling } = this.state;
    const noTypes = !selectedTypes || selectedTypes.length === 0;

    if (!entry || entry.status === 'draft') {
      return (
        <div className="composerButtons">
          <Button
            buttonType="cannyButton"
            className="publishButton"
            disabled={
              !entry || needsPublish || noTypes || savingEntry || scheduling || showScheduling
            }
            loading={needsPublish || publishing}
            onTap={onPublishTapped}
            value="Publish Now"
          />
          <Button
            buttonType="cannyButton"
            className="scheduleButton"
            disabled={!entry || needsPublish || noTypes || savingEntry || showScheduling}
            loading={scheduling}
            onTap={this.onToggleScheduling}
            value={<div className="icon icon-schedule" />}
          />
          {showScheduling ? (
            <DatePicker
              includeTime={true}
              onClose={this.onToggleScheduling}
              onSubmit={this.onDatePicked}
            />
          ) : null}
        </div>
      );
    } else if (entry.status === 'scheduled') {
      const date = new Date(entry.scheduledFor);
      const militaryHour = date.getHours();
      const hour = militaryHour === 0 ? 12 : militaryHour > 12 ? militaryHour - 12 : militaryHour;
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const pm = militaryHour >= 12;
      const month = Months[date.getMonth()].substr(0, 3);
      const day = date.getDate();
      const year = date.getFullYear();
      return (
        <div className="composerButtons">
          <div className="scheduledFor">
            Scheduled: {hour}:{minutes}
            {pm ? 'pm' : 'am'} on {month} {day}, {year}
          </div>
          {unscheduling ? (
            <Spinner />
          ) : (
            <Tappable onTap={onUnscheduleEntry}>
              <div className="unschedule">Cancel</div>
            </Tappable>
          )}
        </div>
      );
    } else if (entry.status === 'published') {
      if (unsavedWork) {
        return (
          <div className="composerButtons">
            <Button
              buttonType="cannyButton"
              disabled={!entry || noTypes || savingEntry || scheduling || showScheduling}
              loading={savingEntry}
              onTap={onSaveEntry}
              value="Publish Edits"
            />
          </div>
        );
      } else {
        const date = new Date(entry.publishedAt);
        const militaryHour = date.getHours();
        const hour = militaryHour === 0 ? 12 : militaryHour > 12 ? militaryHour - 12 : militaryHour;
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const pm = militaryHour >= 12;
        const month = Months[date.getMonth()].substr(0, 3);
        const day = date.getDate();
        const year = date.getFullYear();
        return (
          <div className="composerButtons">
            <div className="publishedAt">
              Published: {hour}:{minutes}
              {pm ? 'pm' : 'am'}&nbsp;on&nbsp;{month}&nbsp;{day},&nbsp;{year}
            </div>
            {unpublishing ? (
              <Spinner />
            ) : (
              <Tappable onTap={onUnpublishEntry}>
                <div className="unpublish">Revert&nbsp;to&nbsp;draft</div>
              </Tappable>
            )}
          </div>
        );
      }
    }
  }

  renderComposer() {
    const { detailsValue, entry, needsPublish, publishing, onTitleChange, onDetailsChange } =
      this.props;

    return (
      <div className="composer">
        <div className="topContainer">
          <div className="titleContainer">
            <AutoResizeTextarea
              autoFocus={true}
              className="titleInput"
              defaultValue={entry ? entry.title : ''}
              disabled={needsPublish || publishing}
              onChange={onTitleChange}
              placeholder="Entry title"
            />
          </div>
          <div className="divider" />
          <AdminChangelogDetailsInput
            defaultValue={detailsValue}
            disabled={needsPublish || publishing}
            onChange={onDetailsChange}
            hideEditModeButtons
          />
        </div>
        <div className="bottomContainer">
          {this.renderDraftStatus()}
          <div className="rightButtons">
            {this.renderNotifyButton()}
            {this.renderComposerButtons()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div className="adminChangelogCreateComposer">{this.renderComposer()}</div>;
  }
}
