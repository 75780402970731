import React, { useState } from 'react';

import { Trash } from 'lucide-react';

import 'css/components/modals/_AdminTeamModal.scss';
import EmojiPicker from 'common/inputs/EmojiPicker';
import TextInput from 'common/inputs/TextInput';
import ButtonV2 from 'common/ui/ButtonV2';

import TeamMembers from './TeamMembers';
import ModernModal from '../ModernModal';

import type { CompanyTeam, CompanyTeamMember, Member } from 'common/api/endpoints/companies';

const defaultEmoji = '🆕';

type Props = {
  deleteLoading: boolean;
  error: string | null;
  onClose: () => void;
  onDelete: (team: CompanyTeam) => Promise<void>;
  onSave: ({
    avatarEmoji,
    name,
    members,
    team,
  }: {
    avatarEmoji: string;
    name: string;
    members: CompanyTeamMember[];
    team: CompanyTeam | null;
  }) => Promise<void>;
  saveLoading: boolean;
  setError: (error: string | null) => void;
  team: CompanyTeam | null;
};

const AdminTeamModal = ({
  deleteLoading,
  error,
  onClose,
  onDelete,
  onSave,
  saveLoading,
  setError,
  team,
}: Props) => {
  const [teamName, setTeamName] = useState(team?.name ?? '');
  const [teamEmoji, setTeamEmoji] = useState(team?.avatarEmoji ?? defaultEmoji);
  const [teamMembers, setTeamMembers] = useState<CompanyTeamMember[]>(team?.members ?? []);

  const handleSave = () => {
    if (saveLoading || deleteLoading) {
      return;
    }
    onSave({ avatarEmoji: teamEmoji, name: teamName.trim(), members: teamMembers, team });
  };

  const handleDelete = () => {
    if (saveLoading || deleteLoading || !team) {
      return;
    }
    onDelete(team);
  };

  const addTeamMember = (member: Member) => {
    // check user not already in team
    if (teamMembers.some((tm) => tm.userID === member._id)) {
      setError('User is already in team.');
      return;
    }

    const { roleID: _, _id, ...teamMember } = member;
    setTeamMembers((prev) => [...prev, { ...teamMember, userID: _id }]);
    setError(null);
  };

  const removeTeamMember = (userID: string) => {
    setTeamMembers((prev) => prev.filter((member) => member.userID !== userID));
    setError(null);
  };

  return (
    <ModernModal
      overlayClassName="adminTeamModalPortal"
      onClose={onClose}
      header={team ? `Manage ${team.name}` : 'Create New Team'}
      sections={[
        <>
          <div className="inputs">
            <div className="nameSection">
              <h1 className="sectionHeader">Team</h1>
              <div className="row">
                <EmojiPicker value={teamEmoji} onEmojiSelect={(v) => setTeamEmoji(v)} />
                <TextInput
                  className="teamNameInput"
                  type="text"
                  onChange={(e) => setTeamName(e.target.value)}
                  placeholder="e.g. Marketing"
                  value={teamName}
                />
              </div>
            </div>
            <TeamMembers
              members={teamMembers}
              onCreate={addTeamMember}
              onDelete={removeTeamMember}
            />
            {error ? <span className="error">{error}</span> : null}
          </div>
        </>,
      ]}
      footer={
        <>
          {team ? (
            <ButtonV2
              className="deleteTeamButton"
              size="medium"
              color="error"
              variant="plain"
              loading={deleteLoading}
              onClick={handleDelete}
              startIcon={Trash}>
              Delete team
            </ButtonV2>
          ) : null}
          <ButtonV2 size="medium" variant="outlined" onClick={onClose}>
            Cancel
          </ButtonV2>
          <ButtonV2 size="medium" onClick={handleSave} loading={saveLoading}>
            Save
          </ButtonV2>
        </>
      }
    />
  );
};

export default AdminTeamModal;
