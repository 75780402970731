import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import actionItemQueries from 'common/reducers/actionItemQueries';
import asanaSettings from 'common/reducers/asanaSettings';
import asanaTaskSuggestions from 'common/reducers/asanaTaskSuggestions';
import automations from 'common/reducers/automations';
import azureDevopsSettings from 'common/reducers/azureDevopsSettings';
import azureDevopsWorkItemSuggestions from 'common/reducers/azureDevopsWorkItemSuggestions';
import boards from 'common/reducers/boards';
import changelog from 'common/reducers/changelog';
import changelogEntries from 'common/reducers/changelogEntries';
import changelogEntryQueries from 'common/reducers/changelogEntryQueries';
import clickupRules from 'common/reducers/clickupRules';
import clickupTaskSuggestions from 'common/reducers/clickupTaskSuggestions';
import company from 'common/reducers/company';
import cookies from 'common/reducers/cookies';
import customFields from 'common/reducers/customFields';
import customPostFields from 'common/reducers/customPostFields';
import dashboardActivity from 'common/reducers/dashboardActivity';
import discordSettings from 'common/reducers/discordSettings';
import githubIssueSuggestions from 'common/reducers/githubIssueSuggestions';
import gongSettings from 'common/reducers/gongSettings';
import gsuiteGroups from 'common/reducers/gsuiteGroups';
import helpscoutSettings from 'common/reducers/helpscoutSettings';
import inboxItemQueries from 'common/reducers/inboxItemQueries';
import issueSuggestions from 'common/reducers/issueSuggestions';
import jiraRules from 'common/reducers/jiraRules';
import linearIssueSuggestions from 'common/reducers/linearIssueSuggestions';
import linearSettings from 'common/reducers/linearSettings';
import mentionSuggestions from 'common/reducers/mentionSuggestions';
import notifications from 'common/reducers/notifications';
import postDraft from 'common/reducers/postDraft';
import postQueries from 'common/reducers/postQueries';
import posts from 'common/reducers/posts';
import postsActivity from 'common/reducers/postsActivity';
import postSuggestions from 'common/reducers/postSuggestions';
import queueItemQueries from 'common/reducers/queueItemQueries';
import referralSettings from 'common/reducers/referralSettings';
import roadmap from 'common/reducers/roadmap';
import roadmapPosts from 'common/reducers/roadmapPosts';
import roadmaps from 'common/reducers/roadmaps';
import sharedRoadmap from 'common/reducers/sharedRoadmap';
import sharedRoadmapPosts from 'common/reducers/sharedRoadmapPosts';
import thirdPartyCompanies from 'common/reducers/thirdPartyCompanies';
import thirdPartyCompanyFeatureRequestsQueries from 'common/reducers/thirdPartyCompanyFeatureRequestsQueries';
import thirdPartyCompanyPosts from 'common/reducers/thirdPartyCompanyPosts';
import thirdPartyCompanyQueries from 'common/reducers/thirdPartyCompanyQueries';
import tpcForFilters from 'common/reducers/tpcForFilters';
import userPosts from 'common/reducers/userPosts';
import userQueries from 'common/reducers/userQueries';
import userReactions from 'common/reducers/userReactions';
import users from 'common/reducers/users';
import userSuggestions from 'common/reducers/userSuggestions';
import viewer from 'common/reducers/viewer';
import voteQueries from 'common/reducers/voteQueries';
import voters from 'common/reducers/voters';

export default combineReducers({
  actionItemQueries,
  asanaSettings,
  asanaTaskSuggestions,
  automations,
  azureDevopsSettings,
  azureDevopsWorkItemSuggestions,
  boards,
  changelog,
  changelogEntries,
  changelogEntryQueries,
  clickupRules,
  clickupTaskSuggestions,
  company,
  cookies,
  customFields,
  customPostFields,
  dashboardActivity,
  discordSettings,
  githubIssueSuggestions,
  gongSettings,
  gsuiteGroups,
  helpscoutSettings,
  inboxItemQueries,
  issueSuggestions,
  jiraRules,
  linearIssueSuggestions,
  linearSettings,
  mentionSuggestions,
  notifications,
  postDraft,
  posts,
  postsActivity,
  postQueries,
  postSuggestions,
  queueItemQueries,
  sharedRoadmap,
  sharedRoadmapPosts,
  reduxAsyncConnect,
  referralSettings,
  roadmap,
  roadmaps,
  roadmapPosts,
  routing: routerReducer,
  thirdPartyCompanies,
  thirdPartyCompanyFeatureRequestsQueries,
  tpcForFilters,
  thirdPartyCompanyPosts,
  thirdPartyCompanyQueries,
  userPosts,
  userQueries,
  users,
  userReactions,
  userSuggestions,
  viewer,
  voteQueries,
  voters,
});
