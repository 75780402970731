import React, { Component } from 'react';

import classnames from 'classnames';
import { ArrowUpCircle, Building, FormInput, User } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadRoadmapPosts } from 'common/actions/roadmapPosts';
import { reloadRoadmap } from 'common/actions/roadmaps';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { RevenueTimeframes } from 'common/company/RevenueHelpers';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, ParamsContext } from 'common/containers/RouterContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import LazyLoadedImage from 'common/LazyLoadedImage';
import {
  CalculationFieldTypes,
  CalculationFunctions,
  CalculationOpportunityFactors,
  CalculationStaticFactors,
  CalculationVoteFactors,
} from 'common/prioritization/CalculationFactorTypes';
import Tappable from 'common/Tappable';
import ButtonV2 from 'common/ui/ButtonV2';
import { getQueryFilterParams } from 'common/util/filterPosts';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import AdminRoadmapFactorRow from './AdminRoadmapFactorRow';

import HubspotLogo from 'img/hubspot-logo-small.png';
import SalesforceLogo from 'img/salesforce-logo-small.png';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapEditFormulaModal.scss';

const EmptyFactor = {
  deleted: false,
  name: null,
  type: null,
  weight: null,
  updated: false,
  errors: null,
  effort: false,
};

class AdminRoadmapEditFormulaModal extends Component {
  static propTypes = {
    company: PropTypes.shape({
      activeIntegrations: PropTypes.shape({
        hubspot: PropTypes.bool,
        salesforce: PropTypes.bool,
      }),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    reloadRoadmapPosts: PropTypes.func.isRequired,
    reloadRoadmap: PropTypes.func.isRequired,
    roadmap: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  state = {
    error: null,
    impacts: [],
    efforts: [],
    saving: false,
  };

  componentDidMount() {
    const { roadmap } = this.props;

    // 'impact' and 'effort' factors are split on mount
    // we update them as impact and as effort
    // when we save the roadmap, we put them back together as factors
    this.setState({
      impacts: roadmap.factors.filter(({ effort }) => !effort).concat(EmptyFactor),
      efforts: roadmap.factors.filter(({ effort }) => effort),
    });
  }

  deleteImpact = (factor, index) => {
    const { roadmap } = this.props;
    const { impacts } = this.state;
    const updatedFactors = [...impacts];

    updatedFactors[index] = {
      ...impacts[index],
      errors: null,
      deleted: true,
      updated: true,
    };

    // revert invalid changes on name and weight to avoid failing when deleting.
    const updatedFactor = updatedFactors[index];
    if (updatedFactor._id) {
      const originalFactor =
        roadmap.factors.find((impact) => impact._id === updatedFactor._id) ?? {};
      updatedFactor.name = originalFactor.name;
      updatedFactor.weight = originalFactor.weight;
    }

    this.setState({ impacts: updatedFactors });
  };

  updateEffort = (factor, index, factorFields) => {
    const { efforts } = this.state;

    const updatedEfforts = [...efforts];

    updatedEfforts[index] = {
      ...efforts[index],
      ...factorFields,
      updated: true,
    };

    // validate effort factor fields
    updatedEfforts[index].errors = this.getErrors(efforts, updatedEfforts[index], index);

    this.setState({ efforts: updatedEfforts });
  };

  updateImpact = (factor, index, factorFields) => {
    const { impacts } = this.state;

    const updatedImpacts = [...impacts];
    updatedImpacts[index] = {
      ...impacts[index],
      ...factorFields,

      // merge calculation
      ...(impacts[index]?.calculation && {
        calculation: {
          ...impacts[index].calculation,
          ...factorFields.calculation,
        },
      }),
      updated: true,
    };

    // validate impact factor fields
    updatedImpacts[index].errors = this.getErrors(impacts, updatedImpacts[index], index);

    this.setState({ impacts: updatedImpacts });
  };

  getErrors = (factors, factor, factorIndex) => {
    const isWeightInvalid =
      !Number.isInteger(factor.weight) || factor.weight < 0 || factor.weight > 100;
    const isNameInvalid = factor.name?.trim().length <= 0 || factor.name?.trim().length >= 30;
    const isNameDuplicated =
      factors.filter((f, index) => {
        // skip the same factor
        if (factorIndex === index) {
          return false;
        }

        return !f.deleted && f.name?.trim() === factor.name?.trim();
      }).length > 0;

    if (isWeightInvalid || isNameInvalid || isNameDuplicated) {
      return {
        name: isNameInvalid || isNameDuplicated,
        weight: isWeightInvalid,
      };
    }

    return null;
  };

  areImpactsUpdated = () => {
    const { impacts } = this.state;
    return impacts.some((impact) => impact.updated);
  };

  isSaveEnabled = () => {
    const { efforts, impacts } = this.state;
    const factors = [...efforts, ...impacts];
    const areUpdatedOrNew = factors.some((impact) => impact.updated || impact.new);
    const areErrorFree = factors.every((impact) => !impact.errors);
    return areUpdatedOrNew && areErrorFree;
  };

  getFactorsPayload = () => {
    const { impacts, efforts } = this.state;

    const factors = [...impacts, ...efforts];

    return factors
      .filter((factor) => factor.name?.trim())
      .map((factor) => {
        const payloadFactor = {
          delete: false,
          new: false,
          name: factor.name.trim(),
          type: factor.type,
          weight: factor.weight,
          effort: factor.effort,
        };

        if (factor.calculation) {
          payloadFactor.calculation = factor.calculation;
        }

        if (factor._id) {
          return {
            ...payloadFactor,
            _id: factor._id,
            delete: !!factor.deleted,
          };
        }

        if (factor.deleted) {
          // if factor didn't exist and now is deleted, it shouldn't be created
          return null;
        }

        return {
          ...payloadFactor,
          new: true,
        };
      })
      .filter(Boolean);
  };

  onSaveFormula = async () => {
    const {
      boards,
      company,
      location,
      onClose,
      params,
      reloadRoadmapPosts,
      reloadRoadmap,
      roadmap,
    } = this.props;

    this.setState({ saving: true });

    const response = await AJAX.post('/api/roadmaps/factors/update', {
      factors: this.getFactorsPayload(),
      roadmapID: roadmap._id,
    });

    const errorMessage = `There's something wrong with the impact factors, make sure you're entering valid values.`;
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'invalid input': errorMessage,
        'invalid factors': errorMessage,
      },
    });

    if (error) {
      this.setState({ error: error.message, saving: false });
      return;
    }

    const board = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(board, company, location, {}, roadmap);

    this.setState({ saving: false }, async () => {
      await Promise.all([reloadRoadmapPosts(queryParams), reloadRoadmap(roadmap)]);
      onClose();
    });
  };

  onAddImpact = (factor, factorOption) => {
    const { company } = this.props;
    const { impacts } = this.state;

    const growthFactors = [
      CalculationVoteFactors.niceToHaveUserVotes,
      CalculationVoteFactors.importantUserVotes,
      CalculationVoteFactors.mustHaveUserVotes,
    ];

    if (growthFactors.includes(factorOption) && !company?.features?.voteWeights) {
      return;
    }

    this.setState({
      impacts: impacts
        .map((factor) => {
          if (factor !== EmptyFactor) {
            return factor;
          } else if (factorOption in CalculationOpportunityFactors) {
            const FactorOptionsPrettyNames = company.activeIntegrations.salesforce
              ? {
                  [CalculationOpportunityFactors.openOpportunities]: 'Open opportunities',
                  [CalculationOpportunityFactors.wonOpportunities]: 'Closed won opportunities',
                  [CalculationOpportunityFactors.lostOpportunities]: 'Closed lost opportunities',
                }
              : {
                  [CalculationOpportunityFactors.openOpportunities]: 'Open deals',
                  [CalculationOpportunityFactors.wonOpportunities]: 'Closed won deals',
                  [CalculationOpportunityFactors.lostOpportunities]: 'Closed lost deals',
                };

            const newImpact = {
              ...factor,
              name: FactorOptionsPrettyNames[factorOption] ?? null,
              calculation: {
                field: factorOption,
                fieldType: CalculationFieldTypes.default,
                function: CalculationFunctions.count,
              },
              new: true,
              type: 'calculation',
              weight: 1,
            };

            newImpact.errors = this.getErrors(impacts, newImpact);

            return newImpact;
          } else if (factorOption in CalculationVoteFactors) {
            const prettyNames = {
              [CalculationVoteFactors.niceToHaveUserVotes]: 'User votes - Nice to have',
              [CalculationVoteFactors.importantUserVotes]: 'User votes - Important',
              [CalculationVoteFactors.mustHaveUserVotes]: 'User votes - Must have',
              [CalculationVoteFactors.userVotes]: 'User votes - All',
              [CalculationVoteFactors.companyVotes]: 'Company votes',
            };

            const newImpact = {
              ...factor,
              name: prettyNames[factorOption],
              calculation: {
                field: factorOption,
                fieldType: CalculationFieldTypes.default,
                function: CalculationFunctions.count,
              },
              new: true,
              type: 'calculation',
              weight: 1,
            };

            newImpact.errors = this.getErrors(impacts, newImpact);

            return newImpact;
          } else if (factorOption in CalculationStaticFactors) {
            const prettyNames = {
              [CalculationStaticFactors.monthlySpend]: 'MRR',
              [CalculationStaticFactors.annualSpend]: 'ARR',
            };

            const newImpact = {
              ...factor,
              name: prettyNames[factorOption],
              calculation: {
                field: factorOption,
                fieldType: CalculationFieldTypes.default,
                function: CalculationFunctions.sum,
              },
              new: true,
              type: 'calculation',
              weight: 1,
            };

            newImpact.errors = this.getErrors(impacts, newImpact);

            return newImpact;
          } else if (factorOption === 'manual') {
            return {
              ...factor,
              name: null,
              type: 'numberToTen',
              weight: 1,
            };
          }

          const userField = company.customFields.user.find(
            (customField) => customField._id === factorOption
          );
          if (userField) {
            const newImpact = {
              ...factor,
              name: userField.name,
              new: true,
              calculation: {
                fieldID: factorOption,
                fieldType: CalculationFieldTypes.customField,
                function: CalculationFunctions.count,
              },
              type: 'calculation',
              weight: 1,
            };

            newImpact.errors = this.getErrors(impacts, newImpact);

            return newImpact;
          }

          const companyField = company.customFields.company.find(
            (customField) => customField._id === factorOption
          );
          if (companyField) {
            const newImpact = {
              ...factor,
              name: companyField.name,
              new: true,
              calculation: {
                fieldID: factorOption,
                fieldType: CalculationFieldTypes.customField,
                function: CalculationFunctions.count,
              },
              type: 'calculation',
              weight: 1,
            };

            newImpact.errors = this.getErrors(impacts, newImpact);

            return newImpact;
          }
        })
        .concat(EmptyFactor),
    });
  };

  onAddEffort = () => {
    const { efforts } = this.state;

    this.setState({
      efforts: [
        ...efforts,
        {
          name: '',
          type: 'numberToTen',
          weight: 1,
          effort: true,
        },
      ],
    });
  };

  onDeleteEffort = (index, effort) => {
    const { efforts } = this.state;
    const updatedEfforts = [...efforts];

    updatedEfforts[index] = {
      ...efforts[index],
      errors: null,
      deleted: true,
      updated: true,
    };

    this.setState({
      efforts: updatedEfforts,
    });
  };

  renderEffortFactor() {
    const { efforts } = this.state;
    return (
      <div className="factorsTable effortType">
        <div className="factorsTableHeader">
          <div className="row header">
            <div className="cell name">Effort factor</div>
            <div className="cell type">Type</div>
            <div className="cell weight">Weight</div>
          </div>
        </div>
        <div className="factorsTableBody">
          {efforts.map((effort, index) => {
            return (
              <AdminRoadmapFactorRow
                deletable={true}
                effortType={true}
                factor={effort}
                key={effort._id ? `${effort._id}` : index}
                updateFactor={(factor, factorFields) =>
                  this.updateEffort(factor, index, factorFields)
                }
                deleteFactor={() => this.onDeleteEffort(index, effort)}
              />
            );
          })}
          <div className="newFactor">
            <div className="adminRoadmapFactorRow row container">
              <div className="cell name">
                <ButtonV2 variant="plain" className="newEffortButton" onClick={this.onAddEffort}>
                  Add new...
                </ButtonV2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (error) {
      return <div className="error">{error}</div>;
    }
  }

  renderRow = (factor, index) => {
    return (
      <AdminRoadmapFactorRow
        deletable={factor !== EmptyFactor}
        deleteFactor={(factor) => this.deleteImpact(factor, index)}
        factor={factor}
        key={factor._id ? `${factor._id}` : index}
        updateFactor={(factor, factorFields) => this.updateImpact(factor, index, factorFields)}
      />
    );
  };

  renderNewImpact() {
    const { company } = this.props;
    const options = [
      {
        name: 'manual',
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <FormInput className="icon" size={14} />
              <div className="label">Manual</div>
            </div>
          </div>
        ),
      },
      {
        name: CalculationVoteFactors.userVotes,
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <ArrowUpCircle className="icon" size={14} />
              <div className="label">User votes - All</div>
            </div>
          </div>
        ),
      },
      {
        name: CalculationVoteFactors.niceToHaveUserVotes,
        render: (
          <div className={classnames('optionElement', { upsell: !company?.features?.voteWeights })}>
            <div className="optionLabel">
              <ArrowUpCircle className="icon" size={14} />
              <div className="label">User votes - Nice to have</div>
            </div>
            <Pill className="upsellPill" pillStyle={DefaultPillStyles.canny}>
              Upgrade
            </Pill>
          </div>
        ),
      },
      {
        name: CalculationVoteFactors.importantUserVotes,
        render: (
          <div className={classnames('optionElement', { upsell: !company?.features?.voteWeights })}>
            <div className="optionLabel">
              <ArrowUpCircle className="icon" size={14} />
              <div className="label">User votes - Important</div>
            </div>
            <Pill className="upsellPill" pillStyle={DefaultPillStyles.canny}>
              Upgrade
            </Pill>
          </div>
        ),
      },
      {
        name: CalculationVoteFactors.mustHaveUserVotes,
        render: (
          <div className={classnames('optionElement', { upsell: !company?.features?.voteWeights })}>
            <div className="optionLabel">
              <ArrowUpCircle className="icon" size={14} />
              <div className="label">User votes - Must have</div>
            </div>
            <Pill className="upsellPill" pillStyle={DefaultPillStyles.canny}>
              Upgrade
            </Pill>
          </div>
        ),
      },
      {
        name: CalculationVoteFactors.companyVotes,
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <ArrowUpCircle className="icon" size={14} />
              <div className="label">Company votes</div>
            </div>
          </div>
        ),
      },
    ];

    if (company.hasCollectedMonthlySpend) {
      options.push({
        name: CalculationStaticFactors.monthlySpend,
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <Building className="icon" size={14} />
              <div className="label">MRR</div>
            </div>
          </div>
        ),
      });
    }

    if (company.hasCollectedMonthlySpend && company.revenueTimeframe === RevenueTimeframes.arr) {
      options.push({
        name: CalculationStaticFactors.annualSpend,
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <Building className="icon" size={14} />
              <div className="label">ARR</div>
            </div>
          </div>
        ),
      });
    }

    company.customFields.company.forEach((customField) => {
      // we only support numeric custom fields
      if (customField.fieldType !== 'number') {
        return;
      }

      options.push({
        name: customField._id,
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <Building className="icon" size={14} />
              <div className="label">{customField.name}</div>
            </div>
          </div>
        ),
      });
    });

    company.customFields.user.forEach((customField) => {
      // we only support numeric custom fields
      if (customField.fieldType !== 'number') {
        return;
      }

      options.push({
        name: customField._id,
        render: (
          <div className="optionElement">
            <div className="optionLabel">
              <User className="icon" size={14} />
              <div className="label">{customField.name}</div>
            </div>
          </div>
        ),
      });
    });

    if (company.activeIntegrations.hubspot && company.activeIntegrations.salesforce) {
      options.push(
        {
          name: CalculationOpportunityFactors.openOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
                <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
                <div className="label">Open opportunities</div>
              </div>
            </div>
          ),
        },
        {
          name: CalculationOpportunityFactors.wonOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
                <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
                <div className="label">Closed won opportunities</div>
              </div>
            </div>
          ),
        },
        {
          name: CalculationOpportunityFactors.lostOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
                <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
                <div className="label">Closed lost opportunities</div>
              </div>
            </div>
          ),
        }
      );
    } else if (company.activeIntegrations.hubspot) {
      options.push(
        {
          name: CalculationOpportunityFactors.openOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
                <div className="label">Open deals</div>
              </div>
            </div>
          ),
        },
        {
          name: CalculationOpportunityFactors.wonOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
                <div className="label">Closed won deals</div>
              </div>
            </div>
          ),
        },
        {
          name: CalculationOpportunityFactors.lostOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
                <div className="label">Closed lost deals</div>
              </div>
            </div>
          ),
        }
      );
    } else if (company.activeIntegrations.salesforce) {
      options.push(
        {
          name: CalculationOpportunityFactors.openOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
                <div className="label">Open opportunities</div>
              </div>
            </div>
          ),
        },
        {
          name: CalculationOpportunityFactors.wonOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
                <div className="label">Closed won opportunities</div>
              </div>
            </div>
          ),
        },
        {
          name: CalculationOpportunityFactors.lostOpportunities,
          render: (
            <div className="optionElement">
              <div className="optionLabel">
                <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
                <div className="label">Closed lost opportunities</div>
              </div>
            </div>
          ),
        }
      );
    }

    return (
      <div className="newFactor">
        <div className="adminRoadmapFactorRow row container">
          <div className="cell name">
            <ControlledDropdown
              dropdownClassName="adminRoadmapEditFormulaModalDropdown"
              onChange={(factorOption) => this.onAddImpact(EmptyFactor, factorOption)}
              options={options}
              placeholder="Add new..."
            />
          </div>
        </div>
      </div>
    );
  }

  renderTable() {
    const nonEmptyFactors = this.state.impacts.filter((factor) => {
      return factor !== EmptyFactor;
    });
    return (
      <div className="factorsTable">
        <div className="factorsTableHeader">
          <div className="row header">
            <div className="cell name">Impact factors</div>
            <div className="cell type">Type</div>
            <div className="cell segment">Segment</div>
            <div className="cell calc">Calc</div>
            <div className="cell weight">Weight</div>
          </div>
        </div>
        <div className="factorsTableBody">
          {nonEmptyFactors.map(this.renderRow)}
          {this.renderNewImpact()}
        </div>
      </div>
    );
  }

  renderContents() {
    const { saving } = this.state;

    return (
      <Form
        addEventsToDocument={false}
        disableSubmit={!this.isSaveEnabled() || saving}
        onSubmit={this.onSaveFormula}>
        {this.renderTable()}
        {this.renderEffortFactor()}
        {this.renderError()}
        <div className="saveFormulaButton">
          <Button
            buttonType="cannyButton"
            disabled={!this.isSaveEnabled()}
            formButton={true}
            loading={saving}
            value="Save formula"
          />
        </div>
      </Form>
    );
  }

  render() {
    const { onClose } = this.props;
    return (
      <div className="adminRoadmapEditFormulaModal container">
        <Tappable onTap={onClose}>
          <div className="closeButton">
            <div className="icon icon-x" />
          </div>
        </Tappable>
        <div className="heading">Score formula</div>
        <div className="subheading">
          Score is calculated by multiplying each factor by their weight. Then, the impact total is
          divided by effort. More details can be found&nbsp;
          <a
            href="https://help.canny.io/en/articles/5046937-how-do-prioritization-factors-and-scoring-work"
            rel="noopener"
            target="_blank">
            here
          </a>
          .
        </div>
        <hr />
        {this.renderContents()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadRoadmap: (roadmap) => {
      return dispatch(reloadRoadmap(roadmap.urlName));
    },
    reloadRoadmapPosts: (queryParams) => {
      return dispatch(reloadRoadmapPosts(queryParams));
    },
  })),
  withContexts(
    {
      boards: BoardsContext,
      company: CompanyContext,
      location: LocationContext,
      params: ParamsContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminRoadmapEditFormulaModal);
