import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import asyncConnect from 'common/core/asyncConnect';
import GoogleAnalytics from 'common/ga/GoogleAnalytics';
import ProductSeenContainer from 'common/growth/ProductSeenContainer';
import JoinRoadmapForm from 'common/subdomain/public/JoinRoadmapForm';
import companyOGImage from 'common/util/companyOGImage';
import withContexts from 'common/util/withContexts';

import BoardContainer, { asyncFetch as boardAsyncFetch } from './BoardContainer';
import BoardsContainer from './BoardsContainer';
import ContentContainer from './ContentContainer';
import IntercomContainer from './IntercomContainer';
import ModalContainer from './ModalContainer';
import PostContainer, { asyncFetch as postAsyncFetch } from './PostContainer';
import PostLinkContainer from './PostLinkContainer';
import TintColorContainer, { DefaultTintColor } from './TintColorContainer';
import ToastContainer from './ToastContainer';
import LoginToAccess from '../auth/LoginToAccess';
import Helmet from '../helmets/Helmet';
import Spinner from '../Spinner';
import BoardLoginForm from '../subdomain/public/BoardLoginForm';
import JoinBoardForm from '../subdomain/public/JoinBoardForm';
import PublicNav from '../subdomain/public/PublicNav';
import VerifyEmail from '../subdomain/public/VerifyEmail';

import 'css/components/subdomain/public/_PublicContainer.scss';

class PublicContainer extends Component {
  static propTypes = {
    boards: PropTypes.object,
    company: PropTypes.object,
    location: PropTypes.object,
    notifications: PropTypes.object,
    params: PropTypes.object,
    viewer: PropTypes.object,
  };

  componentDidMount() {
    const { company } = this.props;
    if (!company || !company.gaPropertyID) {
      return;
    }

    GoogleAnalytics.track({
      excludePathnames: [/^\/admin(\/|$)/gi],
      history: browserHistory,
      measurementID: company.gaPropertyID,
      trackerName: 'customerTracker',
    });
  }

  getPostLink = (post) => {
    return '/' + post.board.urlName + '/p/' + post.urlName;
  };

  renderContents() {
    const {
      company,
      location: { query },
      params: { boardURLName, postURLName, roadmapViewID, roadmapURLName },
      viewer,
    } = this.props;
    const board = boardURLName ? this.props.boards.items[boardURLName] : null;
    const isLoading = viewer.loading || company.loading || (board && board.loading);
    const error = viewer.error || company.error || (board && board.error);

    const postCouldHaveBeenMoved = postURLName && board?.requestedBoardID;

    const publicRoadmaps = this.props.sharedRoadmap?.roadmaps ?? {};
    const roadmap = publicRoadmaps[roadmapViewID] ?? null;

    if (isLoading) {
      return (
        <ContentContainer outerClassName="loading">
          <Spinner />
        </ContentContainer>
      );
    } else if (company.notFound) {
      return (
        <ContentContainer outerClassName="notFound">
          <div className="heading">Company Not Found</div>
          <div className="message">There is no such company. Did you enter the right URL?</div>
        </ContentContainer>
      );
    } else if (error) {
      return (
        <ContentContainer outerClassName="error">
          Something went wrong, please try again later.
        </ContentContainer>
      );
    } else if (board && board.notFound && !postCouldHaveBeenMoved) {
      return (
        <ContentContainer outerClassName="notFound">
          There is no such board. Did you enter the right URL?
        </ContentContainer>
      );
    } else if (board && board.notAuthorized && !postCouldHaveBeenMoved) {
      if (query.boardID && query.email && query.inviteID) {
        return <JoinBoardForm />;
      } else if (query.verificationID) {
        if (viewer.loggedOut) {
          return <LoginToAccess />;
        }
        return <VerifyEmail boardURLName={boardURLName} />;
      } else {
        return <BoardLoginForm board={board} boardURLName={boardURLName} />;
      }
    } else if (roadmapViewID && !roadmap) {
      if (roadmapURLName && query.email && query.inviteID) {
        return <JoinRoadmapForm />;
      }
    }

    return this.props.children;
  }

  render() {
    const { company, notifications } = this.props;
    const { faviconURL, tintColor } = company;
    const faviconLink = faviconURL
      ? [
          {
            href: faviconURL,
            rel: 'shortcut icon',
          },
        ]
      : [];

    return (
      <PostLinkContainer getPostLink={this.getPostLink}>
        <TintColorContainer tintColor={tintColor || DefaultTintColor}>
          <IntercomContainer>
            <ProductSeenContainer>
              <ModalContainer>
                <ToastContainer>
                  <Helmet
                    link={faviconLink}
                    meta={[
                      // og
                      {
                        property: 'og:image',
                        content: companyOGImage(company),
                      },
                      { property: 'og:title', content: company.name },

                      // twitter
                      { name: 'twitter:card', content: 'summary_large_image' },
                      { name: 'twitter:site', content: '@cannyHQ' },
                      { name: 'twitter:title', content: company.name },
                      {
                        name: 'twitter:image',
                        content: companyOGImage(company),
                      },
                    ]}
                  />
                  <div className="publicContainer">
                    <BoardsContainer>
                      <BoardContainer>
                        <PostContainer>
                          <PublicNav notifications={notifications} />
                        </PostContainer>
                      </BoardContainer>
                    </BoardsContainer>
                    {this.renderContents()}
                  </div>
                </ToastContainer>
              </ModalContainer>
            </ProductSeenContainer>
          </IntercomContainer>
        </TintColorContainer>
      </PostLinkContainer>
    );
  }
}

export default compose(
  asyncConnect([boardAsyncFetch, BoardsContainer.asyncConnect, postAsyncFetch], (state) => ({
    boards: state.boards,
    sharedRoadmap: state.sharedRoadmap,
  })),
  withContexts(
    {
      company: CompanyContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(PublicContainer);
