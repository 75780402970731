import React, { Component } from 'react';

import PropTypes from 'prop-types';

export const CompanyContext = React.createContext();

export default class CompanyContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
  };

  render() {
    return (
      <CompanyContext.Provider value={this.props.company || { loading: true }}>
        {this.props.children}
      </CompanyContext.Provider>
    );
  }
}
