import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { loadCompany } from 'common/actions/company';
import CannySDK from 'common/CannySDK';
import asyncConnect from 'common/core/asyncConnect';
import Helmet from 'common/helmets/Helmet';
import { isPublicView } from 'common/util/pathnameMatch';

import AuthContainer from './AuthContainer';
import CompanyContainer from './CompanyContainer';
import CompanyNotificationsContainer from './CompanyNotificationsContainer';
import ExperimentContainer from './ExperimentContainer';
import FullStoryContainer from './FullStoryContainer';
import HiringContainer from './HiringContainer';
import IsBrowserExtensionContainer from './IsBrowserExtensionContainer';
import IsWidgetContainer from './IsWidgetContainer';
import RouterContainer from './RouterContainer';
import ThemeContainer from './ThemeContainer';
import ViewerContainer from './ViewerContainer';

import TouchIconURL from 'img-raw/apple-touch-icon.png';
import FaviconURL from 'img-raw/logo-icon.png';

import 'css/components/containers/_SubdomainContainer.scss';

CannySDK.init();

class SubdomainContainer extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.filterQuery();
    this.removeEmailAuthParams();
  }

  filterQuery = () => {
    const { location, router } = this.props;
    const { query } = location;
    if (!query.authToken && !query.ssoToken) {
      return;
    }

    const newQuery = Object.assign({}, query);
    delete newQuery.authToken;
    delete newQuery.ssoToken;

    router.replace({
      pathname: location.pathname,
      query: newQuery,
      state: location.state,
    });
  };

  removeEmailAuthParams = () => {
    const { location, router } = this.props;
    const { query } = location;

    if (!query.emailUserID && !query.emailAuthToken) {
      return;
    }

    const newQuery = Object.assign({}, query);
    delete newQuery.emailUserID;
    delete newQuery.emailAuthToken;

    router.replace({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  render() {
    const { location } = this.props;
    return (
      <div>
        <ThemeContainer
          configTheme={isPublicView(location.pathname) ? this.props.company.theme : 'light'}>
          <div className="subdomainContainer">
            <Helmet
              link={[
                {
                  href: FaviconURL,
                  rel: 'shortcut icon',
                },
                {
                  href: TouchIconURL,
                  rel: 'apple-touch-icon',
                },
              ]}
              title="Canny"
            />
            <RouterContainer
              location={this.props.location}
              params={this.props.params}
              router={this.props.router}>
              <IsWidgetContainer isWidget={false}>
                <IsBrowserExtensionContainer isBrowserExtension={false}>
                  <ViewerContainer>
                    <CompanyContainer company={this.props.company}>
                      <CompanyNotificationsContainer>
                        <ExperimentContainer>
                          <FullStoryContainer>
                            <AuthContainer>
                              <HiringContainer>{this.props.children}</HiringContainer>
                            </AuthContainer>
                          </FullStoryContainer>
                        </ExperimentContainer>
                      </CompanyNotificationsContainer>
                    </CompanyContainer>
                  </ViewerContainer>
                </IsBrowserExtensionContainer>
              </IsWidgetContainer>
            </RouterContainer>
            <div id="fb-root" />
          </div>
        </ThemeContainer>
      </div>
    );
  }
}

export default asyncConnect(
  [
    ViewerContainer.asyncConnect,
    {
      promise: ({ store: { dispatch } }) => {
        return dispatch(loadCompany());
      },
    },
    CompanyNotificationsContainer.asyncConnect,
  ],
  (state) => ({ company: state.company })
)(SubdomainContainer);
