import React, { type SyntheticEvent, useContext, useState } from 'react';

import { Info, Play, Zap } from 'lucide-react';

import {
  getAllowedActions,
  getAllowedFilters,
  getRequiredFilters,
} from 'common/automations/automationUtils';
import {
  type Action,
  type ActionType,
  type EditableAutomation,
  type Filter,
  FiltersOperator,
  type Resource,
  Trigger,
} from 'common/automations/constants';
import { StaticColors } from 'common/colors/constants';
import { BoardsContext } from 'common/containers/BoardsContainer';
import generateRandomID from 'common/generateRandomID';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModernModal from 'common/modals/ModernModal';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import Text, { H2, P } from 'common/ui/Text';

import ActionRow, { type FormAction } from './ActionRow';
import FilterRow, { type FormFilter } from './FilterRow';
import {
  createDefaultAction,
  createDefaultFilter,
  findActiveOption,
  getFirstAction,
  getFirstFilter,
} from './util';

import type { ErrorTypes } from 'common/automations/validateAutomation/validateAutomation';

import 'css/components/subdomain/admin/AdminAutomationSettings/_AutomationModal.scss';

export interface FormAutomation extends EditableAutomation {
  actions: FormAction[];
  filters: FormFilter[];
}

const defaultFormValues: FormAutomation = {
  actions: [],
  filters: [],
  filtersOperator: FiltersOperator.all,
  title: '',
  trigger: Trigger.postCreation,
};

const triggerOptions = [
  {
    label: 'Post is created',
    value: Trigger.postCreation,
  },
];

export type FormError = { type: ErrorTypes | 'all'; message: string };

type Props = {
  isEditing: boolean;
  onClose: () => void;
  onSave: (values: FormAutomation) => Promise<FormError | void>;
  defaultValues?: EditableAutomation;
};

const AutomationModal = ({
  isEditing,
  onClose,
  onSave,
  defaultValues = defaultFormValues,
}: Props) => {
  const boards = useContext(BoardsContext);

  const [formValues, _setFormValues] = useState<FormAutomation>(() => {
    // Add ids to the initial state of actions and filters
    return {
      ...defaultValues,
      actions: defaultValues.actions.map((action) => ({ ...action, id: generateRandomID() })),
      filters: defaultValues.filters.map((filter) => ({ ...filter, id: generateRandomID() })),
    };
  });
  const [error, setError] = useState<FormError | undefined>();

  // Boards could potentially be loading. If so, wait
  if (!Array.isArray(boards)) {
    return null;
  }

  const requiredFilterTypes = getRequiredFilters(formValues);
  const allowedFilterTypes = getAllowedFilters(formValues);

  const allowedActionTypes = getAllowedActions(formValues);

  // General helpers
  const setFormValue = <K extends keyof FormAutomation>(valueKey: K, value: FormAutomation[K]) => {
    setError(undefined);
    _setFormValues({ ...formValues, [valueKey]: value });
  };

  const onSaveClicked = async () => {
    const saveError = await onSave(formValues);
    if (saveError) {
      setError(saveError);
    }
  };

  // Filter helpers
  const onCreateFilter = () => {
    const newFilters: FormFilter[] = [
      ...formValues.filters,
      {
        id: generateRandomID(),
        ...createDefaultFilter(getFirstFilter(allowedFilterTypes)),
      },
    ];
    _setFormValues({ ...formValues, filters: newFilters });
  };

  const onRemoveFilter = (id: string) => {
    const newFilters: FormFilter[] = formValues.filters.filter((filter) => filter.id !== id);
    _setFormValues({ ...formValues, filters: newFilters });
  };

  const onResourceChange = (id: string, resource: Resource) => {
    const newFilters = formValues.filters.map((filter) => {
      if (filter.id === id) {
        return {
          id,
          ...createDefaultFilter(resource),
        };
      } else {
        return filter;
      }
    });
    _setFormValues({ ...formValues, filters: newFilters });
  };

  const onSetFilter = <F extends Filter>(
    id: string,
    condition: F['condition'],
    value: F['value']
  ) => {
    const newFilters = formValues.filters.map((filter) => {
      if (filter.id === id) {
        return {
          ...filter,
          condition,
          value,
        };
      } else {
        return filter;
      }
    });
    _setFormValues({ ...formValues, filters: newFilters });
  };

  // Action helpers
  const onCreateAction = () => {
    const newActions: FormAction[] = [
      ...formValues.actions,
      {
        id: generateRandomID(),
        ...createDefaultAction(getFirstAction(allowedActionTypes)),
      },
    ];

    const requiredFilterTypes = getRequiredFilters({ ...formValues, actions: newActions });
    const missingRequiredFilters = requiredFilterTypes.filter((newFilterType) =>
      formValues.filters.every((filter) => filter.resource !== newFilterType)
    );

    const newFilters: FormFilter[] = [
      ...formValues.filters,
      ...missingRequiredFilters.map((filterType) => ({
        id: generateRandomID(),
        ...createDefaultFilter(filterType),
      })),
    ];

    _setFormValues({ ...formValues, actions: newActions, filters: newFilters });
  };

  const onActionTypeChange = (id: string, actionType: ActionType) => {
    const newActions: FormAction[] = formValues.actions.map((action) => {
      if (action.id === id) {
        return {
          id,
          ...createDefaultAction(actionType),
        };
      } else {
        return action;
      }
    });

    const requiredFilterTypes = getRequiredFilters({ ...formValues, actions: newActions });
    const missingRequiredFilters = requiredFilterTypes.filter((newFilterType) =>
      formValues.filters.every((filter) => filter.resource !== newFilterType)
    );

    const newFilters: FormFilter[] = [
      ...formValues.filters,
      ...missingRequiredFilters.map((filterType) => ({
        id: generateRandomID(),
        ...createDefaultFilter(filterType),
      })),
    ];

    _setFormValues({ ...formValues, actions: newActions, filters: newFilters });
  };

  const onSetAction = <A extends Action>(id: string, properties: A['properties']) => {
    const newActions = formValues.actions.map((action) => {
      if (action.id === id) {
        return {
          ...action,
          properties,
        };
      } else {
        return action;
      }
    });
    _setFormValues({ ...formValues, actions: newActions });
  };

  const onRemoveAction = (id: string) => {
    const newActions: FormAction[] = formValues.actions.filter((action) => action.id !== id);
    _setFormValues({ ...formValues, actions: newActions });
  };

  const requiredFilters = formValues.filters.filter((filter) =>
    requiredFilterTypes.includes(filter.resource)
  );
  const optionalFilters = formValues.filters.filter(
    (filter) => !requiredFilterTypes.includes(filter.resource)
  );

  return (
    <ModernModal
      overlayClassName="automationModalPortal"
      onClose={onClose}
      header={
        <Text element="span" variant="headingMd">
          {isEditing ? 'Edit automation' : 'Create new automation'}
        </Text>
      }
      sections={[
        <div key="overview">
          <div className="sectionHeader">
            <div className="iconContainer">
              <Info size="20" fill={StaticColors.canny} color={StaticColors.white} />
            </div>
            <div className="textContainer">
              <H2 variant="headingSm">Overview</H2>
              <P className="subheading">Assign a label to the automation.</P>
            </div>
          </div>

          <div className="fieldLabel hasSmallMargin">
            <label htmlFor="automationTitle">
              <Text element="span">Title</Text>
            </label>
            <TextInput
              id="automationTitle"
              className="smallMargin"
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                setFormValue('title', e.currentTarget.value)
              }
              placeholder="Enter a title"
              value={formValues.title}
              required
            />
          </div>
          {error?.type === 'title' || error?.type === 'trigger' ? (
            <P variant="bodySm" className="automationError">
              {error.message}
            </P>
          ) : null}
        </div>,
        <div key="conditions">
          <div className="sectionHeader">
            <div className="iconContainer">
              <Play size="20" fill={StaticColors.canny} color={StaticColors.canny} />
            </div>
            <div className="textContainer">
              <H2 variant="headingSm">Conditions</H2>
              <P className="subheading">The automation triggers when the following occurs.</P>
            </div>
          </div>
          <SingleSelect
            className="hasMargin"
            placeholder="Select a trigger"
            optionsMaxWidth="400px"
            label="When"
            // The value is a trigger, not just a string
            onChange={(option) => setFormValue('trigger', option?.value as Trigger)}
            options={triggerOptions}
            value={findActiveOption(triggerOptions, formValues.trigger)}
          />
          {formValues.filters.length > 0 ? (
            <P className="hasSmallMargin">and all of these conditions are met</P>
          ) : null}

          <div className="filterRows hasSmallMargin">
            {requiredFilters.map((filter) => {
              return (
                <FilterRow
                  allowedFilters={allowedFilterTypes}
                  key={filter.id}
                  filter={filter}
                  onResourceChange={onResourceChange}
                  onRemove={onRemoveFilter}
                  onFilterChange={(condition, value) => onSetFilter(filter.id, condition, value)}
                  required
                />
              );
            })}
            {optionalFilters.map((filter) => {
              return (
                <FilterRow
                  allowedFilters={allowedFilterTypes}
                  key={filter.id}
                  filter={filter}
                  onResourceChange={onResourceChange}
                  onRemove={onRemoveFilter}
                  onFilterChange={(condition, value) => onSetFilter(filter.id, condition, value)}
                  required={false}
                />
              );
            })}
          </div>
          {allowedFilterTypes.length > 0 ? (
            <ButtonV2
              variant="plain"
              className="hasSmallMargin"
              size="small"
              onClick={onCreateFilter}>
              + Add new condition
            </ButtonV2>
          ) : null}
          {error?.type === 'filter' ? (
            <P variant="bodySm" className="automationError">
              {error.message}
            </P>
          ) : null}
        </div>,
        <div key="outcomes">
          <div className="sectionHeader">
            <div className="iconContainer">
              <Zap size="20" fill={StaticColors.canny} color={StaticColors.canny} />
            </div>
            <div className="textContainer">
              <H2 variant="headingSm">Outcomes</H2>
              <P className="subheading">The following action(s) will execute as a result.</P>
            </div>
          </div>

          <P className="hasSmallMargin">Then</P>
          <div className="actionRows hasSmallMargin">
            {formValues.actions.map((action) => {
              return (
                <ActionRow
                  key={action.id}
                  allowedActions={allowedActionTypes}
                  action={action}
                  filters={formValues.filters}
                  onActionTypeChange={onActionTypeChange}
                  onRemove={onRemoveAction}
                  onActionChange={(properties) => onSetAction(action.id, properties)}
                />
              );
            })}
          </div>
          {allowedActionTypes.length > 0 ? (
            <ButtonV2
              variant="plain"
              size="small"
              className="hasSmallMargin"
              onClick={onCreateAction}>
              + Add new outcome
            </ButtonV2>
          ) : null}
          {error?.type === 'action' ? (
            <P variant="bodySm" className="automationError">
              {error.message}
            </P>
          ) : null}
        </div>,
      ]}
      footer={
        <>
          {error?.type === 'all' ? <P className="automationError">{error.message}</P> : null}
          <Button onTap={onSaveClicked} value={isEditing ? 'Save changes' : 'Create rule'} />
        </>
      }
    />
  );
};

export default AutomationModal;
