import React, { useContext } from 'react';

import classnames from 'classnames';
import { TerminalSquare } from 'lucide-react';

import AutopilotSourceLogo from 'common/common/AutopilotSourceLogo';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_GenericSourceCell.module.scss';

import CannyLogo from 'img/canny-icon.svg';

type DraftSourceProps = {
  sourceType: 'draft';
  link: string | null;
  source: SyncIntegrationNames;
  hasDuplicatePost: boolean;
};

type PostSourceProps = {
  sourceType: 'post';
  urlName: string;
  hasDuplicatePost: boolean;
};

type Props = DraftSourceProps | PostSourceProps;

type ExternalIntegrationNames = Exclude<SyncIntegrationNames, 'api'>;

const SourceLabelMap: Record<ExternalIntegrationNames, string> = {
  [SyncIntegrationNames.intercom]: 'Intercom',
  [SyncIntegrationNames.helpscout]: 'Help Scout',
  [SyncIntegrationNames.zendesk]: 'Zendesk',
  [SyncIntegrationNames.gong]: 'Gong',
  [SyncIntegrationNames.trustpilotReviews]: 'Trustpilot',
  [SyncIntegrationNames.appStoreReviews]: 'App Store',
  [SyncIntegrationNames.playStoreReviews]: 'Google Play',
  [SyncIntegrationNames.g2Reviews]: 'G2',
  [SyncIntegrationNames.capterraReviews]: 'Capterra',
  [SyncIntegrationNames.trustradiusReviews]: 'TrustRadius',
  [SyncIntegrationNames.productHuntReviews]: 'Product Hunt',
  [SyncIntegrationNames.salesforceReviews]: 'Salesforce',
  [SyncIntegrationNames.wordpressReviews]: 'WordPress',
  [SyncIntegrationNames.shopifyReviews]: 'Shopify',
};

const DefaultLinkMap: Record<ExternalIntegrationNames, string> = {
  [SyncIntegrationNames.intercom]: '/admin/settings/intercom',
  [SyncIntegrationNames.helpscout]: '/admin/settings/helpscout',
  [SyncIntegrationNames.zendesk]: '/admin/settings/zendesk',
  [SyncIntegrationNames.gong]: '/admin/settings/gong',
  [SyncIntegrationNames.trustpilotReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.appStoreReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.playStoreReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.g2Reviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.capterraReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.trustradiusReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.productHuntReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.salesforceReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.wordpressReviews]: '/admin/settings/autopilot/feedback-discovery',
  [SyncIntegrationNames.shopifyReviews]: '/admin/settings/autopilot/feedback-discovery',
};

const GenericSourceCell = (props: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  if (props.sourceType === 'draft') {
    const { source, hasDuplicatePost, link } = props;

    switch (source) {
      case SyncIntegrationNames.api:
        return (
          <a
            href={link ?? undefined}
            target="_blank"
            className={styles.sourceLink}
            rel="noopener noreferrer nofollow">
            <TerminalSquare
              className={classnames(styles.queueSourceLogo, { duplicate: hasDuplicatePost })}
            />
            <Span className={styles.queueSourceLabel}>API</Span>
          </a>
        );
      default:
        return (
          <a
            href={link ?? DefaultLinkMap[source]}
            target="_blank"
            className={styles.sourceLink}
            rel="noopener noreferrer nofollow">
            <AutopilotSourceLogo
              className={classnames(styles.queueSourceLogo, { duplicate: hasDuplicatePost })}
              integrationName={source}
            />
            <Span className={styles.queueSourceLabel}>{SourceLabelMap[source]}</Span>
          </a>
        );
    }
  }

  // Post source
  const { urlName, hasDuplicatePost } = props;
  return (
    <button
      className={styles.cannyPostButton}
      onClick={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: urlName },
        })
      }>
      <LazyLoadedImage
        className={classnames(styles.queueSourceLogo, { duplicate: hasDuplicatePost })}
        alt="canny"
        src={CannyLogo}
      />
      <Span className={styles.queueSourceLabel}>Canny</Span>
    </button>
  );
};

export default GenericSourceCell;
