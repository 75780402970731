import AJAX from './AJAX';

const Data = {
  fetch(queries, cookies, callback) {
    if (!callback && typeof cookies === 'function') {
      callback = cookies;
      cookies = {};
    }

    var results = {};
    var numQueries = Object.keys(queries).length;
    var responses = 0;
    var errored = false;

    const promises = [];

    const handleResponse = (qk, qo, responseDataJSON) => {
      if (errored) {
        return;
      }

      var responseData;
      try {
        responseData = JSON.parse(responseDataJSON);
      } catch (e) {
        responseData = { error: 'server error' };
      }

      if (responseData.error) {
        callback(responseData.error, null);
        errored = true;
        return;
      }

      results[qk] = responseData[qo];

      if (++responses === numQueries) {
        callback(null, results);
      }
    };

    // for each query...
    for (var queryKey in queries) {
      var query = queries[queryKey].query;
      var input = queries[queryKey].input || {};

      if (!query) {
        throw new Error('Invalid Query: ' + queryKey);
      }

      // make sure all inputs are specified
      var expectedInput = query.input;
      for (const inputKey in expectedInput) {
        if (expectedInput[inputKey] === 'optional') {
          continue;
        }
        if (input[inputKey] === undefined || input[inputKey] === null) {
          console.log(`Missing input: ${inputKey}, query: ${query.name}`);
          throw new Error('Missing input: ' + inputKey + ', query: ' + query.name);
        }
      }

      // if so, hit the API endpoint
      var data = {};
      for (const cookieName in cookies) {
        data[cookieName] = cookies[cookieName];
      }
      for (const inputKey in expectedInput) {
        if (input[inputKey] === undefined || input[inputKey] === null) {
          continue;
        }
        data[inputKey] = input[inputKey];
      }

      promises.push(
        AJAX.post(query.endpoint, data, handleResponse.bind(null, queryKey, query.output))
      );
    }

    return Promise.all(promises);
  },

  queries: {
    adminDashboardActivity: {
      endpoint: '/api/adminDashboard/get',
      input: { categoryURLNames: 'optional', dateRange: true, tagURLNames: 'optional' },
      output: 'activity',
    },
    apiKey: {
      endpoint: '/api/company/getAPIKey',
      input: {},
      output: 'apiKey',
    },
    asanaSettings: {
      endpoint: '/api/asana/getSettings',
      input: {},
      output: 'asanaSettings',
    },
    asanaTaskSuggestions: {
      endpoint: '/api/asana/searchTasks',
      input: { search: true },
      output: 'tasks',
    },
    assessment: {
      endpoint: '/api/assessments/get',
      input: { email: true },
      output: 'assessment',
    },
    automations: {
      endpoint: '/api/automation/list',
      input: {},
      output: 'result',
    },
    azureDevopsSettings: {
      endpoint: '/api/azureDevops/getSettings',
      input: {},
      output: 'azureDevopsSettings',
    },
    azureDevopsWorkItemSuggestions: {
      endpoint: '/api/azureDevops/workItems/getSuggestions',
      input: { search: true },
      output: 'workItems',
    },
    baeCompany: {
      endpoint: '/api/bae/companies/findOne',
      input: { subdomain: true },
      output: 'company',
    },
    board: {
      endpoint: '/api/boards/get',
      input: { boardURLName: true },
      output: 'board',
    },
    boards: {
      endpoint: '/api/boards/getAll',
      input: {},
      output: 'boards',
    },
    changelog: {
      endpoint: '/api/changelog/get',
      input: {},
      output: 'changelog',
    },
    changelogEntries: {
      endpoint: '/api/changelog/getEntries',
      input: {
        labels: 'optional',
        pages: true,
        status: 'optional',
        type: 'optional',
        searchText: 'optional',
      },
      output: 'result',
    },
    changelogEntry: {
      endpoint: '/api/changelog/getEntry',
      input: { entryURLName: true },
      output: 'entry',
    },
    changelogWidget: {
      endpoint: '/api/changelog/getWidgetData',
      input: { companyID: true, labelIDs: 'optional', ssoToken: 'optional' },
      output: 'result',
    },
    clickupTaskSuggestions: {
      endpoint: '/api/clickup/searchTasks',
      input: { query: true },
      output: 'tasks',
    },
    clickupTeam: {
      endpoint: '/api/clickup/getTeam',
      input: {},
      output: 'result',
    },
    clickupRules: {
      endpoint: '/api/clickup/getRules',
      input: {},
      output: 'rules',
    },
    companies: {
      endpoint: '/api/bae/companies/find',
      input: { search: 'optional' },
      output: 'companies',
    },
    company: {
      endpoint: '/api/company/get',
      input: {},
      output: 'company',
    },
    customFields: {
      endpoint: '/api/customFields/get',
      input: {},
      output: 'customFields',
    },
    customPostFields: {
      endpoint: '/api/customPostFields/list',
      input: {},
      output: 'customPostFields',
    },
    discordSettings: {
      endpoint: '/api/discord/getSettings',
      input: {},
      output: 'discordSettings',
    },
    gsuiteGroups: {
      endpoint: '/api/gsuite/listGroups',
      input: {},
      output: 'groups',
    },
    githubIssueSuggestions: {
      endpoint: '/api/github/findIssueSuggestions',
      input: { search: true },
      output: 'suggestions',
    },
    gongSettings: {
      endpoint: '/api/gong/getSettings',
      input: {},
      output: 'gongSettings',
    },
    helpscoutSettings: {
      endpoint: '/api/helpscout/getSettings',
      input: {},
      output: 'helpscoutSettings',
    },
    inboxItems: {
      endpoint: '/api/inbox/getItems',
      input: {
        boardURLNames: 'optional',
        confidence: 'optional',
        itemType: 'optional',
        lastCreated: 'optional',
        limit: true,
        sort: 'optional',
      },
      output: 'result',
    },
    invoices: {
      endpoint: '/api/billing/getInvoices',
      input: { companyID: true },
      output: 'invoices',
    },
    issueSuggestions: {
      endpoint: '/api/jira/findIssueSuggestions',
      input: { search: true },
      output: 'suggestions',
    },
    jiraProjects: {
      endpoint: '/api/jira/getProjects',
      input: {},
      output: 'projects',
    },
    jiraRequiredFields: {
      endpoint: '/api/jira/getRequiredFields',
      input: { projectID: true },
      output: 'requiredFields',
    },
    jiraRules: {
      endpoint: '/api/jira/getRules',
      input: {},
      output: 'rules',
    },
    jiraStatuses: {
      endpoint: '/api/jira/getStatuses',
      input: {},
      output: 'statuses',
    },
    jobs: {
      endpoint: '/api/jobs/find',
      input: {},
      output: 'jobs',
    },
    linearIssueSuggestions: {
      endpoint: '/api/linear/searchIssues',
      input: { search: true },
      output: 'issues',
    },
    linearSettings: {
      endpoint: '/api/linear/getSettings',
      input: {},
      output: 'linearSettings',
    },
    mentionSuggestions: {
      endpoint: '/api/mentions/findSuggestions',
      input: { boardID: true, membersOnly: true, prefix: true },
      output: 'suggestions',
    },
    metrics: {
      endpoint: '/api/metrics/get',
      input: {},
      output: 'metrics',
    },
    notifications: {
      endpoint: '/api/notifications/get',
      input: { boardID: 'optional', pages: true },
      output: 'notifications',
    },
    pendingInvoices: {
      endpoint: '/api/bae/companies/pendingInvoices',
      input: {},
      output: 'pendingInvoices',
    },
    plans: {
      endpoint: '/api/bae/plans/find',
      input: {},
      output: 'plans',
    },
    post: {
      endpoint: '/api/posts/getOne',
      input: { boardID: true, postURLName: true },
      output: 'post',
    },
    postActivity: {
      endpoint: '/api/posts/activity/get',
      input: {
        limit: 'optional',
        postID: true,
        internalComments: true,
        text: 'optional',
        segmentURLName: 'optional',
        sort: 'optional',
      },
      output: 'postActivity',
    },
    posts: {
      endpoint: '/api/posts/get',
      input: {
        boardID: 'optional',
        boardURLNames: 'optional',
        categoryURLNames: 'optional',
        companyURLNames: 'optional',
        accountOwnerName: 'optional',
        currentBoard: 'optional',
        my: 'optional',
        myCompanies: 'optional',
        ownerIDs: 'optional',
        pages: true,
        postCreatedDateRange: 'optional',
        scoreFactor: 'optional',
        segmentURLName: 'optional',
        sort: true,
        status: 'optional',
        tagURLNames: 'optional',
        textSearch: 'optional',
        unassigned: 'optional',
        uncategorized: 'optional',
        untagged: 'optional',
        voteCreatedDateRange: 'optional',
      },
      output: 'result',
    },
    postSuggestions: {
      endpoint: '/api/posts/findSuggestions',
      input: {
        entryID: 'optional',
        search: true,
        includeStatusTypes: 'optional',
      },
      output: 'suggestions',
    },
    postTasks: {
      endpoint: '/api/posts/thirdPartyTasks',
      input: {
        postIDs: true,
      },
      output: 'thirdPartyTasks',
    },
    postTitles: {
      endpoint: '/api/posts/get/titles',
      input: {
        boardURLNames: true,
      },
      output: 'result',
    },
    publicRoadmap: {
      endpoint: '/api/roadmap/get',
      input: {
        boardURLNames: 'optional',
        categoryURLNames: 'optional',
        allowUncategorized: 'optional',
        pages: true,
      },
      output: 'roadmap',
    },
    actionItems: {
      endpoint: '/api/queue/actions/getItems',
      input: {
        source: 'optional',
        pages: 'optional',
      },
      output: 'result',
    },
    queueItems: {
      endpoint: '/api/queue/getItems',
      input: {
        boardURLNames: 'optional',
        dateRange: 'optional',
        source: 'optional',
        sort: 'optional',
        pages: 'optional',
        postTypes: 'optional',
        sortDirection: 'optional',
      },
      output: 'result',
    },
    queueItemsCount: {
      endpoint: '/api/queue/count',
      input: {},
      output: 'result',
    },
    referralSettings: {
      endpoint: '/api/referrals/getSettings',
      input: {},
      output: 'referralSettings',
    },
    referrer: {
      endpoint: '/api/referrals/getReferrer',
      input: { referralCode: true },
      output: 'referrer',
    },
    roadmap: {
      endpoint: '/api/roadmaps/getOne',
      input: { roadmapURLName: true },
      output: 'roadmap',
    },
    sharedRoadmap: {
      endpoint: '/api/roadmaps/shared/getOne',
      input: { roadmapViewID: true, roadmapURLName: true },
      output: 'roadmap',
    },
    sharedRoadmapPosts: {
      endpoint: '/api/roadmaps/shared/posts/get',
      input: { roadmapID: true, roadmapViewID: true },
      output: 'result',
    },
    roadmaps: {
      endpoint: '/api/roadmaps/get',
      input: {},
      output: 'roadmaps',
    },
    roadmapPosts: {
      endpoint: '/api/roadmaps/posts/get',
      input: {
        boardURLNames: 'optional',
        categoryURLNames: 'optional',
        roadmapID: true,
        etaDateRange: 'optional',
        ownerIDs: 'optional',
        status: 'optional',
        tagURLNames: 'optional',
        descriptionColumnFilters: 'optional',
        factorFilters: 'optional',
      },
      output: 'result',
    },
    thirdPartyCompanies: {
      endpoint: '/api/thirdPartyCompanies/get',
      input: {
        limit: true,
        search: 'optional',
        segmentURLName: 'optional',
      },
      output: 'result',
    },
    thirdPartyCompany: {
      endpoint: '/api/thirdPartyCompanies/getOne',
      input: { urlName: true },
      output: 'thirdPartyCompany',
    },
    thirdPartyCompanyFeatureRequests: {
      endpoint: '/api/reports/thirdPartyCompanyFeatureRequests',
      input: { sort: 'optional', filter: 'optional' },
      output: 'companies',
    },
    thirdPartyCompaniesForFilters: {
      endpoint: '/api/thirdPartyCompanies/getForFilters',
      input: { search: 'optional', baseCompanies: 'optional', baseAccountOwner: 'optional' },
      output: 'result',
    },
    thirdPartyCompanyPosts: {
      endpoint: '/api/thirdPartyCompanies/getPosts',
      input: {
        filter: true,
        limit: true,
        urlName: true,
      },
      output: 'result',
    },
    upcomingRenewals: {
      endpoint: '/api/bae/companies/upcomingRenewals',
      input: {},
      output: 'upcomingRenewals',
    },
    user: {
      endpoint: '/api/users/getOne',
      input: { urlName: true },
      output: 'user',
    },
    userPosts: {
      endpoint: '/api/users/getPosts',
      input: {
        filter: true,
        limit: true,
        userID: true,
      },
      output: 'result',
    },
    users: {
      endpoint: '/api/users/get',
      input: {
        limit: true,
        sort: 'optional',
        search: 'optional',
        segmentURLName: 'optional',
        dateRange: 'optional',
      },
      output: 'result',
    },
    userReactions: {
      endpoint: '/api/userReactions/get',
      input: {
        objectID: true,
        objectType: true,
        reactionName: true,
      },
      output: 'reactions',
    },
    userSuggestions: {
      endpoint: '/api/company/findUserSuggestions',
      input: { search: true },
      output: 'suggestions',
    },
    viewer: {
      endpoint: '/api/viewer/get',
      input: { includeDocsData: 'optional' },
      output: 'viewer',
    },
    vipTrials: {
      endpoint: '/api/bae/companies/vipTrials',
      input: {},
      output: 'vipTrials',
    },
    voters: {
      endpoint: '/api/posts/getVoters',
      input: { postID: true, segmentURLName: 'optional' },
      output: 'result',
    },
    votes: {
      endpoint: '/api/votes/get',
      input: { filter: 'optional', limit: true, userID: true },
      output: 'result',
    },
    widget: {
      endpoint: '/api/widget/get',
      input: { boardToken: true, ssoToken: 'optional' },
      output: 'result',
    },
  },
};

Object.keys(Data.queries).forEach((queryName) => {
  Data.queries[queryName].name = queryName;
});

export default Data;
