import devURL from 'common/util/devURL';

import generateRandomID from './generateRandomID';
import Message from './message/Message';
import queryString from './util/queryString';

const GitHubAuthURL = 'https://github.com/login/oauth/authorize';
const GitHubClientID = __DEV__ ? '3fd92ffb5d2bdd6291c9' : '964578a3453dbdc89b04';
const GitHubScope = 'user:email';
const Origin = devURL('https://canny.io');
const RedirectURI = devURL('https://canny.io/auth');

export default class OAuthGitHub {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab, redirectURL, separateWindow }) {
    this._onFailure = onFailure;
    this._onContinue = onContinue;
    this._shouldOpenNewTab = shouldOpenNewTab;
    this._redirectURL = redirectURL;
    this._separateWindow = separateWindow;
  }

  connect = () => {
    const redirectURI =
      RedirectURI +
      queryString.stringify({
        authType: 'github',
        stage: 2,
        ...(this._shouldOpenNewTab && { close: true }),
        ...(this._redirectURL && { redirectURL: encodeURIComponent(this._redirectURL) }),
        ...(this._separateWindow && { separateWindow: encodeURIComponent(this._separateWindow) }),
      });

    const params = {
      client_id: GitHubClientID,
      redirect_uri: redirectURI,
      scope: GitHubScope,
      state: generateRandomID(),
    };

    const query = [];
    for (var key in params) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    }

    const url = GitHubAuthURL + '?' + query.join('&');

    if (!this._shouldOpenNewTab) {
      window.location = url;
      return;
    }

    const authWindow = window.open(url, 'Canny Authentication');
    const unsubscribe = Message.subscribe(authWindow, Origin, 'authResult', (result) => {
      this._onContinue(result);
      unsubscribe();
    });
  };
}
