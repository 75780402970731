import React, { useContext, useState } from 'react';

import 'css/components/modals/_CustomRoleModal.scss';

import { DollarSign } from 'lucide-react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import TextInput from 'common/inputs/TextInput';
import { companyRoleHasBillablePermissions } from 'common/roles/utils';
import ButtonV2 from 'common/ui/ButtonV2';
import IconBadge from 'common/ui/IconBadge';
import { Span } from 'common/ui/Text';

import { type Section, Sections } from './constants';
import PermissionsAccordion from './PermissionsAccordion';
import { generateInitialState } from './utils';
import ModernModal from '../ModernModal';

import type { Company, Role, RolePermissionName } from 'common/api/endpoints/companies';

type EnabledPermissionsMap = Record<Section, Partial<Record<RolePermissionName, boolean>>>;

type Props = {
  error: string | null;
  onClose: () => void;
  onSave: (
    name: string | undefined,
    enabledPermissions: RolePermissionName[],
    roleID?: string
  ) => void;
  role: Role | null;
  saveLoading: boolean;
};

const CustomRoleModal = ({ error, onClose, onSave, role, saveLoading }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [title, setTitle] = useState<string>(role?.name ?? '');
  const [permissions, setPermissions] = useState<EnabledPermissionsMap>(generateInitialState(role));

  const toggleSection = (section: Section) => (isChecked: boolean) => {
    setPermissions((prev) => {
      const updatedSection = { ...prev[section] };

      for (const key in updatedSection) {
        updatedSection[key as RolePermissionName] = isChecked;
      }

      return {
        ...prev,
        [section]: updatedSection,
      };
    });
  };

  const togglePermission = (section: Section) => (permission: string, isChecked: boolean) => {
    setPermissions((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [permission]: isChecked,
      },
    }));
  };

  const enabledPermissions = Object.values(permissions)
    .map((permissionMap) =>
      Object.entries(permissionMap)
        .filter(([_, isEnabled]) => isEnabled)
        .map(([permission]) => permission)
    )
    .flat() as RolePermissionName[];

  const tempRole = {
    name: title,
    permissions: Object.fromEntries(enabledPermissions.map((permission) => [permission, true])),
  };
  const isRoleBillable = companyRoleHasBillablePermissions(company, tempRole);

  const onSaveClick = () => {
    if (saveLoading) {
      return;
    }

    onSave(title, enabledPermissions, role?._id);
  };

  return (
    <ModernModal
      overlayClassName="customRoleModalPortal"
      onClose={onClose}
      header={role ? 'Update role' : 'Create new role'}
      sections={[
        <div key="main" className="mainContent">
          <section className="titleSection">
            <label htmlFor="titleInput">
              Title <Span className="requiredField">*</Span>
            </label>
            <TextInput
              id="titleInput"
              placeholder="e.g. Team Owner"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </section>
          <section className="permissionsSection">
            <ul>
              {Sections.map((section) => (
                <li key={section}>
                  <PermissionsAccordion
                    permissions={permissions[section]}
                    section={section}
                    togglePermission={togglePermission(section)}
                    toggleSection={toggleSection(section)}
                  />
                </li>
              ))}
            </ul>
          </section>
        </div>,
      ]}
      footer={
        <>
          {isRoleBillable && (
            <Span className="roleBillableMessage">
              <IconBadge active size="medium" icon={DollarSign} />
              This is a billable role
            </Span>
          )}
          {error && (
            <Span variant="bodySm" className="errorText">
              {error}
            </Span>
          )}
          <ButtonV2 size="medium" variant="outlined" onClick={onClose}>
            Cancel
          </ButtonV2>
          <ButtonV2 size="medium" onClick={onSaveClick} loading={saveLoading}>
            {role ? 'Update' : 'Create'} role
          </ButtonV2>
        </>
      }
    />
  );
};

export default CustomRoleModal;
