import type { ObjectValues } from 'common/types/util';

const Colors = {
  oidc: 'var(--oidc)',
  okta: 'var(--okta)',
  slack: 'var(--slack)',
  salesforce: 'var(--salesforce)',
  segment: 'var(--segment)',
  zapier: 'var(--zapier)',
  facebook: 'var(--facebook)',
  zendesk: 'var(--zendesk)',
  clickup: 'var(--clickup)',
  github: 'var(--github)',
  gong: 'var(--gong)',
  googleanalytics: 'var(--googleanalytics)',
  gsuite: 'var(--gsuite)',
  helpscout: 'var(--helpscout)',
  hubspot: 'var(--hubspot)',
  jira: 'var(--jira)',
  azure: 'var(--azure)',
  azureDevops: 'var(--azureDevops)',
  discord: 'var(--discord)',
  intercom: 'var(--intercom)',
  asana: 'var(--asana)',
  linear: 'var(--linear)',
  msteams: 'var(--msteams)',
  trello: 'var(--trello)',
  aha: 'var(--aha)',
  productboard: 'var(--productboard)',
  pendo: 'var(--pendo)',
  userVoice: 'var(--userVoice)',
  trustpilot: 'var(--trustpilot)',
  appStore: 'var(--appStore)',
  playStore: 'var(--playStore)',
  g2: 'var(--g2)',
  capterra: 'var(--capterra)',
  trustradius: 'var(--trustradius)',
  productHunt: 'var(--productHunt)',
  wordpress: 'var(--wordpress)',
  shopify: 'var(--shopify)',
  caseStudyAgencyAnalytics: 'var(--case-study-agencyanalytics)',
  caseStudyAhrefs: 'var(--case-study-ahrefs)',
  caseStudyAxios: 'var(--case-study-axios)',
  caseStudyClickup: 'var(--case-study-clickup)',
  caseStudyFigured: 'var(--case-study-figured)',
  caseStudyHive: 'var(--case-study-hive)',
  caseStudyKnak: 'var(--case-study-knak)',
  caseStudyMercury: 'var(--case-study-mercury)',
  caseStudyMissive: 'var(--case-study-missive)',
  caseStudyTanda: 'var(--case-study-tanda)',
  'caseStudySticker-mule': 'var(--case-study-sticker-mule)',
  caseStudyStrapi: 'var(--case-study-strapi)',
  caseStudyJaneTechnologies: 'var(--case-study-jane-technologies)',
  caseStudyDocument360: 'var(--case-study-document360)',
  caseStudyAkiflow: 'var(--case-study-akiflow)',
  caseStudyPallyy: 'var(--case-study-pallyy)',
  caseStudyGiveButter: 'var(--case-study-give-butter)',
  caseStudyCci: 'var(--case-study-cci)',
  caseStudyOutlier: 'var(--case-study-outlier)',

  darkenCaseStudyAgencyAnalytics: 'var(--darken-case-study-agencyanalytics)',
  darkenCaseStudyAhrefs: 'var(--darken-case-study-ahrefs)',
  darkenCaseStudyAxios: 'var(--darken-case-study-axios)',
  darkenCaseStudyClickup: 'var(--darken-case-study-clickup)',
  darkenCaseStudyFigured: 'var(--darken-case-study-figured)',
  darkenCaseStudyHive: 'var(--darken-case-study-hive)',
  darkenCaseStudyKnak: 'var(--darken-case-study-knak)',
  darkenCaseStudyFramer: 'var(--darken-case-study-framer)',
  darkenCaseStudyMercury: 'var(--darken-case-study-mercury)',
  darkenCaseStudyMissive: 'var(--darken-case-study-missive)',
  darkenCaseStudyTanda: 'var(--darken-case-study-tanda)',
  'darkenCaseStudySticker-mule': 'var(--darken-case-study-sticker-mule)',
  darkenCaseStudyStrapi: 'var(--darken-case-study-strapi)',
  darkenCaseStudyCci: 'var(--darken-case-study-cci)',
  darkencaseStudyJaneTechnologies: 'var(--darken-case-study-jane-technologies)',
  darkencaseStudyDocument360: 'var(--darken-case-study-document360)',
  darkencaseStudyAkiflow: 'var(--darken-case-study-akiflow)',
  darkenCaseStudyPallyy: 'var(--darken-case-study-pallyy)',
  darkenCaseStudyGiveButter: 'var(--darken-case-study-give-butter)',
  darkenCaseStudyOutlier: 'var(--darken-case-study-outlier)',

  postStatusComplete: 'var(--postStatusComplete)',
  postStatusInProgress: 'var(--postStatusInProgress)',
  postStatusPlanned: 'var(--postStatusPlanned)',
  postStatusUnderReview: 'var(--postStatusUnderReview)',
  postStatusClosed: 'var(--postStatusClosed)',
  postStatusOpen: 'var(--postStatusOpen)',
  postStatusBlue100: 'var(--postStatusBlue100)',
  postStatusBlue200: 'var(--postStatusBlue200)',
  postStatusBlue300: 'var(--postStatusBlue300)',
  postStatusBlue400: 'var(--postStatusBlue400)',
  postStatusBlue500: 'var(--postStatusBlue500)',
  postStatusBlue600: 'var(--postStatusBlue600)',
  postStatusBlue700: 'var(--postStatusBlue700)',
  postStatusBlue800: 'var(--postStatusBlue800)',

  postStatusGreen100: 'var(--postStatusGreen100)',
  postStatusGreen200: 'var(--postStatusGreen200)',
  postStatusGreen300: 'var(--postStatusGreen300)',
  postStatusGreen400: 'var(--postStatusGreen400)',
  postStatusGreen500: 'var(--postStatusGreen500)',
  postStatusGreen600: 'var(--postStatusGreen600)',
  postStatusGreen700: 'var(--postStatusGreen700)',
  postStatusGreen800: 'var(--postStatusGreen800)',

  postStatusPurple100: 'var(--postStatusPurple100)',
  postStatusPurple200: 'var(--postStatusPurple200)',
  postStatusPurple300: 'var(--postStatusPurple300)',
  postStatusPurple400: 'var(--postStatusPurple400)',
  postStatusPurple500: 'var(--postStatusPurple500)',
  postStatusPurple600: 'var(--postStatusPurple600)',
  postStatusPurple700: 'var(--postStatusPurple700)',
  postStatusPurple800: 'var(--postStatusPurple800)',

  postStatusRed100: 'var(--postStatusRed100)',
  postStatusRed200: 'var(--postStatusRed200)',
  postStatusRed300: 'var(--postStatusRed300)',
  postStatusRed400: 'var(--postStatusRed400)',
  postStatusRed500: 'var(--postStatusRed500)',
  postStatusRed600: 'var(--postStatusRed600)',
  postStatusRed700: 'var(--postStatusRed700)',
  postStatusRed800: 'var(--postStatusRed800)',

  white: 'var(--white)',
  black: 'var(--black)',

  overlay10: 'var(--overlay-10)',
  // 'overlay20': 'var(--overlay-20)',
  overlay30: 'var(--overlay-30)',
  overlay40: 'var(--overlay-40)',
  overlay50: 'var(--overlay-50)',
  overlay60: 'var(--overlay-60)',
  overlay70: 'var(--overlay-70)',
  overlay80: 'var(--overlay-80)',
  overlay90: 'var(--overlay-90)',
  // 'overlay100': 'var(--overlay-100)',
  // 'overlay110': 'var(--overlay-110)',
  overlay120: 'var(--overlay-120)',

  gray10: 'var(--gray-10)',
  gray20: 'var(--gray-20)',
  gray30: 'var(--gray-30)',
  gray40: 'var(--gray-40)',
  gray50: 'var(--gray-50)',
  gray60: 'var(--gray-60)',
  gray70: 'var(--gray-70)',
  gray80: 'var(--gray-80)',
  gray90: 'var(--gray-90)',
  gray100: 'var(--gray-100)',
  gray110: 'var(--gray-110)',
  gray120: 'var(--gray-120)',

  // 02-tomato

  tomato10: 'var(--tomato-10)',
  tomato20: 'var(--tomato-20)',
  // 'tomato30': 'var(--tomato-30)',
  tomato40: 'var(--tomato-40)',
  // 'tomato50': 'var(--tomato-50)',
  tomato60: 'var(--tomato-60)',
  // 'tomato70': 'var(--tomato-70)',
  // 'tomato80': 'var(--tomato-80)',
  tomato90: 'var(--tomato-90)',
  // 'tomato100': 'var(--tomato-100)',
  tomato110: 'var(--tomato-110)',
  // 'tomato120': 'var(--tomato-120)',

  // 02.5-Ruby

  // ruby10: 'var(--ruby-10)',
  // ruby20: 'var(--ruby-20)',
  // ruby30: 'var(--ruby-30)',
  ruby40: 'var(--ruby-40)',
  ruby50: 'var(--ruby-50)',
  // ruby60: 'var(--ruby-60)',
  // ruby70: 'var(--ruby-70)',
  // ruby80: 'var(--ruby-80)',
  ruby90: 'var(--ruby-90)',
  // ruby100: 'var(--ruby-100)',
  ruby110: 'var(--ruby-110)',
  // ruby120: 'var(--ruby-120)',

  // 03-red

  red10: 'var(--red-10)',
  red20: 'var(--red-20)',
  red30: 'var(--red-30)',
  red40: 'var(--red-40)',
  red50: 'var(--red-50)',
  red60: 'var(--red-60)',
  red70: 'var(--red-70)',
  red80: 'var(--red-80)',
  red90: 'var(--red-90)',
  red100: 'var(--red-100)',
  red110: 'var(--red-110)',
  // 'red120': 'var(--red-120)',

  // 04-crimson

  // 'crimson10': 'var(--crimson-10)',
  // 'crimson20': 'var(--crimson-20)',
  // 'crimson30': 'var(--crimson-30)',
  crimson40: 'var(--crimson-40)',
  // 'crimson50': 'var(--crimson-50)',
  crimson60: 'var(--crimson-60)',
  // 'crimson70': 'var(--crimson-70)',
  // 'crimson80': 'var(--crimson-80)',
  crimson90: 'var(--crimson-90)',
  // 'crimson100': 'var(--crimson-100)',
  crimson110: 'var(--crimson-110)',
  // 'crimson120': 'var(--crimson-120)',

  // 05-pink

  // 'pink10': 'var(--pink-10)',
  // 'pink20': 'var(--pink-20)',
  // 'pink30': 'var(--pink-30)',
  pink40: 'var(--pink-40)',
  // 'pink50': 'var(--pink-50)',
  // 'pink60': 'var(--pink-60)',
  // 'pink70': 'var(--pink-70)',
  // 'pink80': 'var(--pink-80)',
  pink90: 'var(--pink-90)',
  // 'pink100': 'var(--pink-100)',
  pink110: 'var(--pink-110)',
  // 'pink120': 'var(--pink-120)',

  // 06-Plum

  // 'plum10': 'var(--plum-10)',
  // 'plum20': 'var(--plum-20)',
  // 'plum30': 'var(--plum-30)',
  plum40: 'var(--plum-40)',
  // 'plum50': 'var(--plum-50)',
  // 'plum60': 'var(--plum-60)',
  // 'plum70': 'var(--plum-70)',
  // 'plum80': 'var(--plum-80)',
  plum90: 'var(--plum-90)',
  // 'plum100': 'var(--plum-100)',
  plum110: 'var(--plum-110)',
  // 'plum120': 'var(--plum-120)',

  // 07-Purple

  // 'purple10': 'var(--purple-10)',
  // 'purple20': 'var(--purple-20)',
  purple30: 'var(--purple-30)',
  purple40: 'var(--purple-40)',
  purple50: 'var(--purple-50)',
  // 'purple60': 'var(--purple-60)',
  // 'purple70': 'var(--purple-70)',
  // 'purple80': 'var(--purple-80)',
  purple90: 'var(--purple-90)',
  // 'purple100': 'var(--purple-100)',
  purple110: 'var(--purple-110)',
  // 'purple120': 'var(--purple-120)',

  // 08-Violet

  // 'violet10': 'var(--violet-10)',
  violet20: 'var(--violet-20)',
  // 'violet30': 'var(--violet-30)',
  violet40: 'var(--violet-40)',
  // 'violet50': 'var(--violet-50)',
  // 'violet60': 'var(--violet-60)',
  // 'violet70': 'var(--violet-70)',
  // 'violet80': 'var(--violet-80)',
  violet90: 'var(--violet-90)',
  // 'violet100': 'var(--violet-100)',
  violet110: 'var(--violet-110)',
  // 'violet120': 'var(--violet-120)',

  // 08.5-iris

  // iris10: 'var(--iris-10)',
  // iris20: 'var(--iris-20)',
  // iris30: 'var(--iris-30)',
  iris40: 'var(--iris-40)',
  // iris50: 'var(--iris-50)',
  // iris60: 'var(--iris-60)',
  // iris70: 'var(--iris-70)',
  // iris80: 'var(--iris-80)',
  iris90: 'var(--iris-90)',
  // iris100: 'var(--iris-100)',
  iris110: 'var(--iris-110)',
  // iris120: 'var(--iris-120)',

  // 09-indigo

  indigo10: 'var(--indigo-10)',
  indigo20: 'var(--indigo-20)',
  indigo30: 'var(--indigo-30)',
  indigo40: 'var(--indigo-40)',
  indigo50: 'var(--indigo-50)',
  indigo60: 'var(--indigo-60)',
  indigo70: 'var(--indigo-70)',
  indigo80: 'var(--indigo-80)',
  indigo90: 'var(--indigo-90)',
  indigo100: 'var(--indigo-100)',
  indigo110: 'var(--indigo-110)',
  indigo120: 'var(--indigo-120)',

  // 10-Blue

  blue10: 'var(--blue-10)',
  blue20: 'var(--blue-20)',
  blue30: 'var(--blue-30)',
  blue40: 'var(--blue-40)',
  blue50: 'var(--blue-50)',
  blue60: 'var(--blue-60)',
  // 'blue70': 'var(--blue-70)',
  blue80: 'var(--blue-80)',
  blue90: 'var(--blue-90)',
  blue100: 'var(--blue-100)',
  blue110: 'var(--blue-110)',
  // 'blue120': 'var(--blue-120)',

  // 11-Cyan

  // 'cyan10': 'var(--cyan-10)',
  // 'cyan20': 'var(--cyan-20)',
  // 'cyan30': 'var(--cyan-30)',
  cyan40: 'var(--cyan-40)',
  // 'cyan50': 'var(--cyan-50)',
  // 'cyan60': 'var(--cyan-60)',
  // 'cyan70': 'var(--cyan-70)',
  // 'cyan80': 'var(--cyan-80)',
  cyan90: 'var(--cyan-90)',
  // 'cyan100': 'var(--cyan-100)',
  cyan110: 'var(--cyan-110)',
  // 'cyan120': 'var(--cyan-120)',

  // 12-Teal

  // 'teal10': 'var(--teal-10)',
  // 'teal20': 'var(--teal-20)',
  // 'teal30': 'var(--teal-30)',
  teal40: 'var(--teal-40)',
  // 'teal50': 'var(--teal-50)',
  // 'teal60': 'var(--teal-60)',
  // 'teal70': 'var(--teal-70)',
  // 'teal80': 'var(--teal-80)',
  teal90: 'var(--teal-90)',
  // 'teal100': 'var(--teal-100)',
  teal110: 'var(--teal-110)',
  // 'teal120': 'var(--teal-120)',

  // 12.5-jade

  // jade10: 'var(--jade-10)',
  // jade20: 'var(--jade-20)',
  // jade30: 'var(--jade-30)',
  // jade40: 'var(--jade-40)',
  // jade50: 'var(--jade-50)',
  // jade60: 'var(--jade-60)',
  // jade70: 'var(--jade-70)',
  // jade80: 'var(--jade-80)',
  // jade90: 'var(--jade-90)',
  // jade100: 'var(--jade-100)',
  jade110: 'var(--jade-110)',
  // jade120: 'var(--jade-120)',

  // 13-Green

  // 'green10': 'var(--green-10)',
  green20: 'var(--green-20)',
  green30: 'var(--green-30)',
  green40: 'var(--green-40)',
  green50: 'var(--green-50)',
  // 'green60': 'var(--green-60)',
  green70: 'var(--green-70)',
  // 'green80': 'var(--green-80)',
  green90: 'var(--green-90)',
  green100: 'var(--green-100)',
  green110: 'var(--green-110)',
  // 'green120': 'var(--green-120)',

  // 14-Grass

  // 'grass10': 'var(--grass-10)',
  // 'grass20': 'var(--grass-20)',
  grass30: 'var(--grass-30)',
  grass40: 'var(--grass-40)',
  // 'grass50': 'var(--grass-50)',
  // 'grass60': 'var(--grass-60)',
  // 'grass70': 'var(--grass-70)',
  // 'grass80': 'var(--grass-80)',
  grass90: 'var(--grass-90)',
  // 'grass100': 'var(--grass-100)',
  grass110: 'var(--grass-110)',
  // 'grass120': 'var(--grass-120)',

  // 15-Orange

  orange10: 'var(--orange-10)',
  orange20: 'var(--orange-20)',
  orange30: 'var(--orange-30)',
  orange40: 'var(--orange-40)',
  // 'orange50': 'var(--orange-50)',
  orange60: 'var(--orange-60)',
  // 'orange70': 'var(--orange-70)',
  // 'orange80': 'var(--orange-80)',
  orange90: 'var(--orange-90)',
  // 'orange100': 'var(--orange-100)',
  orange110: 'var(--orange-110)',
  // 'orange120': 'var(--orange-120)',

  // 16-Brown

  // 'brown10': 'var(--brown-10)',
  // 'brown20': 'var(--brown-20)',
  // 'brown30': 'var(--brown-30)',
  brown40: 'var(--brown-40)',
  // 'brown50': 'var(--brown-50)',
  // 'brown60': 'var(--brown-60)',
  // 'brown70': 'var(--brown-70)',
  // 'brown80': 'var(--brown-80)',
  brown90: 'var(--brown-90)',
  // 'brown100': 'var(--brown-100)',
  brown110: 'var(--brown-110)',
  // 'brown120': 'var(--brown-120)',

  // 17-Sky

  // 'sky10': 'var(--sky-10)',
  // 'sky20': 'var(--sky-20)',
  // 'sky30': 'var(--sky-30)',
  sky40: 'var(--sky-40)',
  sky50: 'var(--sky-50)',
  // 'sky60': 'var(--sky-60)',
  // 'sky70': 'var(--sky-70)',
  // 'sky80': 'var(--sky-80)',
  sky90: 'var(--sky-90)',
  // 'sky100': 'var(--sky-100)',
  sky110: 'var(--sky-110)',
  // 'sky120': 'var(--sky-120)',

  // 18-Mint

  // 'mint10': 'var(--mint-10)',
  // 'mint20': 'var(--mint-20)',
  mint30: 'var(--mint-30)',
  mint40: 'var(--mint-40)',
  mint50: 'var(--mint-50)',
  // 'mint60': 'var(--mint-60)',
  // 'mint70': 'var(--mint-70)',
  // 'mint80': 'var(--mint-80)',
  mint90: 'var(--mint-90)',
  // 'mint100': 'var(--mint-100)',
  mint110: 'var(--mint-110)',
  // 'mint120': 'var(--mint-120)',

  // 19-Lime

  // 'lime10': 'var(--lime-10)',
  // 'lime20': 'var(--lime-20)',
  // 'lime30': 'var(--lime-30)',
  lime40: 'var(--lime-40)',
  lime50: 'var(--lime-50)',
  // 'lime60': 'var(--lime-60)',
  // 'lime70': 'var(--lime-70)',
  // 'lime80': 'var(--lime-80)',
  lime90: 'var(--lime-90)',
  // 'lime100': 'var(--lime-100)',
  lime110: 'var(--lime-110)',
  // 'lime120': 'var(--lime-120)',

  // 20-Yellow

  yellow10: 'var(--yellow-10)',
  yellow20: 'var(--yellow-20)',
  yellow30: 'var(--yellow-30)',
  yellow40: 'var(--yellow-40)',
  // 'yellow50': 'var(--yellow-50)',
  yellow60: 'var(--yellow-60)',
  yellow70: 'var(--yellow-70)',
  // 'yellow80': 'var(--yellow-80)',
  yellow90: 'var(--yellow-90)',
  // 'yellow100': 'var(--yellow-100)',
  yellow110: 'var(--yellow-110)',
  // 'yellow120': 'var(--yellow-120)',

  // 21-Amber

  // amber10: 'var(--amber-10)',
  // amber20: 'var(--amber-20)',
  // amber30: 'var(--amber-30)',
  // amber40: 'var(--amber-40)',
  // amber50: 'var(--amber-50)',
  // amber60: 'var(--amber-60)',
  // amber70: 'var(--amber-70)',
  // amber80: 'var(--amber-80)',
  // amber90: 'var(--amber-90)',
  // amber100: 'var(--amber-100)',
  amber110: 'var(--amber-110)',
  // amber120: 'var(--amber-120)',

  'rgba-7': 'var(--rgba-7)',
  'rgba-8': 'var(--rgba-8)',
  'rgba-9': 'var(--rgba-9)',
  'rgba-10': 'var(--rgba-10)',
  'rgba-17': 'var(--rgba-17)',

  elevation1: 'var(--elevation-1)',
  elevation2: 'var(--elevation-2)',
} as const;

export enum ColorNames {
  tomato = 'tomato',
  crimson = 'crimson',
  pink = 'pink',
  purple = 'purple',
  violet = 'violet',
  blue = 'blue',
  cyan = 'cyan',
  teal = 'teal',
  grass = 'grass',
  orange = 'orange',
  brown = 'brown',
  sky = 'sky',
  mint = 'mint',
  iris = 'iris',
  lime = 'lime',
  ruby = 'ruby',
  plum = 'plum',
  yellow = 'yellow',
}

export const StaticColors = {
  postStatusComplete: '#6cd345',
  postStatusInProgress: '#c17aff', // duplicate on css purple
  postStatusPlanned: '#1fa0ff', // duplicate on css lightBlue
  postStatusUnderReview: '#85b5b5', // duplicate on css underReview
  postStatusOpen: '#a6a6a6',
  postStatusClosed: '#ed2b2b', // duplicate on css red
  postStatusBlue100: '#81c8c8',
  postStatusBlue200: '#81b1ff',
  postStatusBlue300: '#00c0fd',
  postStatusBlue400: '#1fa0ff', // duplicate on css - lightBlue
  postStatusBlue500: '#6b69ff',
  postStatusBlue600: '#1e58ed',
  postStatusBlue700: '#0063ae',
  postStatusBlue800: '#040077',
  postStatusGreen100: '#00e4a1',
  postStatusGreen200: '#6cd345', // duplicate on css postStatusComplete
  postStatusGreen300: '#04c168',
  postStatusGreen400: '#1cbc9c',
  postStatusGreen500: '#1e907a',
  postStatusGreen600: '#048944',
  postStatusGreen700: '#00652a',
  postStatusGreen800: '#436652',
  postStatusPurple100: '#ee82ff',
  postStatusPurple200: '#fd37f1',
  postStatusPurple300: '#c17aff', // duplicate on css purple
  postStatusPurple400: '#b118c6',
  postStatusPurple500: '#9a4fb9',
  postStatusPurple600: '#c50a8b',
  postStatusPurple700: '#a60174',
  postStatusPurple800: '#7c0088',
  postStatusRed100: '#ff9367',
  postStatusRed200: '#f4ae27', // duplicate on css - yellowWhite4
  postStatusRed300: '#fd7f00',
  postStatusRed400: '#fa7d9b',
  postStatusRed500: '#f0787a',
  postStatusRed600: '#fd563d',
  postStatusRed700: '#cd4a12',
  postStatusRed800: '#a50000',
  canny: '#525df9',
  white: '#ffffff',
  darkThemeBackground: '#111111',
  darkBlue: '#1a1523',
  middleBlue: '#7D7D83',
  redWhiteColor: '#eeeeee',
} as const;

export type ColorKeys = keyof typeof Colors;
export type ColorValues = ObjectValues<typeof Colors>;
export default Colors;
