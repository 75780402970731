import React, { useCallback, useContext } from 'react';

import { type Dispatch } from 'redux';

import { type CompanyInfo, reloadTPCForFilters } from 'common/actions/tpcForFilters';
import { TPCForFiltersContext } from 'common/containers/TPCForFiltersContainer';
import connect from 'common/core/connect';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import stringSort from 'common/util/stringSort';
import urlNamify from 'common/util/urlNamify';
import useDelayer from 'common/util/useDelayer';

import type { Option } from 'common/ui/common/select/SelectCommon';

type ConnectProps = {
  reloadCompanies?: (search?: string) => Promise<void>;
};

type OwnProps = {
  companySelected: (company: CompanyInfo) => void;
  selection?: string;
};

type Props = OwnProps & ConnectProps;

const CompanyFilterInput = ({ companySelected, reloadCompanies, selection }: Props) => {
  const { accountOwnerNames, loading } = useContext(TPCForFiltersContext);

  const getSelectedAccountOwner = () => {
    if (!selection) {
      return undefined;
    }

    const selectedAccountOwner = decodeURIComponent(selection);
    return accountOwnerNames?.[urlNamify(selectedAccountOwner)];
  };

  const selectFilter = (filter?: Option) => {
    if (filter && accountOwnerNames?.[filter.value]) {
      companySelected(accountOwnerNames?.[filter.value]);
    } else if (selection) {
      const toDeselect = getSelectedAccountOwner();
      if (toDeselect) {
        companySelected(toDeselect);
      }
    }
  };

  const onSearchChange = (search?: string) => {
    if (search && search.length > 0) {
      searchDelayer(search);
    }
  };

  const searchForCompanies = useCallback(
    (search: string) => {
      if (reloadCompanies) {
        reloadCompanies(search);
      }
    },
    [reloadCompanies]
  );

  const searchDelayer = useDelayer(searchForCompanies, 300);

  // Our current TPC search doesn't do name parts and only prefix matches
  // purposefully match that search functionality by only returning prefix matches here
  const options: Option[] = Object.values(accountOwnerNames ?? {})
    .sort(stringSort('name'))
    .map((option) => {
      return {
        label: option.name,
        value: option.urlName,
      };
    });

  const getSelectedOption = () => {
    const selectedAccountOwnerInfo = getSelectedAccountOwner();

    if (!selectedAccountOwnerInfo) {
      return undefined;
    }

    const { name, urlName } = selectedAccountOwnerInfo;
    return {
      label: name,
      value: urlName,
    };
  };

  return (
    <SingleSelectWithSearch
      options={options}
      onChange={selectFilter}
      allowClear
      loading={loading}
      placeholder="Search..."
      value={getSelectedOption()}
      onSearchChange={onSearchChange}
    />
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompanies: (search: string) => dispatch(reloadTPCForFilters(search)),
}))(CompanyFilterInput);
