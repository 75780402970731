import React from 'react';

import { X } from 'lucide-react';

import Card from 'common/common/Card';
import { LoadStatus, type LocalFile, MimeTypes } from 'common/constants/files';
import LazyLoadedImage from 'common/LazyLoadedImage';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';

import AttachmentIcon from 'img/icons/attachment-icon.svg';
import DocumentIcon from 'img/icons/document-icon.svg';
import JSONIcon from 'img/icons/json-icon.svg';
import PDFIcon from 'img/icons/pdf-icon.svg';
import PresentationIcon from 'img/icons/presentation-icon.svg';
import SpreadsheetIcon from 'img/icons/spreadsheet-icon.svg';
import TextIcon from 'img/icons/text-icon.svg';
import VideoIcon from 'img/icons/video-icon.svg';

import 'css/components/file/_FileCard.scss';

type Props = {
  allowRemove: boolean;
  file: LocalFile;
  onFileRemoved?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: LocalFile) => void;
};

const CardContent = {
  attachment: { label: 'Attachment', icon: AttachmentIcon },
  document: { label: 'Document', icon: DocumentIcon },
  json: { label: 'Json', icon: JSONIcon },
  pdf: { label: 'PDF', icon: PDFIcon },
  presentation: { label: 'Presentation', icon: PresentationIcon },
  spreadsheet: { label: 'Spreadsheet', icon: SpreadsheetIcon },
  text: { label: 'Text', icon: TextIcon },
  video: { label: 'Video', icon: VideoIcon },
};

const ContentMap: Partial<Record<MimeTypes, { label: string; icon: string }>> = {
  [MimeTypes.txt]: CardContent.text,
  [MimeTypes.rtf]: CardContent.document,
  [MimeTypes.csv]: CardContent.spreadsheet,
  [MimeTypes.md]: CardContent.text,
  [MimeTypes.yaml]: CardContent.text,
  // Note: PDF uploads are temporarily disabled due to a security vulnerability
  // [MimeTypes.pdf]: CardContent.pdf,
  [MimeTypes.doc]: CardContent.document,
  [MimeTypes.docx]: CardContent.document,
  [MimeTypes.xls]: CardContent.spreadsheet,
  [MimeTypes.xlsx]: CardContent.spreadsheet,
  [MimeTypes.ppt]: CardContent.document,
  [MimeTypes.pptx]: CardContent.presentation,
  [MimeTypes.odt]: CardContent.document,
  [MimeTypes.ods]: CardContent.spreadsheet,
  [MimeTypes.odp]: CardContent.presentation,
  [MimeTypes.key]: CardContent.presentation,
  [MimeTypes.json]: CardContent.json,
  [MimeTypes.xml]: CardContent.text,
  [MimeTypes.mp4]: CardContent.video,
  [MimeTypes.avi]: CardContent.video,
  [MimeTypes.mov]: CardContent.video,
  [MimeTypes.wmv]: CardContent.video,
  [MimeTypes.flv]: CardContent.video,
};

const FileCard = ({ allowRemove, file, onFileRemoved }: Props) => {
  const content = file.contentType
    ? ContentMap[file.contentType] ?? CardContent.attachment
    : CardContent.attachment;
  const loaded = file.uploadStatus === LoadStatus.loaded;

  const component = (
    <Card className="fileCard" borderStyle="solid">
      <div className="leftContainer">
        <LazyLoadedImage alt={file.name} className="fileIcon" src={content.icon} />
        <P className="fileName" variant="bodyMd">
          {file.name || content.label}
        </P>
      </div>
      {allowRemove && (
        <div className="rightContainer">
          <IconButtonV2
            size="small"
            loading={!loaded}
            onClick={(e) => onFileRemoved && onFileRemoved(e, file)}
            icon={X}
            variant="plain"
            aria-label="Remove file"
          />
        </div>
      )}
    </Card>
  );

  return loaded ? (
    <a href={file.url ?? ''} target="_blank" download={file.uniqueID} rel="noopener">
      {component}
    </a>
  ) : (
    component
  );
};

export default FileCard;
