import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { AlertCircle, Equal, Plus } from 'lucide-react';

import Colors from 'common/colors/constants';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import generateRandomID from 'common/generateRandomID';
import Button from 'common/inputs/Button';
import RadioButton from 'common/inputs/RadioButton';
import ModernModal from 'common/modals/ModernModal';
import UppercaseHeader from 'common/UppercaseHeader';
import UserAvatar from 'common/user/UserAvatar';

import type { CompanyUser } from 'common/api/endpoints/users';
import type { MapObjectType } from 'common/types/util';

import 'css/components/modals/_MergeUsersModal.scss';

type UserSummary = Pick<CompanyUser, 'name' | 'email' | 'avatarURL'>;

export type Overwrites = {
  name: 'target' | 'origin';
  email: 'target' | 'origin';
  primary: 'target' | 'origin';
};

type SelectedFields = MapObjectType<Overwrites, boolean>;

// this ID is used only to generate an avatar for the "merged" user, in case none
// of the existing users have one associated.
const DefaultMergedUserID = generateRandomID();

const UserSummaryCard = ({
  user,
  selectedFields,
  highlight = false,
  onChange,
}: {
  highlight?: boolean;
  user: UserSummary & Pick<CompanyUser, '_id'>;
  selectedFields?: SelectedFields;
  onChange?: (field: keyof Overwrites) => void;
}) => {
  const isEditable = !!selectedFields;

  let primaryLabel = null;
  if (!isEditable) {
    primaryLabel = <Pill pillStyle={DefaultPillStyles.canny}>Final</Pill>;
  } else if (selectedFields.primary) {
    primaryLabel = <Pill pillStyle={DefaultPillStyles.canny}>Primary</Pill>;
  } else {
    primaryLabel = (
      <button className="primaryButton" onClick={() => onChange?.('primary')}>
        Mark as primary
      </button>
    );
  }

  return (
    <div
      role="form"
      aria-label={`user ${user.name}`}
      className={classnames('userSummary', { highlight })}>
      <div className="field avatarContainer">
        <UserAvatar size="large" user={user} />
        <div className="action">{primaryLabel}</div>
      </div>
      <div className="divider" />
      <div className="field">
        <div className="fieldData">
          <UppercaseHeader>Name</UppercaseHeader>
          <div className="userName">{user.name ?? 'Unknown'}</div>
        </div>
        {user.name && isEditable && (
          <div className="action">
            <RadioButton
              name="name"
              selected={selectedFields.name}
              onSelected={() => onChange?.('name')}
            />
          </div>
        )}
      </div>
      <div className="field">
        <div className="fieldData">
          <UppercaseHeader>Email</UppercaseHeader>
          <div className="email">{user.email ?? 'Unknown'}</div>
        </div>
      </div>
    </div>
  );
};

const getDefaultOverwrites = (fromUser: CompanyUser): Overwrites => {
  return {
    email: fromUser.email ? 'origin' : 'target',
    name: fromUser.name ? 'origin' : 'target',
    primary: 'origin',
  };
};

type Props = {
  fromUser: CompanyUser;
  toUser: CompanyUser;
  onClose: () => void;
  onMerge: (fromUser: CompanyUser, toUser: CompanyUser, overwrites: Overwrites) => void;
};

const MergeUsersModal = ({ fromUser, toUser, onClose, onMerge }: Props) => {
  const [overwrites, setOverwrites] = useState<Overwrites>(getDefaultOverwrites(fromUser));

  useEffect(() => {
    // set default overwrites when users change
    setOverwrites(getDefaultOverwrites(fromUser));
  }, [fromUser]);

  const onFieldChange = (fieldName: keyof Overwrites, userType: 'origin' | 'target') => {
    setOverwrites({ ...overwrites, [fieldName]: userType });
  };

  const getSelectedFields = (userType: 'origin' | 'target'): SelectedFields => {
    return Object.fromEntries(
      Object.entries(overwrites).map(([fieldName, overwriteUserType]) => [
        fieldName,
        overwriteUserType === userType,
      ])
    ) as SelectedFields;
  };

  const getMergedUser = (
    fromUser: CompanyUser,
    toUser: CompanyUser,
    overwrites: Overwrites
  ): UserSummary & Pick<CompanyUser, '_id'> => {
    return {
      email: overwrites.primary === 'origin' ? fromUser.email : toUser.email,
      name: overwrites.name === 'origin' ? fromUser.name : toUser.name,
      avatarURL:
        overwrites.primary === 'origin'
          ? fromUser.avatarURL ?? toUser.avatarURL
          : toUser.avatarURL ?? fromUser.avatarURL,
      _id: DefaultMergedUserID,
    };
  };

  const primaryUser = overwrites.primary === 'origin' ? fromUser : toUser;
  return (
    <ModernModal
      overlayClassName="mergeUsersModalPortal"
      onClose={onClose}
      header="Merge users"
      sections={[
        <>
          <div className="info">
            <p>
              Merging will combine all activity (i.e. posts, votes, and comments) from both records
              into the primary record.{' '}
              <span className="bold">This action is&nbsp;irreversible.</span>
            </p>
            <p>
              You may select which name you would like to keep. For all the other fields, values
              from the primary user will be used. If a value doesn't exist in the primary record,
              values from the non-primary user will be&nbsp;used.
            </p>
            <p>
              The user will need to login with the primary record's credentials. Please notify the
              user of the&nbsp;change.
            </p>
          </div>
          <div className="mergeSummary">
            <UserSummaryCard
              user={fromUser}
              selectedFields={getSelectedFields('origin')}
              onChange={(fieldName) => onFieldChange(fieldName, 'origin')}
            />
            <Plus color={Colors['gray60']} strokeWidth={1} />
            <UserSummaryCard
              user={toUser}
              selectedFields={getSelectedFields('target')}
              onChange={(fieldName) => onFieldChange(fieldName, 'target')}
            />
            <Equal color={Colors['gray60']} strokeWidth={1} />
            <UserSummaryCard user={getMergedUser(fromUser, toUser, overwrites)} highlight={true} />
          </div>
          {!primaryUser.hasCannyAccount && (
            <div className="alert" role="alert">
              <AlertCircle className="alertIcon" />
              <p>
                The selected primary record does not have login credentials associated. Try
                switching primary records, or ensure the user can access their posts through other
                methods (e.g. SSO, Identify, etc).
              </p>
            </div>
          )}
        </>,
      ]}
      footer={
        <>
          <Button buttonType="ghostButton" onClick={onClose} value="Cancel" />
          <Button onClick={() => onMerge(fromUser, toUser, overwrites)} value="Merge users" />
        </>
      }
    />
  );
};

export default MergeUsersModal;
