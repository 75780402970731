const devVariables = {
  googleOauthClientID: '407440207476-ovmdrqb7bghgvavddlp9rt8b640jr5a5.apps.googleusercontent.com',
  hostnameOverride: 'cannisters.website',
  jiraIntegrationKey: 'canny-addon-dev',
  supportsSearchEngineIndexing: false,
};

const stagingVariables = {
  googleOauthClientID: '407440207476-60t2tkbtpupspui8be37gi9ios3hg07h.apps.googleusercontent.com',
  hostnameOverride: 'cannisters.cafe',
  jiraIntegrationKey: 'canny-addon-dev',
  supportsSearchEngineIndexing: false,
};

const productionVariables = {
  googleOauthClientID: '407440207476-18s2lf12sn6317eu06llthplbfj4gal2.apps.googleusercontent.com',
  hostnameOverride: undefined,
  jiraIntegrationKey: 'canny-addon',
  supportsSearchEngineIndexing: true,
};

type Keys = keyof typeof stagingVariables | keyof typeof productionVariables;

export default function getConfig(key: Keys) {
  if (__DEV_ENV__) {
    return devVariables[key];
  }

  if (__STAGING__) {
    return stagingVariables[key];
  }

  return productionVariables[key];
}
