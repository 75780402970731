import React from 'react';

import { Hourglass, Inbox, PauseCircle, Plus, Search } from 'lucide-react';

import { reloadCompany } from 'common/actions/company';
import connect from 'common/core/connect';
import Link from 'common/Link';
import ButtonV2 from 'common/ui/ButtonV2';

import AdminQueueNUX from './AdminQueueNUX';
import useFilterControls from './useFilterControls';

import type { Counts } from './types';
import type { Company } from 'common/api/endpoints/companies';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import type { State } from 'redux-connect';

type OwnProps = {
  company: Company;
  counts: Counts;
};

type ConnectProps = {
  queryState: Record<string, string> | null;
  reloadCompany: () => void;
};

const calculateHasOtherItems = (source: string | undefined, counts: Counts) => {
  if (!source) {
    return counts.totalCount > 0;
  }

  if (source === 'canny') {
    return counts.cannyCount > 0;
  }

  if (counts.perSourceCounts[source as SyncIntegrationNames]) {
    return true;
  }

  return false;
};

const AutopilotNUX = ({ company, counts, queryState, reloadCompany }: ConnectProps & OwnProps) => {
  const { installedSyncIntegrations } = company;
  const { queryParams, updateFilters } = useFilterControls({ queryState, reloadCompany });

  const { source } = queryParams;
  const hasOtherItems = calculateHasOtherItems(source, counts);

  // there are items, but none match the current filter (ie items === [], but hasOtherItems === true)
  if (hasOtherItems) {
    return (
      <AdminQueueNUX
        cta={
          <ButtonV2 onClick={() => updateFilters({ source })} size="medium">
            Reset filters
          </ButtonV2>
        }
        icon={Search}
        info={[
          'We couldn’t find any results that match your current filters. Try adjusting your filters or check back later.',
        ]}
        title="No results found"
      />
    );
  }

  if (
    source &&
    source !== 'canny' &&
    installedSyncIntegrations.find(({ integrationName }) => integrationName === source)?.disabled
  ) {
    return (
      <AdminQueueNUX
        cta={
          <Link to="/admin/settings/autopilot/feedback-discovery">
            <ButtonV2 size="medium">Enable source</ButtonV2>
          </Link>
        }
        icon={PauseCircle}
        info={[
          'The source you selected is currently disabled. Enable it to resume feedback extraction and processing.',
        ]}
        title="Enable your source"
      />
    );
  }

  // no sources installed
  if (installedSyncIntegrations.length === 0) {
    return (
      <AdminQueueNUX
        cta={
          <Link to="/admin/settings/autopilot/feedback-discovery">
            <ButtonV2 startIcon={Plus} size="medium">
              Connect source
            </ButtonV2>
          </Link>
        }
        icon={Inbox}
        info={[
          'Your inbox is currently empty. Connect a source to automatically import relevant feedback into your Canny workspace.',
        ]}
        title="Connect a source"
      />
    );
  }

  if (installedSyncIntegrations.filter((integration) => !integration.disabled).length === 0) {
    return (
      <AdminQueueNUX
        cta={
          <Link to="/admin/settings/autopilot/feedback-discovery">
            <ButtonV2 size="medium">Enable sources</ButtonV2>
          </Link>
        }
        icon={PauseCircle}
        info={[
          'All of your connected sources are currently disabled. Enable them to resume feedback extraction and processing',
        ]}
        title="Enable your sources"
      />
    );
  }

  return (
    <AdminQueueNUX
      cta={
        <Link to="/admin/settings/autopilot/feedback-discovery">
          <ButtonV2 startIcon={Plus} size="medium">
            Connect more sources
          </ButtonV2>
        </Link>
      }
      icon={Hourglass}
      info={[
        'Canny Autopilot is working hard to extract and process feedback from the last 30 days. Check back regularly for results.',
      ]}
      title="Retrieving data from sources..."
    />
  );
};

export default connect(
  (state: State) => ({
    queryState: state.routing.locationBeforeTransitions.query,
  }),
  (dispatch: Dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })
)(AutopilotNUX) as unknown as React.FC<OwnProps>;
