import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import URLNameInput from 'common/inputs/URLNameInput';
import AccessModal from 'common/modals/AccessModal';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import Strings from 'common/Strings';
import SwitchV2 from 'common/ui/SwitchV2';
import { P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';
import styles from 'css-module/components/subdomain/admin/_AdminBoardSettingsGeneral.module.scss';

class AdminBoardSettingsGeneral extends Component {
  static propTypes = {
    board: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      settings: PropTypes.shape({
        privateAuthors: PropTypes.bool,
        privateVotes: PropTypes.bool,
        showVoterNames: PropTypes.bool,
        privateComments: PropTypes.bool,
      }),
      urlName: PropTypes.string,
    }),
    company: PropTypes.object,
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    edited: false,
    editing: false,
    error: null,
    formHasEdits: false,
    upsellFeature: { cta: null, name: null },
    privateAuthors: this.props.board.settings.privateAuthors,
    privateVotes: this.props.board.settings.privateVotes,
    showTimestamps: this.props.board.settings.showTimestamps,
    showVoterNames: this.props.board.settings.showVoterNames,
    showComments: !this.props.board.settings.privateComments,
    showCreateForm: this.props.board.settings.showCreateForm,
  };

  constructor(props, context) {
    super(props, context);

    this.nameRef = React.createRef();
    this.urlRef = React.createRef();
    this.descriptionRef = React.createRef();
  }

  hasEdits = () => {
    const { board } = this.props;
    const { settings } = board;
    const {
      edited,
      formHasEdits,
      privateVotes,
      showComments,
      showVoterNames,
      showTimestamps,
      showCreateForm,
      privateAuthors,
    } = this.state;

    const changes = [
      formHasEdits,
      privateAuthors !== settings.privateAuthors,
      privateVotes !== settings.privateVotes,
      showTimestamps !== settings.showTimestamps,
      showComments === settings.privateComments,
      showVoterNames !== settings.showVoterNames,
      showCreateForm !== settings.showCreateForm,
    ];

    return edited ? false : changes.some(Boolean);
  };

  checkFeatureAccess = (feature) => {
    const {
      company: { features },
    } = this.props;
    return !features || features?.[feature];
  };

  onNameToggle = (showVoterNames) => {
    this.setState((state) => ({
      edited: false,
      showVoterNames,
    }));
  };

  onPrivateAuthorsToggle = (privateAuthors) => {
    if (privateAuthors && !this.checkFeatureAccess('privateAuthors')) {
      this.setState({
        upsellFeature: {
          cta: 'Anonymize boards',
          name: 'privateAuthors',
        },
      });
      return;
    }

    this.setState((state) => ({
      edited: false,
      privateAuthors,
    }));
  };

  onCommentsToggle = (showComments) => {
    if (!showComments && !this.checkFeatureAccess('privateComments')) {
      this.setState({
        upsellFeature: {
          cta: 'Make comments from end-users private',
          name: 'privateComments',
        },
      });
      return;
    }

    this.setState({
      edited: false,
      showComments,
    });
  };

  onTimestampToggle = (showTimestamps) => {
    this.setState((state) => ({
      edited: false,
      showTimestamps: showTimestamps,
    }));
  };

  onVotesPublicToggle = (votesPublic) => {
    if (!votesPublic && !this.checkFeatureAccess('privateVotes')) {
      this.setState({
        upsellFeature: {
          cta: 'Make votes private',
          name: 'privateVotes',
        },
      });
      return;
    }

    this.setState((state) => ({
      edited: false,
      privateVotes: !votesPublic,
    }));
  };

  onFormChange = () => {
    const { board } = this.props;
    const name = this.nameRef.current?.getValue().trim();
    const urlName = this.urlRef.current?.getValue().trim();
    const description = this.descriptionRef.current?.getValue().trim();

    this.setState({
      edited: false,
      formHasEdits:
        name !== board.name ||
        urlName !== board.urlName ||
        description !== board.strings.description,
    });
  };

  onSubmit = async () => {
    const name = this.nameRef.current.getValue().trim();
    const urlName = this.urlRef.current.getValue().trim();
    const description = this.descriptionRef.current.getValue().trim();

    var error = null;
    if (!name || !validateInput.board.name(name)) {
      error = 'Please enter a valid board name';
    } else if (!urlName || !validateInput.board.urlName(urlName)) {
      error = 'Please enter a valid board url';
    } else if (!validateInput.board.strings.description(description)) {
      error = 'Please enter a valid description (0-200 characters)';
    }

    if (error) {
      this.setState({ error });
      return;
    }

    this.setState({
      editing: true,
    });

    const { board, router } = this.props;
    if (board.settings.showCreateForm !== this.state.showCreateForm) {
      const response = await AJAX.post('/api/boards/editCreatePostForm', {
        boardID: board._id,
        showCreateForm: this.state.showCreateForm,
      });

      const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
      if (error) {
        this.setState({ error: error.message });
        return;
      }
    }

    if (description !== board.strings.description) {
      const response = await AJAX.post('/api/boards/editStrings', {
        boardID: board._id,
        ...board.strings,
        description,
      });

      const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
      if (error) {
        this.setState({ error: error.message });
        return;
      }
    }

    AJAX.post(
      '/api/boards/edit',
      {
        boardID: board._id,
        name,
        privateAuthors: this.state.privateAuthors,
        privateVotes: this.state.privateVotes,
        showTimestamps: this.state.showTimestamps,
        showVoterNames: this.state.showVoterNames,
        privateComments: !this.state.showComments,
        urlName,
      },
      (response) => {
        this.setState({
          editing: false,
        });

        if (response !== 'success') {
          var responseObject;
          try {
            responseObject = JSON.parse(response);
          } catch (e) {
            responseObject = { error: Strings.miscError };
          }

          if (responseObject.error === 'url taken') {
            this.setState({
              error: 'This URL is taken',
            });
          } else {
            this.setState({
              error: Strings.miscError,
            });
          }
          return;
        }

        this.setState({ edited: true });

        const editedBoardSettingsURL = '/admin/settings/boards/' + urlName + '/general';
        router.replace(editedBoardSettingsURL);

        setTimeout(() => {
          this.props.reloadBoard(board.urlName);
        }, 0);
      }
    );
  };

  onUpsell = () => {
    this.setState({ upsellFeature: { cta: null, name: null } });
    this.onCommentsToggle(false);
  };

  onUpsellDismiss = () => {
    this.setState({ upsellFeature: { cta: null, name: null } });
  };

  onToggleShowCreateForm = (showCreateForm) => {
    const { openModal, viewer, company } = this.props;
    const { features } = company;
    const viewerHasPermission = hasPermission('manageBoardPrivacy', company, viewer);

    if (!features.disableUserSubmissions) {
      this.setState({
        upsellFeature: {
          cta: 'Disable user submissions',
          name: 'disableUserSubmissions',
        },
      });
      return;
    } else if (!viewerHasPermission) {
      openModal(AccessModal, {
        requiredPermissions: ['manageBoardPrivacy'],
      });
      return;
    }

    this.setState({
      showCreateForm,
      edited: false,
    });
  };

  renderError() {
    if (!this.state.error) {
      return;
    }

    return <div className={classnames('error', styles.error)}>{this.state.error}</div>;
  }

  render() {
    const { board } = this.props;
    const hasEdits = this.hasEdits();

    return (
      <div className={styles.adminBoardSettingsGeneral}>
        <Helmet title={'General Settings | ' + board.name + ' | Canny'} />
        <Form
          addEventsToDocument={false}
          disableSubmit={!hasEdits || this.state.editing}
          onSubmit={this.onSubmit}>
          <div className="subHeading">Naming</div>
          <div className={styles.namingFields}>
            <TextInput
              className={styles.textInput}
              defaultValue={board.name}
              inset="Name"
              onChange={this.onFormChange}
              ref={this.nameRef}
            />
            <TextInput
              defaultValue={board.strings.description}
              inset="Description"
              onChange={this.onFormChange}
              ref={this.descriptionRef}
            />
            <URLNameInput
              company={this.props.company}
              className={styles.textInput}
              input={board.urlName}
              inset="URL"
              onChange={this.onFormChange}
              ref={this.urlRef}
            />
          </div>
          <div className="subHeading">Public View</div>
          <P className={styles.description}>
            These settings affects the public view of your board. If your board is private, only
            people who have access to the private board will be&nbsp;affected.
          </P>
          <div className={styles.row}>
            <P>Allow new posts from end users</P>
            <SwitchV2 onChange={this.onToggleShowCreateForm} checked={this.state.showCreateForm} />
          </div>
          <div className={styles.row}>
            <P>Use user aliases in posts, comments, votes, and comment likes</P>
            <SwitchV2 onChange={this.onPrivateAuthorsToggle} checked={this.state.privateAuthors} />
          </div>
          <div className={styles.row}>
            <P>Show total number of votes on posts</P>
            <SwitchV2 onChange={this.onVotesPublicToggle} checked={!this.state.privateVotes} />
          </div>
          <div className={`${styles.row} ${styles.dependent}`}>
            <P>Show names of people who vote on posts and like comments</P>
            <SwitchV2 onChange={this.onNameToggle} checked={this.state.showVoterNames} />
          </div>
          <div className={styles.row}>
            <P>Show dates on posts and comments</P>
            <SwitchV2 onChange={this.onTimestampToggle} checked={this.state.showTimestamps} />
          </div>
          <div className={styles.row}>
            <P>Allow end-users to see each others' comments</P>
            <SwitchV2 onChange={this.onCommentsToggle} checked={this.state.showComments} />
          </div>
          {this.renderError()}
          <Button
            buttonType="cannyButton"
            disabled={!hasEdits}
            formButton={true}
            loading={this.state.editing}
            value={this.state.edited ? 'Saved' : 'Save'}
          />
        </Form>
        <UpsellModal
          cta={this.state.upsellFeature.cta}
          feature={this.state.upsellFeature.name}
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsell}
          show={!!this.state.upsellFeature.name}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board.general),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminBoardSettingsGeneral);
