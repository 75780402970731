import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';

import { type CompanyInfo } from 'common/actions/tpcForFilters';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import styles from 'css-module/components/subdomain/admin/AdminSideBar/_CompanyFilterSection.module.scss';

import AccountOwnerFilterInput from './AccountOwnerFilterInput';
import CompanyFilterInput from './CompanyFilterInput';
import CompanyFilterSelections from './CompanyFilterSelections';
import FilterToggle from './FilterToggle';

// these equal the url parameter names
export enum ToggleModes {
  companies = 'companies',
  accountOwner = 'accountOwner',
}

const CompanyFilterSection = () => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const [toggleMode, setToggleMode] = useState<ToggleModes>(ToggleModes.companies);

  // on load / location change
  useEffect(() => {
    const { query } = location;
    if (query?.[ToggleModes.companies] && toggleMode !== ToggleModes.companies) {
      setToggleMode(ToggleModes.companies);
    } else if (query?.[ToggleModes.accountOwner] && toggleMode !== ToggleModes.accountOwner) {
      setToggleMode(ToggleModes.accountOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onChangeMode = (newMode: string) => {
    if (newMode in ToggleModes) {
      const mode = newMode as ToggleModes;
      if (mode !== toggleMode) {
        clearParam(toggleMode);
        setToggleMode(ToggleModes[mode]);
      }
    }
  };

  const updateParam = (paramValue: string) => {
    const { query, pathname } = location;
    const queryToUpdate: string = query?.[toggleMode];
    const queryURLNameSet: Record<string, boolean> = {};
    let queryURLNames: string[] = [];
    if (queryToUpdate) {
      queryURLNames =
        toggleMode === ToggleModes.companies ? queryToUpdate.split('_') : [queryToUpdate];
    }

    if (toggleMode === ToggleModes.accountOwner) {
      // keep this new list empty, and
      // only add the selected one if it's not in there
      if (!queryURLNames.includes(paramValue)) {
        queryURLNameSet[paramValue] = true;
      }
    } else if (toggleMode === ToggleModes.companies) {
      queryURLNames.forEach((urlName) => {
        queryURLNameSet[urlName] = true;
      });

      if (queryURLNameSet[paramValue]) {
        delete queryURLNameSet[paramValue];
      } else {
        queryURLNameSet[paramValue] = true;
      }
    }

    const newURLNames = Object.keys(queryURLNameSet);
    const newParamValue = newURLNames.length > 0 ? newURLNames.join('_') : undefined;

    const newQuery = Object.assign({}, query, {
      [toggleMode]: newParamValue,
    });

    router.push({
      pathname,
      query: newQuery,
    });
  };

  const clearParam = (paramName: ToggleModes) => {
    const { query } = location;
    const newQuery = Object.assign({}, query, {
      [paramName]: undefined,
    });
    router.push({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  const onCompanySelected = (company: CompanyInfo) => {
    updateParam(company.urlName);
  };

  const onAccountOwnerSelected = (accountOwner: CompanyInfo) => {
    updateParam(accountOwner.name);
  };

  const companiesQuery: string = location.query?.companies ?? null;
  const accountOwnerQuery: string = location.query?.accountOwner ?? null;

  const selectedCompanies = companiesQuery ? companiesQuery.split('_') : [];
  const selectedAccountOwner = accountOwnerQuery ? accountOwnerQuery : undefined;

  const toggleOptions = [
    {
      label: 'By name',
      value: ToggleModes.companies,
    },
    {
      label: 'By account owner',
      value: ToggleModes.accountOwner,
    },
  ];

  return (
    <AdminSidebarSection className={classNames(styles.companyFilterSection)} title="Companies">
      <FilterToggle
        name="feedback-companies"
        toggleOptions={toggleOptions}
        selection={toggleMode}
        onToggle={onChangeMode}
      />
      {toggleMode === ToggleModes.companies && (
        <>
          <CompanyFilterInput
            selections={selectedCompanies}
            onCompanySelected={onCompanySelected}
          />
          <CompanyFilterSelections
            selections={selectedCompanies}
            onCompanySelected={onCompanySelected}
          />
        </>
      )}
      {toggleMode === ToggleModes.accountOwner && (
        <AccountOwnerFilterInput
          selection={selectedAccountOwner}
          companySelected={onAccountOwnerSelected}
        />
      )}
    </AdminSidebarSection>
  );
};

export default CompanyFilterSection;
