import React, { useContext, useEffect, useState } from 'react';

import { ClipboardList, Sparkles } from 'lucide-react';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { ColorNames } from 'common/colors/constants';
import Card from 'common/common/Card';
import UserAvatarV2 from 'common/common/UserAvatarV2';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Markdown from 'common/markdown/Markdown';
import UpsellModal from 'common/modals/UpsellModal';
import SpinnerV2 from 'common/SpinnerV2';
import Timestamp from 'common/Timestamp';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelect from 'common/ui/SingleSelectWithSearch';
import SwitchV2 from 'common/ui/SwitchV2';
import { H1, H2, P, Span } from 'common/ui/Text';
import getFirstName from 'common/util/getFirstName';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Board } from 'common/api/endpoints/boards';
import type { Member } from 'common/api/endpoints/companies';
import type { StrippedUser, StrippedUserWithAlias } from 'common/api/endpoints/users';
import type { Post } from 'common/api/resources/posts';
import type { Option } from 'common/ui/common/select/SelectCommon';
import type { Dispatch } from 'redux-connect';

import 'css/components/subdomain/admin/_AdminBoardSettingsSmartReplies.scss';

type SamplePost = {
  title: string;
  details: string;
  boardName: string;
  created?: string;
  author: StrippedUserWithAlias;
};

type SampleComment = {
  value: string;
  author: StrippedUserWithAlias;
  mentionedUsers: StrippedUserWithAlias[];
};

const SampleAlias = {
  alias: 'Green Frog',
  aliasID: '3',
};

const samplePost: SamplePost = {
  title: 'Translate into different languages',
  details: 'Maybe using some tool that allows the community to work on the translations.',
  boardName: 'Feature Requests',
  created: new Date().toString(),
  author: {
    _id: '2',
    alias: 'Red Panda',
    aliasID: '2',
    name: 'Andrew Rasmussen',
    urlName: 'andrew-rasmussen',
    avatarURL: 'https://canny.io/images/6d591ecab05cb10d017b75704b67ee9d.png',
    created: new Date(),
  },
};

const sampleComment: SampleComment = {
  value: `
    Hey ${getFirstName(
      samplePost.author.name
    )}, thank you for your feedback! Here are a couple extra questions to understand your use case a little better:
    1. Which languages are the highest priority for you and your users?
    2. Do you envision the community translations being used for public-facing content, internal documentation, or both?
    3. Are there specific sections or types of feedback in Canny you believe should be prioritized for translation?
  `,
  author: {
    _id: '1',
    alias: 'Blue Bird',
    aliasID: '1',
    name: 'Sarah Hum',
    urlName: 'sarah-hum',
    avatarURL: 'https://canny.io/images/a1964a6d0d50028d799d972317f71c83.jpg',
    created: new Date(),
  },
  mentionedUsers: [],
};

type Props = {
  board: Board;
  reloadBoard: (boardUrlName: string) => void;
};

const SamplePost = ({ content }: { content: SamplePost }) => {
  return (
    <Card className="examplePost" borderStyle="none">
      <div className="exampleBody">
        <div className="exampleHeading">
          <H2 variant="headingSm">{content.title}</H2>
          <Span className="exampleTag postTag" variant="bodySm">
            Post
          </Span>
        </div>
        <P className="exampleDetails" variant="bodyMd">
          <Markdown contents={content.details} />
        </P>
        <div className="postFooter">
          <UserAvatarV2 size={16} user={content.author} color={ColorNames.blue} shape="circle" />
          <P variant="bodySm">{content.author.name}</P>
          <span className="divider" />
          <ClipboardList className="icon" />
          <P variant="bodySm">{content.boardName}</P>
          <span className="divider" />
          <Timestamp className="timestamp" timestamp={content.created} />
        </div>
      </div>
    </Card>
  );
};

const SampleComment = ({ content }: { content: SampleComment }) => {
  return (
    <Card className="exampleComment" borderStyle="none">
      <UserAvatarV2 size={28} user={content.author} color={ColorNames.blue} shape="circle" />
      <div className="exampleBody">
        <div className="exampleHeading">
          <H2 variant="headingSm">{content.author.name}</H2>
          <Span className="exampleTag commentTag" variant="bodySm">
            Smart reply
          </Span>
        </div>
        <P className="exampleDetails" variant="bodyMd">
          <Markdown contents={content.value} mentions={content.mentionedUsers} />
        </P>
      </div>
    </Card>
  );
};

const mapMembersToOptions = (members: Member[]) =>
  members.map((member) => ({
    avatar: member.avatarURL,
    label: member.name,
    value: member._id,
  }));

const initialSelectedAdmin = (memberOptions: Option[], board: Board) => () =>
  memberOptions.find((option) => option.value === board.settings.autoResponse?.authorID) ||
  undefined;

const AdminBoardSettingsSmartReplies = ({ board, reloadBoard }: Props) => {
  const company = useContext(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const showIntercom = useContext(ShowIntercomContext);
  const viewer = useContext(ViewerContext);
  const memberOptions = mapMembersToOptions(company.members);

  const [smartReplies, setEnableSmartReplies] = useState(!!board.settings.autoResponse);
  const [saving, setSaving] = useState(false);

  const [selectedAdmin, setSelectedAdmin] = useState<Option | undefined>(
    initialSelectedAdmin(memberOptions, board)
  );
  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const [loadingPost, setLoadingPost] = useState(true);
  const [postDetails, setPostDetails] = useState<SamplePost>(samplePost);
  const [commentDetails, setCommentDetails] = useState<SampleComment | null>(null);
  const [loadingComment, setLoadingComment] = useState(false);
  const [postDoesNotExist, setPostDoesNotExist] = useState(false);

  useEffect(() => {
    const fetchSamplePost = async () => {
      if (!company.hasClearbitData) {
        setPostDoesNotExist(true);
        setLoadingPost(false);
        return;
      }

      const response = await AJAX.post('/api/boards/autoResponse/getSamplePost', {
        boardID: board._id,
      });

      const { error, parsedResponse } = parseAPIResponse(response, {
        isSuccessful: (parsedResponse: { exists: boolean; post?: Post }) =>
          parsedResponse.hasOwnProperty('exists'),
      });

      if (error || !parsedResponse?.exists || !parsedResponse?.post) {
        setPostDoesNotExist(true);
        setLoadingPost(false);
        return;
      }

      const { post } = parsedResponse;
      const { board: postBoard, created, details, author, title } = post;

      if (!author) {
        setLoadingPost(false);
        return;
      }

      const authorWithAlias = { ...author, ...SampleAlias };

      setPostDetails({
        title,
        details,
        boardName: postBoard.name,
        author: authorWithAlias,
        created: created,
      });

      setLoadingPost(false);
    };

    fetchSamplePost();
  }, [board._id, company.hasClearbitData]);

  const saveChanges = async () => {
    setSaving(true);

    const response = await AJAX.post('/api/boards/editSettings', {
      boardID: board._id,
      autoResponse:
        smartReplies && selectedAdmin?.value
          ? {
              authorID: selectedAdmin.value,
              enabledBy: viewer._id,
            }
          : null,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      setSaving(false);
      return;
    }

    showToast('Smart replies settings updated successfully.', ToastTypes.success);
    await reloadBoard(board.urlName);
    setSaving(false);
  };

  const findAuthor = () => {
    if (selectedAdmin?.value) {
      return company.members.find((member: StrippedUser) => member._id === selectedAdmin?.value);
    } else if (company.autoResponse?.authorID) {
      return company.members.find(
        (member: StrippedUser) => member._id === company.autoResponse.authorID
      );
    } else {
      return viewer;
    }
  };

  const generateResponse = async () => {
    if (postDoesNotExist) {
      setCommentDetails(sampleComment);
    } else {
      setLoadingComment(true);
      const response = await AJAX.post('/api/boards/autoResponse/getSampleComment', {
        boardID: board._id,
      });

      const { error, parsedResponse } = parseAPIResponse(response, {
        isSuccessful: (parsedResponse: { exists: boolean; post?: Post; response?: string }) =>
          parsedResponse.hasOwnProperty('exists'),
      });

      if (error || !parsedResponse?.exists || !parsedResponse?.response) {
        const noQuestions = parsedResponse?.exists && !parsedResponse.response;
        showToast(
          noQuestions
            ? 'This post would not generate any response. We are showing you a sample instead.'
            : 'We were unable to generate a response, we are showing you a sample instead. Please try again shortly.',
          ToastTypes.error
        );
        setPostDetails(samplePost);
        setCommentDetails(sampleComment);
        setLoadingComment(false);
        return;
      }

      setCommentDetails({
        value: parsedResponse.response,
        author: findAuthor(),
        mentionedUsers: [postDetails.author],
      });
    }
  };

  return (
    <div className="adminBoardSettingsSmartReplies">
      <Helmet title={`Smart Replies | ${board.name} | Canny`} />
      <div className="smartRepliesHeader">
        <div className="smartRepliesHeaderDetails">
          <H1 variant="headingMd">Smart Replies</H1>
          <span className="smartRepliesDescription">
            <P className="smartRepliesBody" variant="bodyMd">
              Automate thoughtful, human-like responses for every post.
            </P>
            <SwitchV2
              checked={smartReplies}
              onChange={
                smartReplies || company.features?.autoResponse
                  ? setEnableSmartReplies
                  : () => setShowUpsellModal(true)
              }
              size="medium"
            />
          </span>
        </div>
      </div>
      {!company.hasClearbitData && company.features?.autoResponse ? (
        <div className="error" role="alert">
          <P className="noClearbitData">
            Your company needs manual intervention to enable Smart Replies. Please,{' '}
            <button onClick={showIntercom} className="supportButton link">
              reach out to support
            </button>{' '}
            for&nbsp;help.
          </P>
        </div>
      ) : null}
      <div className="smartRepliesAdminSelection">
        <div className="smartRepliesAdminSelectionDetails">
          <H2 variant="headingSm">Comment Author</H2>
          <span className="smartRepliesDescription">
            <P className="smartRepliesBody" variant="bodyMd">
              The admin selected will be featured as the author for the reply.
            </P>
            <div className="smartRepliesAdminSelect">
              <SingleSelect
                allowClear
                disabled={!smartReplies}
                labelPlacement="left"
                onChange={setSelectedAdmin}
                options={memberOptions}
                optionsPlacement="bottom"
                placeholder="Select Admin"
                value={selectedAdmin}
              />
            </div>
          </span>
        </div>
      </div>
      <Card borderStyle="solid" className="exampleCard">
        {!loadingPost ? (
          <>
            <div className="exampleExplanation">
              <H2 variant="headingSm">What are smart replies?</H2>
              <P variant="bodyMd" className="smartRepliesBody">
                Smart replies generate personalized questions within two hours of post creation,
                allowing quick engagement with customer feedback and efficient information
                gathering.
              </P>
              <P variant="bodyMd" className="smartRepliesBody">
                Note: Every Smart Reply uses 1 Autopilot credit.{' '}
                <a
                  className="learnMore"
                  target="_blank"
                  href="https://help.canny.io/en/articles/8202451-autopilot#h_3fb0af8a32"
                  rel="noopener">
                  Learn more
                </a>
              </P>
              <P className="smartRepliesBody" variant="bodyMd">
                Click the button below to see a live example:
              </P>
            </div>
            <SamplePost content={postDetails} />
            {commentDetails && <SampleComment content={commentDetails} />}
            {!commentDetails && (
              <ButtonV2
                color="primary"
                className="smartRepliesGenerateButton"
                size="medium"
                startIcon={Sparkles}
                variant="outlined"
                loading={loadingComment}
                onClick={generateResponse}>
                Generate response
              </ButtonV2>
            )}
          </>
        ) : (
          <SpinnerV2 size="large" />
        )}
      </Card>
      <UpsellModal
        cta="Automate thoughtful, human-like responses for every post."
        feature="autoResponse"
        onClose={() => setShowUpsellModal(false)}
        onUpsell={() => setShowUpsellModal(false)}
        show={showUpsellModal}
      />
      <ButtonV2
        className="saveButton"
        disabled={
          (smartReplies &&
            (!selectedAdmin || selectedAdmin?.value === board?.settings?.autoResponse?.authorID)) ||
          (!smartReplies && smartReplies === !!board.settings.autoResponse)
        }
        onClick={saveChanges}
        loading={saving}
        size="medium">
        Save changes
      </ButtonV2>
    </div>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadBoard: (boardURLName: string) => {
    return dispatch(reloadBoard(boardURLName));
  },
}))(AdminBoardSettingsSmartReplies);
